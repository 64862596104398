<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'app',
    created() {
      const isMaterialOrder = sessionStorage.getItem('isMaterialOrder');
      const user = sessionStorage.getItem('user');
      isMaterialOrder && this.$store.commit('updateLoginMethod', 1);
      isMaterialOrder && user && this.$store.commit('updateUser', sessionStorage.getItem('user'));
    },
    mounted() {
			this.$utils.setRootSize();
		}
	}
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		height: 100%;
	}
</style>
