var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loginwindows"},[_c('div',{class:[
      'window',
      'am',
      {
        on: _vm.showWindow > 0,
        window_log: _vm.showWindow == 1,
        window_reg: _vm.showWindow == 2,
        'ie-low': _vm.ifIe9,
      },
    ]},[_c('div',{staticClass:"window-box"},[_c('div',{staticClass:"window_con"},[_c('i',{staticClass:"window_close iconfont iconclose",on:{"click":_vm.closeWindow}}),_c('img',{staticClass:"window_bgone",attrs:{"src":_vm.windowA,"alt":""}}),_c('img',{staticClass:"window_bgtwo",attrs:{"src":_vm.windowB,"alt":""}}),(_vm.showWindow == 1 || _vm.showWindow == 2)?_c('div',{staticClass:"window_title"},[_vm._v(" "+_vm._s(_vm.showWindow == 1 ? '欢迎登录' : '欢迎注册')),_c('span',[_vm._v("山东教育社刊物订购")])]):_vm._e(),(_vm.showWindow == 3)?_c('div',{staticClass:"window_title"},[_vm._v("修改密码")]):_vm._e(),_c('div',{staticClass:"window_form"},[_c('p',[_c('i',{staticClass:"iconfont icongerenzhongxin"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"},{name:"support",rawName:"v-support"}],attrs:{"type":"text","placeholder":"请您输入手机号","pad":"true","maxlength":"11"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value}}})]),(_vm.showWindow > 1)?_c('div',{staticClass:"silde-box"},[_c('p',{staticClass:"slide-bg",style:({ width: _vm.clientX - _vm.startX + 'px' })}),_c('div',{class:['slide-btn', { on: _vm.ifPass }],style:({ left: _vm.clientX - _vm.startX + 4 + 'px' }),on:{"mousedown":_vm.downSlide}},[_c('i',{class:[
                  'iconfont',
                  { iconicon_paging_right: !_vm.ifPass, iconsuccess: _vm.ifPass },
                ]})]),_c('span',{class:['slide-text', { on: _vm.ifPass }]},[_vm._v(_vm._s(_vm.ifPass ? '校验成功' : '请向右滑动验证'))])]):_vm._e(),(_vm.showWindow > 1)?_c('p',{staticClass:"window_shortp"},[_c('i',{staticClass:"iconduanxin iconfont"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.msgCode),expression:"msgCode"},{name:"support",rawName:"v-support"}],attrs:{"type":"text","placeholder":"请您输入验证码","pad":"true","autocomplete":"off"},domProps:{"value":(_vm.msgCode)},on:{"input":function($event){if($event.target.composing)return;_vm.msgCode=$event.target.value}}}),_c('span',{staticClass:"window_require",on:{"click":_vm.requestCode}},[_vm._v(_vm._s(_vm.codeText))])]):_vm._e(),_c('p',[_c('i',{staticClass:"iconfont iconpassword"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"},{name:"support",rawName:"v-support"}],attrs:{"type":"password","placeholder":"至少8位的数字+字母组合的密码","pad":"true","autocomplete":"off"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),(_vm.showWindow == 1)?_c('span',[_c('a',{staticClass:"forbtn",on:{"click":function($event){return _vm.changeType(3)}}},[_vm._v("忘记密码?")])]):_vm._e(),_c('div',{staticClass:"window_btnbox clear"},[(_vm.showWindow == 1)?_c('p',{staticClass:"logbtn fl lingred",on:{"click":_vm.login}},[_vm._v(" 登录 ")]):_vm._e(),(_vm.showWindow == 1)?_c('div',{staticClass:"btn-lingred regbox fr",on:{"click":function($event){return _vm.changeType(2)}}},[_c('span',{staticClass:"regbtn"},[_vm._v("注册")])]):_vm._e(),(_vm.showWindow == 2)?_c('p',{staticClass:"logbtn lingred modbtn",on:{"click":_vm.register}},[_vm._v(" 注册 ")]):_vm._e(),(_vm.showWindow == 3)?_c('p',{staticClass:"logbtn lingred modbtn",on:{"click":_vm.modlog}},[_vm._v(" 确认修改并登录 ")]):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }