<!-- 申请开票页面 -->
<template>
  <div class="invoice" :style="{ minHeight: $store.state.clientHeight }">
    <div class="page-box">
      <!-- ######################################################################################################################## -->
      <!-- 页面标题 开始 -->
      <div class="page-title clear">
        <div class="page-icon fl">
          <i class="iconfont iconfapiao"></i>
          <span>开具发票</span>
        </div>
        <div class="page-return fr" @click="$router.go(-1)">
          <i class="am iconfont iconzhiyuanfanhui3"></i>
          <span class="am">返回上一步</span>
        </div>
      </div>
      <!-- 页面标题 结束 -->
      <!-- ######################################################################################################################## -->

      <!-- ######################################################################################################################## -->
      <!-- 主内容 开始 -->
      <div class="invoice-container">
        <!-- 开票导航 -->
        <div class="invoice-guide clear">
          <div class="item active">选择开票商品及数量</div>
          <div class="item">填写开票信息</div>
          <div class="item">完成开票申请</div>
        </div>

        <!-- 开票提示 -->
        <div class="invoice-tip">
          发票申请请在3个月内开具，超出3个月需要自行点击链接再次触发
        </div>

        <!-- 书本列表 -->
        <div class="invoice-list">
          <div class="invoice-table clear">
            <!-- 列表 -->
            <div
              class="invoice-book"
              v-for="(item, index) in listData"
              :key="index"
            >
              <div class="w55 fl clear">
                <!-- 选中状态 -->
                <div
                  :class="['invoice-select', 'fl', { on: item.selected }]"
                  @click="selectThis(index)"
                >
                  <i class="iconfont iconsuccess"></i>
                </div>
                <!-- 图片 -->
                <div class="img-box">
                  <img :src="item.picture" alt="" class="fl" />
                </div>
                <!-- 标题 -->
                <div class="fl invoice-msg">
                  <p>{{ item.name }}</p>
                </div>
              </div>
              <p class="w20 fl">
                <!-- 数量 -->
                <span class="blue"
                  >共{{ item.count - item.invoice_count }}本</span
                >
                <!-- 价格 -->
                <span
                  >￥{{
                    (
                      item.uninvoiceNum * parseFloat(item.half_year_price)
                    ).toFixed(2)
                  }}/元</span
                >
              </p>
              <div class="w25 fl">
                <!-- 开票数量 -->
                <el-input
                  type="number"
                  v-model="item.fillinNum"
                  @input="(e) => inputMount(e, item, index)"
                  :min="item.min"
                  :max="item.max"
                ></el-input>
              </div>
            </div>

            <!-- 全选 -->
            <div class="invoice-selectAll" @click="selectAllFun">
              <div :class="['invoice-select', { on: selectedAll }]">
                <i class="iconfont iconsuccess"></i>
              </div>
              <span>全选</span>
            </div>
          </div>

          <!-- 数据统计 -->
          <div class="invoice-bottom clear">
            <span class="fl"
              >共选择<span class="blue"> {{ totalNumber || 0 }} </span
              >本,合计金额<span class="blue">
                {{ totalPrice.toFixed(2) }}元
              </span></span
            >
            <span class="invoice-btn fr" @click="nextStep"
              >确认并进行下一步</span
            >
          </div>
        </div>
      </div>
      <!-- 主内容 结束 -->
      <!-- ######################################################################################################################## -->
    </div>
  </div>
</template>

<script>
let that
import bookImg from '@/assets/images/1.png'
export default {
  data: () => {
    return {
      // 订单号
      orderNo: '',
      // 开票书本列表
      listData: [],
      // 全选按钮状态
      selectedAll: false,
    }
  },
  created() {
    that = this
    // 获取订单号
    this.orderNo = this.$route.query.order_no
    // 查询开票书本列表
    this.queryListData()
  },
  computed: {
    // 总数量
    totalNumber: function () {
      let number = 0
      this.listData.forEach((el) => {
        if (el.selected) {
          number = number + el.fillinNum
        }
      })
      return number
    },

    // 总金额
    totalPrice: function () {
      let price = 0
      this.listData.forEach((el) => {
        if (el.selected) {
          price = price + el.fillinNum * parseFloat(el.half_year_price)
        }
      })
      return price
    },
  },
  methods: {
    /**
     * ####################################################################################################
     * ## 选择全部/取消选择全部
     * ####################################################################################################
     */
    selectAllFun: function () {
      // 更新全选按钮状态
      this.selectedAll = !this.selectedAll
      // 更新item选择状态
      this.listData.forEach((item, index) => {
        item.selected = this.selectedAll ? true : false
      })
    },

    /**
     * ####################################################################################################
     * ## 选中/取消选中
     * ####################################################################################################
     */
    selectThis: function (index) {
      // 更新选中状态
      let item = this.listData[index]
      item.selected = !item.selected
      this.listData.splice(index, 1, item)

      // 更新全选按钮状态
      let selectedItems = this.listData.filter((el) => {
        return el.selected
      })
      if (selectedItems.length < this.listData.length) {
        this.selectedAll = false
      } else {
        this.selectedAll = true
      }
    },

    // 输入开票数量
    inputMount: function (e, item, index) {
      // 强制限制开票数量
      if (e < item.min) {
        this.listData[index].fillinNum = item.min
      } else if (e > item.max) {
        this.listData[index].fillinNum = item.max
      } else {
        this.listData[index].fillinNum = parseInt(e)
      }
    },

    /**
     * ####################################################################################################
     * ## 查询开票书本列表
     * ####################################################################################################
     */
    queryListData: function () {
      that
        .$http({
          url: 'invoice/invoice_book',
          method: 'GET',
          params: {
            order_no: this.orderNo,
          },
        })
        .then((res) => {
          //   console.log(res);
          // 格式化数据
          res.result.forEach((el) => {
            // 为数据添加默认选中状态
            el.selected = false
            // 计算未开票书籍数量
            el.uninvoiceNum = el.count - el.invoice_count
            // 最小可开数量
            el.min = 1
            // 最大可开数量
            el.max = el.count - el.invoice_count
            // 填写的开票数量
            el.fillinNum = el.count - el.invoice_count
          })
          // 更新列表数据
          that.listData = res.result
        })
        .catch((err) => {
          console.error(err)
        })
    },

    /**
     * ####################################################################################################
     * ## 下一步
     * ####################################################################################################
     */
    nextStep: function () {
      // 判断是否选择了书籍
      if (this.totalNumber <= 0) {
        this.$message.error('错了哦，还没有选择书籍！')
        return
      }
      // 存储选择的数据，下一步使用
      let goodsData = []
      this.listData.forEach((el) => {
        if (el.selected) {
          goodsData.push({
            book_no: el.book_no,
            count: el.fillinNum,
          })
        }
      })
      sessionStorage['InvoiceTwoGoodsData'] = JSON.stringify(goodsData)
      this.$router.push({
        path: '/invoiceTwo',
        query: { order_no: this.orderNo },
      })
    },
  },
}
</script>

<style>
.invoice {
  padding-top: 90px;
}
.invoice-container {
  width: 900px;
  margin: 30px auto 100px;
}
.invoice-container .invoice-guide {
  width: 100%;
  overflow: hidden;
}
.invoice-container .invoice-guide .item {
  float: left;
  position: relative;
  width: 33.33%;
  color: #3a3a3a;
  font-size: 0.16rem;
  text-align: center;
  background: #eeeeee;
  height: 56px;
  line-height: 56px;
}
.invoice-container .invoice-guide .item.active {
  background-color: #3f7ad2;
  background: linear-gradient(90deg, #3fa6d2, #3f7ad2);
  color: #ffffff;
}
.invoice-container .invoice-guide .item:before {
  content: '';
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  right: -56px;
  z-index: 10;
  border-width: 28px;
  border-style: solid;
  box-sizing: border-box;
  border-color: rgba(0, 0, 0, 0);
  border-left-color: #eeeeee;
}
.invoice-container .invoice-guide .item:after {
  content: '';
  width: 60px;
  height: 60px;
  position: absolute;
  top: -2px;
  right: -61px;
  z-index: 5;
  border-width: 30px;
  border-style: solid;
  box-sizing: border-box;
  border-color: rgba(0, 0, 0, 0);
  border-left-color: #ffffff;
}
.invoice-container .invoice-guide .item.active:before {
  border-left-color: #3f7ad2;
}
.invoice-tip {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #f8e3b6;
  font-size: 0.16rem;
  color: #976b00;
  text-align: center;
  margin-top: 22px;
}
.invoice-list {
  width: 100%;
  margin-top: 30px;
}
.invoice-list .invoice-table {
  border: 1px solid #eeeeee;
  margin-bottom: 20px;
}
.invoice-table .invoice-book {
  height: 190px;
  border-bottom: 1px solid #eeeeee;
}
.invoice-table .w20 {
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  height: 100%;
}
.invoice-select {
  width: 24px;
  height: 24px;
  margin: 83px 30px 0;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  text-align: center;
  cursor: pointer;
}
.invoice-select i {
  color: #ffffff;
  line-height: 24px;
}
.invoice-select.on {
  background: rgb(63, 122, 210);
  background: linear-gradient(rgb(63, 122, 210) 0%, rgb(63, 166, 210) 100%);
  border: 1px solid rgb(63, 122, 210);
}
.invoice-select.on i {
  color: #ffffff;
}
.invoice-book > div {
  height: 100%;
}
.invoice-msg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  width: 50%;
}
.invoice-msg p {
  font-weight: bold;
  color: #333333;
  font-size: 0.18rem;
}
.invoice-msg span {
  font-size: 0.14rem;
  color: #999999;
}
.invoice-book > p .blue {
  color: #3b77d1;
  font-size: 0.18rem;
  text-align: center;
  width: 100%;
  display: inline-block;
  margin-top: 70px;
}
.invoice-book > p span {
  color: #999999;
  font-size: 0.16rem;
  text-align: center;
  width: 100%;
  display: inline-block;
}
.invoice-book .el-input {
  width: 80%;
  display: block;
  margin: 75px auto;
}
.invoice-selectAll {
  cursor: pointer;
}
.invoice-selectAll .invoice-select {
  margin: 30px 15px 30px 30px;
  display: inline-block;
}
.invoice-selectAll span {
  font-size: 0.18rem;
}
.invoice-list .invoice-bottom {
  width: 100%;
}
.invoice-list .invoice-bottom .fl {
  line-height: 50px;
}
.invoice-list .invoice-bottom .fl .blue {
  color: #3b77d1;
  font-size: 0.18rem;
}
.invoice-container .invoice-btn {
  display: inline-block;
  padding: 0px 10px;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  border: none;
  opacity: 1;
  background: #3f7ad2;
  background: linear-gradient(90deg, #3fa6d2, #3f7ad2);
  outline: none;
  transition: all 0.2s;
  cursor: pointer;
  line-height: 0.46rem;
}
.invoice-container .invoice-btn:hover {
  opacity: 0.8;
}
.invoice-list .img-box {
  width: 120px;
  padding: 20px 10px;
  position: relative;
  height: 100%;
  float: left;
}
.invoice-book img {
  max-width: 100px;
  height: auto;
  position: absolute;
  left: 10px;
}
</style>
