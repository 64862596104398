<template>
  <div class="error-container">
    <p>未查询到您的历史订单</p>
    <div>
      <div class="btn" @click="refresh">刷新试试</div>
      <div class="btn" @click="returnHome">返回订阅平台</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      city: '',
      cityName: '',
      xzType: '',
    }
  },
  mounted() {
    this.city = this.$route.query.city || ''
    this.xzType = this.$route.query.xz || ''
    this.cityName =
      this.city && this.city == '1'
        ? '济南市'
        : this.city == '2'
        ? '东营市'
        : this.city == '3'
        ? '枣庄市'
        : '淄博市'
  },
  methods: {
    // 点击刷新试试
    refresh: function () {
      this.$http({
        url: 'passport/old_version_url',
        method: 'GET',
        params: {
          city: this.cityName,
          xz_type: this.xzType,
          type: 'pc',
        },
      })
        .then((res) => {
          window.location.href = res.result.url
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 点击返回平台
    returnHome: function () {
      sessionStorage.removeItem('cityName')
      this.$router.push('/')
    },
  },
}
</script>

<style scoped>
.error-container {
  height: 600px;
  height: 100%;
  text-align: center;
  padding-top: 200px;
}
.error-container p {
  color: #aaaaaa;
  font-size: 30px;
  margin: 70px 0;
}
.error-container > div {
  display: flex;
  align-items: center;
  width: 800px;
  margin: 0 auto;
  justify-content: center;
}
.error-container > div .btn {
  width: 200px;
  line-height: 50px;
  background: #007bff;
  background: linear-gradient(
    105deg,
    rgb(63, 129, 210) 0%,
    rgb(136, 210, 103) 100%
  );
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.error-container > div .btn:hover {
  opacity: 0.8;
}
.error-container > div div:first-child {
  margin-right: 80px;
}
</style>
