<template>
  <div class="confirm">
    <div class="confirm-box">
      <div class="confirm-title lingred">
        <span>订单列表确认</span>
        <i class="window-close iconfont iconclose" @click="clickClose"></i>
      </div>
      <div class="list">
        <div class="line clear">
          <span class="w125">序号</span>
          <span class="w40">刊物名称</span>
          <span class="w15">数量</span>
          <span class="w15">价格</span>
          <span class="w175">总价</span>
        </div>
        <div v-for="(item, index) in list" :key="index" class="line">
          <span class="w125">{{ index + 1 }}</span>
          <span class="nowrap w40">{{ item.name }}</span>
          <span class="w15">{{ item.count }}</span>
          <span class="w15">￥{{ item.half_year_price }}元</span>
          <span class="w175"
            >￥{{ (item.count * item.half_year_price).toFixed(2) }}元</span
          >
        </div>
      </div>
      <p class="order-msg">
        合计：<span>￥{{ bookFee.toFixed(2) }}元</span>（刊物费）+<span
          >￥{{ postFee.toFixed(2) }}元</span
        >（运费）<b>￥{{ allFee.toFixed(2) }}元</b>
      </p>
      <!-- 支付按钮 -->
      <div class="btn-box" v-if="payType == 1">
        <div class="lingred pay-btn" @click="toPay()">微信支付</div>
        <div
          v-if="official == 1"
          class="lingred pay-btn"
          @click="officialCardPay()"
        >
          公务卡支付
        </div>
      </div>
      <div v-else class="btn-box">
        <div class="lingred pay-btn" @click="bankPay(1)">扫码支付</div>
        <div class="lingred pay-btn" @click="bankPay(2)">银联支付</div>
        <!--        济南、菏泽 暂时不开放公务卡订单-->
        <div
          v-if="official == 1"
          class="lingred pay-btn"
          @click="officialCardPay()"
        >
          公务卡支付
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payType: sessionStorage['payType'] || 1,
      city: sessionStorage['city'] || '',
      official: sessionStorage['official'] || '',
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
    bookFee: {
      type: Number,
      default() {
        return 0
      },
    },
    postFee: {
      type: Number,
      default() {
        return 0
      },
    },
    allFee: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  mounted() {
    // console.log(this.list);
  },
  methods: {
    // 点击取消
    clickClose: function () {
      this.$emit('clickClose')
    },
    // 点击微信支付
    toPay: function () {
      this.$router.replace({ path: '/wxPay' })
    },
    // 建行支付
    bankPay: function (type) {
      let orderNo = JSON.parse(sessionStorage['payCode']).orderId
      this.$http({
        url: 'order/pay_again',
        showLoading: true,
        data: {
          order_no: orderNo,
          source: type,
        },
      })
        .then((res) => {
          this.$router.push('/orders')
          window.open(res.result.url)
        })
        .catch((err) => {
          console.error(err)
        })
    },

    /**
     * ****************************************************************************************************
     * ** 公务卡支付
     * ****************************************************************************************************
     */
    officialCardPay() {
      let orderNo = JSON.parse(sessionStorage['payCode']).orderId
      this.$router.push({
        path: '/businessCardBindingWechatOperationInstructions',
        query: { order_no: orderNo },
      })
      return
      // this.$http({
      // 	url:'order/official',
      // 	showLoading:true,
      // 	data:{
      // 		order_no:orderNo,
      // 	}
      // }).then(res => {
      //   console.log('officialCardPay success');
      //   this.$router.push({
      //     path: '/businessCardBindingWechatOperationInstructions',
      //     query: { order_no:  orderNo}
      //   });
      // }).catch(err => {
      // 	console.log(err);
      // })
    },
  },
}
</script>

<style scoped>
.confirm {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}
.confirm .confirm-box {
  width: 860px;
  max-height: 580px;
  margin-left: -430px;
  position: absolute;
  left: 50%;
  top: 50%;
  padding-bottom: 40px;
  margin-top: -290px;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
}
.confirm .confirm-box > p {
  text-align: center;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}
.confirm .confirm-box > div {
  width: 100%;
}
.confirm .confirm-box > div .btn {
  cursor: pointer;
  width: 140px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 30px;
  font-size: 16px;
}
.confirm .confirm-box > div .close {
  background: #f2f2f2;
  color: #999;
}
.confirm-title {
  width: 100%;
  position: relative;
  height: 80px;
}
.confirm-title span {
  text-align: center;
  font-size: 0.26rem;
  color: #fff;
  line-height: 80px;
  display: inline-block;
  width: 100%;
}
.confirm-title i {
  color: #666;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 28px;
  font-weight: 100;
  padding: 10px;
  cursor: pointer;
}
.confirm-box .list {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}
.confirm-box .list .line {
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  height: 50px;
}
.confirm-box .list .line span {
  line-height: 50px;
  float: left;
  font-size: 18px;
  color: #3a3a3a;
  text-align: center;
}
.confirm .order-msg {
  text-align: right !important;
  padding-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.confirm .confirm-box .pay-btn {
  text-align: center;
  display: block;
  width: 220px;
  height: 60px;
  line-height: 60px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  margin: 0 auto;
}
.confirm .confirm-box .order-msg span {
  color: #333333;
}
.confirm .confirm-box .order-msg b {
  font-size: 0.28rem;
  color: #3b77d1;
  font-weight: bold;
}
.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm .confirm-box .btn-box .pay-btn {
  margin: 0 30px;
}
</style>
