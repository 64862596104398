<template>
	<div class="print">
		<!--startprint-->
		<table width="100%" border="0">
			<tr class="head"><td colspan="4">山东教育社期刊订单</td></tr>
			<tr class="head" bgcolor="#f0f0f0">
				<td colspan="4">订单号：{{ orderPrintData.order_no }} 日期：{{ orderPrintData.create_time }}</td>
			</tr>
			<tr>
				<td><strong>期刊</strong></td>
				<td><strong>价格</strong></td>
				<td><strong>份数</strong></td>
				<td><strong>总计</strong></td>
			</tr>
			<tr v-for="(item, index) in orderPrintData.books" :key="index">
				<td>{{ item.name }}</td>
				<td>{{ item.half_year_price }}元</td>
				<td>{{ item.count }}</td>
				<td>{{ item.total_price }}元</td>
			</tr>
			<tr>
				<td colspan="2"><strong>合计</strong></td>
				<td>{{orderPrintData.total_count}}</td>
				<td>{{orderPrintData.total_price}}元</td>
			</tr>
			<tr class="head" bgcolor="#f0f0f0"><td colspan="4">客户信息</td></tr>
			<tr>
				<td><strong>姓名</strong></td>
				<td><strong>电话</strong></td>
				<td><strong>学校</strong></td>
				<td><strong>年级 班级</strong></td>
			</tr>
			<tr>
				<td>{{ orderPrintData.address.name }}</td>
				<td>{{ orderPrintData.address.phone }}</td>
				<td>{{ orderPrintData.address.school }}</td>
				<td v-if="orderPrintData.address.grade_name">{{ orderPrintData.address.grade_name }} {{ orderPrintData.address.classes_name }}</td>
				<td v-else>无</td>
			</tr>
		</table>

		<!--endprint-->
		<div class="opbtn" v-if="showBtn">
			<a class="printnow" @click="print">打印</a>
			<a class="close" @click="close">关闭</a>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			orderPrintData: sessionStorage['orderPrintData'] ? JSON.parse(sessionStorage['orderPrintData']) : null, // 订单数据
			showBtn:true,										// 打印隐藏按钮
		};
	},
	methods: {
		// 点击打印
		print: function() {
			this.showBtn = false;
			setTimeout(()=>{
				window.print();
				this.showBtn = true;
			},400)
		},
		// 点击关闭
		close: function() {
			if (navigator.userAgent.indexOf('MSIE') > 0) {
				if (confirm('您确定要关闭本页吗？')) {
					window.opener = null;
					window.open('', '_top');
					window.top.close();
				}
			} else if (navigator.userAgent.indexOf('Firefox') > 0) {
				if (confirm('您确定要关闭本页吗？')) {
					window.opener = null;
					window.open('', '_self', '');
					window.close();
				}
			} else {
				if (confirm('您确定要关闭本页吗？')) {
					window.opener = null;
					window.open('', '_self', '');
					window.close();
				}
			}
		}
	}
};
</script>

<style scoped>
	.print {max-width: 960px;margin: 0 auto;position: relative;padding-top: 30px;}
	.opbtn {text-align: right;}
	.opbtn a {cursor: pointer;padding: 0 0 0 15px;font-size: 0.2rem;font-weight: bold;}
	.opbtn a:hover {color: red;}
	table {border-collapse: collapse;max-width: 100%;width: 100%;margin: 1em 0;}
	table td {border: 1px solid #666;padding: 12px 10px;vertical-align: middle;}
	table tr.head {text-align: center;line-height: 55px;font-size: 24px;}
</style>
