<template>
  <div class="confirm">
    <div class="confirm-box">
      <div class="confirm-title lingred">
        <span>{{ title }}</span>
      </div>
      <div class="confirm-text">
        <div v-html="text"></div>
      </div>
      <div class="btn-box">
        <div class="lingred pay-btn" @click="conExe">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      title: '请各位读者注意',
      //   text: '如您所订购的书刊在订阅批次一年内出现问题，请您第一时间拨打我们的咨询电话；超出一年将无法处理，感谢您的理解。书刊订阅批次请在“我的订单”中查看。',
      text: '您订购的书籍将于<b style="color: red;">9月份升年级后</b>发放到学校，请务必选择<b style="color: red;">升级后的书籍</b>并且填写<b style="color: red;">升级后所在的年级</b>。',
    }
  },
  mounted() {
    // 查询提示内容
    this.queryTips()
  },
  methods: {
    //继续执行
    conExe() {
      //   console.log('click')
      this.$emit('conExe')
    },

    /**
     * @description: 查询提示内容
     * @return {*}
     */
    queryTips() {
      this.$http({
        method: 'GET',
        url: 'web_config/upgrade_letter',
        data: {},
      })

        .then((res) => {
          this.title = res.result.upgrade_title
          this.text = res.result.upgrade_letter
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
</script>
<style scoped>
.confirm {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}
.confirm .confirm-box {
  width: 660px;
  max-height: 580px;
  margin-left: -330px;
  position: absolute;
  left: 50%;
  top: 50%;
  padding-bottom: 40px;
  margin-top: -290px;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
}

.confirm .confirm-box .confirm-text {
  font-size: 18px;
  padding: 30px 50px;
  line-height: 1.9;
  text-indent: 2em;
}
.confirm .confirm-box > p {
  text-align: center;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}
.confirm .confirm-box > div {
  width: 100%;
}
.confirm .confirm-box > div .btn {
  cursor: pointer;
  width: 140px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 30px;
  font-size: 16px;
}
.confirm-title {
  width: 100%;
  position: relative;
  height: 80px;
}
.confirm-title span {
  text-align: center;
  font-size: 0.26rem;
  color: #fff;
  line-height: 80px;
  display: inline-block;
  width: 100%;
}
.confirm .confirm-box .pay-btn {
  text-align: center;
  display: block;
  width: 220px;
  height: 60px;
  line-height: 60px;
  border: none;
  border-radius: 30px;
  font-size: 22px;
  cursor: pointer;
  margin: 0 auto;
}
.confirm .confirm-box .order-msg span {
  color: #333333;
}
.confirm .confirm-box .order-msg b {
  font-size: 0.28rem;
  color: #3b77d1;
  font-weight: bold;
}
.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm .confirm-box .btn-box .pay-btn {
  margin: 0 30px;
}
</style>
