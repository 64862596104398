<template>
  <div class="window">
    <div class="window-box">
      <div class="window-con">
        <i class="window-close iconfont iconclose" @click="closeWindow"></i>
        <img class="window-bgone" :src="windowA" alt="" />
        <img class="window-bgtwo" :src="windowB" alt="" />
        <div class="window-title">
          {{ type == 1 ? '添加地址' : '修改地址' }}
        </div>
        <div class="window-from">
          <!-- 所属区域 -->
          <p><span>*</span>所属区域</p>
          <div class="line clear">
            <div class="city fl">{{ cityValue }}</div>
            <el-select
              v-model="districtValue"
              placeholder="请选择区县"
              class="short margin"
              @change="changeDistrict"
            >
              <el-option
                v-for="(item, index) in districtList"
                :key="index"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
            <el-select
              v-model="streetValue"
              placeholder="请选择所属街道"
              class="margin"
              @change="changeStreet"
            >
              <el-option
                v-for="(item, index) in streetList"
                :key="index"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <!-- 所属学校 -->
          <p><span>*</span>所属学校</p>
          <div class="line clear">
            <el-select
              v-model="schoolTypeValue"
              placeholder="请选择学校类型"
              @change="changeSchoolType"
            >
              <el-option
                v-for="(item, index) in schoolTypeList"
                :key="index"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
            <el-select
              v-model="schoolValue"
              placeholder="请选择所属学校"
              class="margin"
              @change="changeSchool"
            >
              <el-option
                v-for="(item, index) in schoolList"
                :key="index"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <!-- 姓名年级 -->
          <p>
            <span class="half"><span>*</span>姓名</span
            ><span class="half"><span>*</span>年级</span>
          </p>
          <div class="line clear">
            <input
              type="text"
              placeholder="请输入姓名"
              v-support
              v-model="nameValue"
              maxlength="50"
            />
            <!-- @visible-change="focusGrade" -->
            <el-select
              v-model="gradeValue"
              :disabled="!showGradeClass"
              placeholder="请选择年级"
              class="margin"
              @change="changeGrade"
            >
              <el-option
                v-for="(item, index) in gradeList"
                :key="index"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <!-- 姓名年级 -->
          <p>
            <span class="half"><span>*</span>班级</span
            ><span class="half"><span>*</span>手机号</span>
          </p>
          <div class="line clear">
            <el-select
              v-model="classValue"
              :disabled="!showGradeClass || !showClass"
              placeholder="请选择班级"
              @change="changeClass"
            >
              <el-option
                v-for="(item, index) in classList"
                :key="index"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
            <input
              type="text"
              placeholder="请输入手机号"
              v-support
              v-model="telValue"
              class="margin"
            />
          </div>
        </div>
        <!-- 添加 -->
        <div class="lingred address-btn" @click="addAdress">
          {{ type == 1 ? '添加' : '修改' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import windowA from '@/assets/images/windowa.jpg'
import windowB from '@/assets/images/windowb.jpg'
let that
export default {
  data: function () {
    return {
      windowA, // 背景图a
      windowB, // 背景图b
      cityValue: '', // 所属城市绑定的值(文本)。
      nameValue: '', // 姓名绑定的值。
      telValue: '', // 电话绑定的值。
      orderNum: '', // 订单号
      // 区县
      districtValue: '', // 所属区县绑定的值。
      districtId: '', // 所属区县id。
      districtList: [], // 区县列表。
      // 街道
      streetValue: '', // 所属街道绑定的值。
      streetList: [], // 街道列表。
      streetId: '', // 街道Id
      // 学校类型
      schoolTypeValue: '', // 学校类型绑定的值。
      schoolTypeId: '', // 选中的学校类型id。
      schoolTypeList: [], // 学校类型列表。
      schoolTypeInitList: [], // 学校类型-年级原始数据列表
      // 所属学校
      schoolValue: '', // 所属学校绑定的值。
      schoolId: '', // 选中的所属学校id。
      schoolList: [], // 学校列表。
      // 年级
      gradeValue: '', // 年级绑定的值。
      gradeId: '', // 年级Id
      gradeList: [], // 年级列表。
      // 班级
      classValue: '', // 班级绑定的值。
      classId: '', // 班级Id
      classList: [], // 班级列表。
      showGradeClass: true, // 填写年级班级
      showClass: true, // 填写班级
    }
  },
  props: {
    type: [String, Number], // 1:添加;2:修改;3:订单修改
    showBox: [Boolean], // true:展示;false:关闭
    orderNo: [Object], // 订单编号
  },
  created() {
    that = this
  },
  watch: {
    showBox: (newValue, oldValue) => {
      if (newValue) {
        if (that.type == '3') {
          that.requestAddress(that.orderNo)
        } else {
          that.requestAddress()
        }
      }
    },
  },
  mounted() {
    if (this.type == '3') {
      that.requestAddress(that.orderNo)
    } else {
      that.requestAddress()
    }
  },
  methods: {
    // 初始化方法
    initFun: function () {
      this.cityValue = sessionStorage['cityName']
      // 如果是修改,从缓存拿数据
      if (this.type == 2) {
        let address = JSON.parse(sessionStorage['addressMsg'])
        if (address) {
          this.cityValue = address.cityValue // 所属城市。
          this.cityId = address.cityId // 所属城市。
          this.districtValue = address.districtValue // 所属区县。
          this.districtId = address.districtId // 区县id。
          this.streetValue = address.streetValue // 所属街道。
          this.streetId = address.streetId // 街道id。
          this.schoolTypeId = address.schoolTypeId // 学校类型（id）。
          this.schoolTypeValue = address.schoolTypeValue // 学校类型（文本）。
          this.schoolValue = address.schoolValue // 所属学校（文本）。
          this.schoolId = address.schoolId // 所属学校(id)
          this.nameValue = address.nameValue // 姓名。
          this.gradeValue = address.gradeValue // 年级。
          this.gradeId = address.gradeId // 年级(id)
          this.classValue = address.classValue // 班级(文本)。
          this.classId = address.classId // 班级(id).
          this.telValue = address.telValue // 电话。
          // 设置各类型的初始化列表
          this.streetList = this.districtList.filter((item) => {
            return item.id == that.districtId
          })[0].rows // 处理街道列表
          this.schoolTypeList = this.streetList.filter((item) => {
            return item.id == that.streetId
          })[0].rows // 处理学校类型列表
          this.schoolList = this.schoolTypeList.filter((item) => {
            return item.id == that.schoolTypeId
          })[0].rows // 处理学校列表
          let initGrades = []
          initGrades = this.schoolTypeInitList.filter((item) => {
            return item.id == that.schoolTypeId
          })[0] // 处理年级列表
          this.gradeList =
            initGrades.is_show_class == 1 ? initGrades.grades : []
          this.showGradeClass = initGrades.is_show_class == 1 ? true : false
          let initClass = this.gradeList.filter((item) => {
            return item.id == that.gradeId
          })[0].is_class // 处理是否展示班级
          this.showClass = initClass == 1 ? true : false
        }
      } else if (this.type == 3) {
        this.$http({
          url: 'order/get_edit_address',
          method: 'GET',
          params: {
            order_no: that.orderNo.order_no,
          },
        })
          .then((res) => {
            // console.log(res)
            that.districtId = res.result.area_id
            that.streetId = res.result.street_id
            that.schoolTypeId = res.result.school_type_id
            that.schoolId = res.result.mechanism_id
            that.gradeId = res.result.grade_id
            that.nameValue = res.result.name
            that.telValue = res.result.phone
            that.classId = res.result.classes_id
            let district = that.districtList.filter((el) => {
              return el.id == that.districtId
            })
            that.districtValue = district[0].name
            that.streetList = district[0].rows
            let street = that.streetList.filter((el) => {
              return el.id == that.streetId
            })
            that.streetValue = street[0].name
            that.schoolTypeList = street[0].rows
            let schoolType = that.schoolTypeList.filter((el) => {
              return el.id == that.schoolTypeId
            })
            that.schoolTypeValue = schoolType[0].name
            that.schoolList = schoolType[0].rows
            let school = that.schoolList.filter((el) => {
              return el.id == that.schoolId
            })
            that.schoolValue = school[0].name
            let gradeList = that.schoolTypeInitList.filter((el) => {
              return el.id == that.schoolTypeId
            })
            that.showGradeClass = gradeList[0].is_show_class
            that.gradeList = gradeList[0].grades
            let grade = that.gradeList.filter((el) => {
              return el.id == that.gradeId
            })
            that.gradeValue = grade[0].name
            that.gradeId = grade[0].id
            that.showClass = grade[0].is_class == 1 ? true : false
            if (that.showClass) {
              that.classValue = that.classList.filter((el) => {
                return el.id == that.classId
              })[0].name
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },

    // 点击关闭按钮
    closeWindow: function () {
      this.$emit('closeBox')
    },

    // 选择了区县
    changeDistrict: function (e) {
      if (e.id != this.districtId) {
        this.streetValue =
          this.schoolTypeValue =
          this.schoolValue =
          this.gradeValue =
          this.streetId =
          this.schoolTypeId =
          this.schoolId =
          this.gradeId =
            ''
      }
      this.districtId = e.id
      this.districtValue = e.name
      this.streetList = e.rows
    },

    // 选择了街道
    changeStreet: function (e) {
      if (!this.districtValue) {
        this.$message.error('请先选择所属区县')
        return false
      }
      if (e.id != this.streetId) {
        this.schoolTypeValue =
          this.schoolValue =
          this.gradeValue =
          this.schoolTypeId =
          this.schoolId =
          this.gradeId =
            ''
      }
      this.streetId = e.id
      this.schoolTypeList = e.rows
      this.streetValue = e.name
    },

    // 选择了学校类型
    changeSchoolType: function (e) {
      if (!this.streetValue) {
        this.$message.error('请先选择所属街道')
        return false
      }
      let list = this.schoolTypeInitList.filter((item) => {
        return item.id == e.id
      })
      if (list[0].is_show_class == 1) {
        // 如果是需要展示年级班级
        that.gradeList = list[0].grades
        that.showGradeClass = true
      } else {
        // 如果不需要展示年级班级
        that.gradeValue = ''
        that.classValue = ''
        that.gradeId = ''
        that.classId = ''
        that.showGradeClass = false
      }
      // 判断是否改变
      if (e.id != this.schoolTypeId) {
        this.schoolValue =
          this.gradeValue =
          this.classValue =
          this.schoolId =
          this.gradeId =
            ''
      }
      this.schoolTypeId = e.id
      this.schoolTypeValue = e.name
      this.schoolList = e.rows
    },

    // 选择了学校
    changeSchool: function (e) {
      if (!this.schoolTypeValue) {
        this.$message.error('请先选择学校类型')
        return false
      }
      this.schoolId = e.id
      this.schoolValue = e.name
    },

    // 聚焦年级
    focusGrade: function (e) {
      if (e && this.showGradeClass) {
        alert('本次征订，请选择2022年秋季开学时学生所在年级。')
      }
    },

    // 选择了年级
    changeGrade: function (e) {
      // console.log(e);
      if (!this.schoolTypeValue) {
        this.$message.error('请先选择学校类型')
        return false
      }
      this.gradeId = e.id
      this.gradeValue = e.name
      if (e.is_class == 0) {
        this.showClass = false
        this.classId = ''
        this.classValue = ''
      } else {
        this.showClass = true
      }
    },

    // 选择了班级(班级不受层级影响)
    changeClass: function (e) {
      this.classId = e.id
      this.classValue = e.name
    },

    // 获取地址列表数据
    requestAddress: function (e) {
      that
        .$http({
          url: 'city/address',
          method: 'GET',
          params: {
            city_id: e ? e.city_id : sessionStorage['city'],
            xz_type: e ? e.xz_type : sessionStorage['xzType'],
          },
        })
        .then((res) => {
          sessionStorage['addressData'] = JSON.stringify(res.result)
          that.districtList = res.result.address
          that.classList = res.result.classes
          that.schoolTypeInitList = res.result.school_type
          that.initFun()
        })
        .catch((err) => {
          console.error(err)
        })
    },

    // 点击添加/修改地址
    addAdress: function () {
      // console.log(this.showClass)
      if (!this.districtValue) {
        this.$message.error('请选择区县')
        return false
      }
      if (!this.streetValue) {
        this.$message.error('请选择街道')
        return false
      }
      if (!this.schoolTypeValue) {
        this.$message.error('请选择学校类型')
        return false
      }
      if (!this.schoolValue) {
        this.$message.error('请选择学校')
        return false
      }
      if (!this.nameValue) {
        this.$message.error('请输入姓名')
        return false
      }
      if (this.showGradeClass && !this.gradeValue) {
        this.$message.error('请选择年级')
        return false
      }
      if (this.showGradeClass && this.showClass && !this.classValue) {
        this.$message.error('请选择班级')
        return false
      }
      if (!this.telValue) {
        this.$message.error('请输入手机号')
        return false
      }
      if (!this.$store.state.regPhone.test(this.telValue)) {
        this.$message.error('请输入正确的手机号')
        return false
      }
      // 如果是未支付状态下
      if (this.type == '1' || this.type == '2') {
        let obj = {
          cityValue: that.cityValue,
          nameValue: that.nameValue,
          telValue: that.telValue,
          districtValue: that.districtValue,
          districtId: that.districtId,
          streetValue: that.streetValue,
          streetId: that.streetId,
          schoolTypeValue: that.schoolTypeValue,
          schoolTypeId: that.schoolTypeId,
          schoolValue: that.schoolValue,
          schoolId: that.schoolId,
          gradeValue: that.gradeValue,
          gradeId: that.gradeId,
          classValue: that.classValue,
          classId: that.classId,
          showGradeClass: that.showGradeClass,
        }
        sessionStorage['addressMsg'] = JSON.stringify(obj)
        this.$emit('addAdress')
      } else {
        this.$http({
          url: 'order/edit_address',
          data: {
            order_no: that.orderNo.order_no,
            mechanism_id: that.schoolId,
            school_type_id: that.schoolTypeId,
            name: that.nameValue,
            phone: that.telValue,
            grade_id: that.gradeId,
            classes_id: that.classId,
          },
        })
          .then((res) => {
            this.$message.success('更换成功')
            this.$emit('addAdress')
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
  },
}
</script>

<style>
.window {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 1000;
}
.window-box {
  width: 720px;
  padding: 28px 25px;
  margin-left: -360px;
  overflow: hidden;
  position: fixed;
  top: 10%;
  left: 50%;
  border-radius: 6px;
  background: url(../assets/images/logwindow.jpg) no-repeat;
  background-position: top;
}
.window-con {
  width: 100%;
  position: relative;
  background: #fff;
  padding: 55px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  min-height: 400px;
}
.window-con .window-title {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: rgb(51, 51, 51);
  line-height: 2;
  margin-bottom: 10px;
}
.window-con .window-close {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  font-size: 24px;
  color: #aaaaaa;
  padding: 10px;
  z-index: 10;
}
.window-con .window-bgone {
  position: absolute;
  top: 0;
  left: 0;
}
.window-con .window-bgtwo {
  position: absolute;
  bottom: 0;
  right: 0;
}
.window_con .window_title {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: rgb(51, 51, 51);
  line-height: 2;
  margin-bottom: 30px;
}
.window-from {
  position: relative;
  z-index: 1;
}
.window-from > p {
  margin-bottom: 5px;
  font-size: 14px;
  color: #999999;
}
.window-from > p .half {
  display: inline-block;
  width: 49%;
  color: #999999;
}
.window-from > p > span,
.window-from > p .half span {
  color: #ff0000;
  margin-right: 5px;
}
.line {
  width: 100%;
  margin-bottom: 10px;
}
.line .city {
  width: 105px;
  height: 60px;
  background: rgb(249, 249, 249);
  border-radius: 6px;
  text-align: center;
  color: rgb(102, 102, 102);
  line-height: 60px;
  font-size: 18px;
}
.line > input {
  width: 270px;
  height: 60px;
  background: rgb(249, 249, 249);
  border-radius: 6px;
  font-size: 18px;
  float: left;
}
.line .el-select {
  float: left;
  width: 270px;
  overflow: hidden;
}
.line .el-select,
.line .el-input,
.line input {
  height: 60px;
  font-size: 18px;
  background: rgb(249, 249, 249);
  border: none;
}
.line .short {
  width: 156px !important;
}
.line .margin {
  margin-left: 10px !important;
}
.address-btn {
  display: block;
  width: 260px;
  height: 60px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  margin: 25px auto 0;
  text-align: center;
  line-height: 60px;
}
</style>
