import Vue from 'vue'
import VueRouter from 'vue-router'
import util from './assets/js/util'
Vue.use(VueRouter)

import Index from './pages/Index.vue'
import WxPay from './pages/WxPay.vue'
import Print from './pages/Print.vue'
import Error from './pages/Error.vue'

import Choose from './pages/Choose.vue'
import PlaceLogin from './pages/PlaceLogin.vue'
import BookList from './pages/BookList.vue'
import Process from './pages/Process.vue'
import Detail from './pages/Detail.vue'
import ShopCar from './pages/ShopCar.vue'
import Confirm from './pages/Confirm.vue'
import Orders from './pages/Orders.vue'
import MaterialOrder from './pages/Material/OrderList.vue'
import InvoiceProcess from './pages/invoice/InvoiceProcess.vue'
import InvoiceOne from './pages/invoice/InvoiceOne.vue'
import InvoiceTwo from './pages/invoice/InvoiceTwo.vue'
import InvoiceSuccess from './pages/invoice/InvoiceSuccess.vue'
import InvoiceIndex from './pages/invoice/InvoiceIndex.vue'
import BusinessCardBindingWechatOperationInstructions from './pages/BusinessCardBindingWechatOperationInstructions.vue'

const routes = [
	{
	  path: '/',
	  name: 'index',
	  component: Index,
		redirect: 'choose',
		children:[
      {
        path: '/choose',
        name: 'choose',
        component: Choose,
        meta: {
          hideHeaderRight: true
        }
      },
			{
				path: '/booklist',
				name: 'booklist',
				component: BookList
			},
			{
				path: '/process',
				name: 'process',
				component: Process
			},
			{
				path: '/detail',
				name: 'detail',
				component: Detail
			},
			{
				path: '/shopcar',
				name: 'shopcar',
				component: ShopCar
			},
			{
				path: '/confirm',
				name: 'confirm',
				component: Confirm
			},
			{
				path: '/orders',
				name: 'orders',
				component: Orders
			},
			{
				path: '/invoiceProcess',
				name: 'invoiceProcess',
				component: InvoiceProcess
			},
			{
				path: '/invoiceOne',
				name: 'invoiceOne',
				component: InvoiceOne
			},
			{
				path: '/invoiceTwo',
				name: 'invoiceTwo',
				component: InvoiceTwo
			},
			{
				path: '/invoiceSuccess',
				name: 'invoiceSuccess',
				component: InvoiceSuccess
			},
			{
				path: '/invoiceIndex',
				name: 'invoiceIndex',
				component: InvoiceIndex
			},
			{
				path: '/businessCardBindingWechatOperationInstructions',
				name: 'businessCardBindingWechatOperationInstructions',
				component: BusinessCardBindingWechatOperationInstructions
			},
		]
	},
  {
    path: '/place',
    name: 'place',
    component: ()=> import('./pages/Material/Index'),
    redirect: '/place/login',
    children: [
      {
        path: '/place/login',
        name: 'place-login',
        component: ()=> import('./pages/Material/Login'),
        meta: {
          hideHeaderRight: true
        }
      },
      {
        path: '/place/booklist',
        name: 'booklist',
        component: ()=> import('./pages/Material/BookList')
      },
      {
        path: '/place/process',
        name: 'process',
        component: ()=> import('./pages/Material/Process')
      },
      {
        path: '/place/orders',
        name: 'place-orders',
        component: ()=> import('./pages/Material/OrderList'),
      },
      {
        path: '/place/wxpay',
        name: 'place-wxpay',
        component: ()=> import('./pages/Material/WxPay'),
        meta: {
          noHeader: true
        }
      },
      {
        path: '/place/invoiceProcess',
        name: 'invoiceProcess',
        component: ()=> import('./pages/Material/invoice/InvoiceProcess')
      },
      {
        path: '/place/invoiceOne',
        name: 'invoiceOne',
        component: ()=> import('./pages/Material/invoice/InvoiceOne')
      },
      {
        path: '/place/invoiceTwo',
        name: 'invoiceTwo',
        component: ()=> import('./pages/Material/invoice/InvoiceTwo')
      },
      {
        path: '/place/invoiceSuccess',
        name: 'invoiceSuccess',
        component: ()=> import('./pages/Material/invoice/InvoiceSuccess')
      },
      {
        path: '/place/invoiceIndex',
        name: 'invoiceIndex',
        component: ()=> import('./pages/Material/invoice/InvoiceIndex')
      },
      {
        path: '/place/businessCardBindingWechatOperationInstructions',
        name: 'businessCardBindingWechatOperationInstructions',
        component: ()=> import('./pages/Material/BusinessCardBindingWechatOperationInstructions')
      },
    ]
  },
	{
		path: '/wxPay',
		name: 'wxPay',
		component: WxPay
	},
	{
		path: '/print',
		name: 'print',
		component: Print,
		meta: {
			title: '打印订单'
		}
	},
	{
		path: '/error',
		name: 'error',
		component: Error
	}
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
	base: process.env.BASE_URL,
  routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
})


router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : '山东教育社报刊征订';
  // 手机 微信环境
  if ( to.path === '/place/login' && util.isWX() ) {
    window.location.href = 'http://wxzf.sdjys.org/wechat/openid?secret_key=StqVTlQQsF8F&uri=combine_material';
    return
  }
  // 地方教材登录 并且不是电脑端 非微信环境 则跳转到 对应手机页面
  if ( to.path === '/place/login' && to.query.showWindow != 3 && !util.IsPC() && !util.isWX() ){
    window.location.href = '/phone/place-login';
    return
  }
  // 手机端跳转到对应 页面
	if (to.path === '/member.php') {
		window.location.href = 'https://shop.sdjys.org/charge'
	} else if (to.name == null) {
		next('/')
	} else {
		next()
	}
})

export default router
