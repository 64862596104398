<template>
  <div class="detail-container">
    <div class="banner"><img :src="bannerImg" alt="" /></div>
    <div class="detail-box content clear">
      <div class="detail-img fl">
        <img :src="item.picture" alt="" />
      </div>
      <div class="fr">
        <div class="detail-title clear">
          <p class="fl">
            {{ item.title }}<span>{{ item.type }}</span>
          </p>
          <span class="fr" @click="returnList">返回列表</span>
        </div>
        <div class="detail-content" v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import exImg from '@/assets/images/1.png'
import bannerImg from '@/assets/images/banner.jpg'
let that
export default {
  name: 'detail',
  data: function () {
    return {
      item: {},
      type: '',
      bannerImg: bannerImg,
    }
  },
  created() {
    that = this
  },
  mounted() {
    this.type = this.$route.query.type
    this.requestDetail()
  },
  methods: {
    // 点击返回列表
    returnList: function () {
      let that = this
      this.$router.push({ path: '/booklist', query: { navId: that.type } })
    },
    // 请求详情数据
    requestDetail: function () {
      that
        .$http({
          url: 'book/detail',
          method: 'GET',
          params: {
            book_no: that.$route.query.id,
            is_cart_count: false,
            city_id: sessionStorage['city'],
            xz_type: sessionStorage['xzType'],
          },
        })
        .then((res) => {
          that.item = res.result.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
</script>

<style>
.detail-box {
  padding-top: 45px;
  min-height: 500px;
}
.detail-box .detail-img {
  width: 220px;
  position: relative;
  min-height: 320px;
}
.detail-box .detail-img img {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  min-width: 85%;
  min-height: 85%;
  overflow: hidden;
}
.detail-box > .fr {
  width: 740px;
}
.detail-title {
  width: 100%;
}
.detail-title p {
  font-size: 25px;
  color: rgb(51, 51, 51);
  width: calc(100% - 120px);
  display: inline-block;
}
.detail-title span {
  font-size: 18px;
}
.detail-title > span {
  color: #3b77d1;
  cursor: pointer;
  line-height: 1.2;
}
.detail-title > span:hover {
  text-decoration: underline;
}
.detail-content {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eeeeee;
}
</style>
