<template>
  <!-- 主页 -->
  <div class="container">
    <!-- 公共头部 -->
    <div class="header clear">
      <div class="header-logo fl">
        <img :src="logoImg" alt="" @click="toIndex" />
        <span v-show="cityName">{{ cityName }}{{ systemName }}</span>
      </div>
      <div class="header-right fr" v-if="!$route.meta.hideHeaderRight">
        <div
          :class="{
            hidden: $route.meta.hideHeaderRight || $store.state.isMaterialOrder,
          }"
        >
          <div class="header-navbar fl clear">
            <span
              v-for="item in navList"
              :class="{
                active: item.id == currentId && item.path == currentPath,
              }"
              :key="item.id"
              @click="changeBookList(item)"
              >{{ item.name }}</span
            >
          </div>
          <div class="header-search fl">
            <input
              type="text"
              v-model="searchText"
              @blur="saveSearch"
              placeholder="请输入您要买的刊物"
              @keyup.enter="toSearch"
              v-support
            />
            <i class="iconfont iconsousuo" @click="toSearch"></i>
          </div>
        </div>
        <div class="header-user fl">
          <div
            :class="[
              'header-car',
              {
                hidden:
                  $route.meta.hideHeaderRight || $store.state.isMaterialOrder,
              },
            ]"
            @click="toShopCar"
          >
            <i class="iconfont icongouwuche"></i><span>{{ carMount }}</span>
          </div>
          <div
            class="header-avatar"
            v-if="$store.state.isMaterialOrder || ifLogin"
          >
            <i class="iconfont icongerenzhongxin lingred"></i>
            <span>{{ $utils.hidePhoneNumber($store.state.user || user) }}</span>
            <div class="header-fun">
              <p class="header-arr"></p>
              <div>
                <span @click="toOrders">查看订单</span>
                <span @click="toLogout">退出登录</span>
              </div>
            </div>
          </div>
          <div class="header-login clear" v-else>
            <span class="lingred am" @click="toLog(1)">登录</span>
            <div class="border-box" @click="toLog(2)">
              <span class="am">注册</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录注册弹框 -->
    <logBox
      ref="logBox"
      @login="login"
      :showWindow.sync="showWindow"
      v-if="!$route.meta.hideHeaderRight"
    ></logBox>
    <!-- 顶部背景 -->
    <!-- <div class="banner" v-if="showBanner"><img :src="bannerImg" alt="" /></div> -->
    <!-- 主题内容 -->
    <div class="mainer">
      <!-- <keep-alive> -->
      <router-view
        :type="currentId"
        :search="searchText"
        @init="childInit"
        @login="toLog(1)"
      ></router-view>
      <!-- </keep-alive> -->
    </div>
    <!-- 公共底部 -->
    <div class="footer">
      <img
        v-if="!$route.meta.hideHeaderRight && !$store.state.isMaterialOrder"
        :src="changeImg"
        alt=""
        class="change-btn"
        @click="toChangePage"
      />
      <div class="returntop am" :class="{ on: gotop }" @click="goTop">
        <span>返回顶部</span>
        <p><img :src="returnImg" alt="" /><em>TOP</em></p>
      </div>
      <div
        class="open_ewm"
        @click="ifCode = false"
        v-if="ifCode && $route.name != 'choose'"
      >
        <div class="clear">
          微信公众号<img class="close_ewm" :src="shouImg" />
        </div>
        <img :src="ewmImg" />
      </div>
      <div
        class="gzh_ewm"
        @click="ifCode = true"
        v-if="!ifCode && $route.name != 'choose'"
      >
        <p class="opengzh"><img :src="zhanImg" />点击公众号扫码</p>
      </div>
      <div class="foot-con clear">
        <div class="copy fl">
          <p>
            Copyright&copy;2016-2024 山东教育社 All Rights Reserved.&nbsp;<a
              target="_blank"
              href="http://beian.miit.gov.cn"
              >鲁ICP备16007069号-1</a
            >
          </p>
          <p>
            本站所有未注明出处的文字、图片等资料版权均为本站所有，未经许可严禁转载!<a
              target="_blank"
              class="footer-author"
              href="https://www.biaofun.com/"
              >技术支持：Biaofun标梵互动</a
            >
          </p>
        </div>
        <div class="pic fr">
          <img :src="footerImg" />
        </div>
      </div>
    </div>
    <!-- 温馨提示 -->
    <transition name="fade">
      <!-- 必须登录状态，未读，不在城市选择页面才展示 -->
      <Tip
        v-if="ifShow && !$route.meta.hideHeaderRight"
        @close="closeTip"
      ></Tip>
    </transition>
  </div>
</template>

<script>
// 引入本地图片
import logoImg from '@/assets/images/logo.png'
import bannerImg from '@/assets/images/banner.jpg'
import footerImg from '@/assets/images/footer.png'
import returnImg from '@/assets/images/top.png'
import zhanImg from '@/assets/images/zhan.png'
import shouImg from '@/assets/images/shou.png'
import ewmImg from '@/assets/images/ewm.jpg'
// 引入组件
import logBox from '../components/LogWindow.vue'
import Tip from '@/components/Tips.vue'
let that
export default {
  name: 'index',
  components: {
    logBox,
    Tip,
  },
  data: function () {
    return {
      logoImg: logoImg, // logo图片
      bannerImg: bannerImg, // banner图片
      footerImg: footerImg, // footer图片
      returnImg: returnImg, // return图片
      zhanImg: zhanImg, // 展开图片
      shouImg: shouImg, // 收回图片
      ewmImg: ewmImg, // 二维码图片
      changeImg: require('../assets/images/change.png'), // 更换学制和城市
      currentPath: '/booklist', // 当前路由
      currentId: '', // 当前类型(页)
      searchText: sessionStorage['searchText'] || '', // 搜索内容
      ifLogin: sessionStorage['ifLogin'] || false, // 是否登录
      user: sessionStorage['user'] || '订阅用户', // 用户手机号
      ifCode: true, // 是否展示二维码
      ifShow: false, // 是否弹出已阅读
      carMount: sessionStorage['cartMount'] || 0, // 购物车数量
      gotop: false, // 是否展示返回顶部按钮
      showWindow: 0, // 展示登录弹框
      flag: false, // 开关
      navList: [
        { id: '1', path: '/process', name: '订阅流程' },
        { id: '2', path: '/booklist', name: '小学目录' },
        { id: '3', path: '/booklist', name: '中学目录' },
        { id: '4', path: '/booklist', name: '学校教师' },
      ],
      cityName: sessionStorage['cityName'] || '',
      systemName: sessionStorage['xzType'] == 1 ? '五四制' : '六三制',
    }
  },
  created() {
    that = this
  },
  mounted() {
    // 监听页面滚动
    window.addEventListener(
      'scroll',
      this.throttle(this.handleScroll, 500),
      true
    )
    this.currentId = this.$route.query.navId || '2'
    this.ifShow = !!(sessionStorage['ifRead'] == 0 && sessionStorage['ifLogin'])
  },
  updated() {
    // 控制当前高亮导航
    this.currentPath = this.$route.path
    if (this.$route.path == '/process') {
      this.currentId = '1'
    } else {
      this.currentId = this.$route.query.navId
    }
    // 设置进哪个页都弹出一封信
    this.ifShow = !!(sessionStorage['ifRead'] == 0 && sessionStorage['ifLogin'])
  },
  watch: {
    $route: (to, from) => {
      if (from.name == 'choose') {
        that.cityName = sessionStorage['cityName'] || ''
        that.systemName = sessionStorage['xzType'] == 1 ? '五四制' : '六三制'
      }
      if (to.name != 'booklist' && to.name != 'process') {
        sessionStorage.setItem('currentId', '0')
      }
      if (to.name == 'choose') {
        that.cityName = ''
        that.systemName = ''
        that.toLogout()
        sessionStorage.removeItem('cityName')
      }
      if (to.name == 'choose' || to.name.indexOf('invoice') >= 0) {
        that.showBanner = false
      } else {
        that.showBanner = true
      }
    },
  },
  methods: {
    // 点击搜索
    toSearch: function (e) {
      if (!this.searchText) {
        this.$message.error('请输入搜索内容')
        return false
      }
      sessionStorage['searchText'] = this.searchText
      this.$router.push({ path: '/booklist', query: { navId: '5' } })
    },
    // 点击切换导航
    changeBookList: function (e) {
      this.currentId = e.id
      if (e.path == '/booklist') {
        this.$router.push({ path: e.path, query: { navId: e.id } })
      } else {
        this.$router.push(e.path)
      }
    },
    // 搜索框失焦
    saveSearch: function (e) {
      sessionStorage['searchText'] = e.target.value
    },
    // 点击logo
    toIndex: function () {
      const { $router, $route, $store } = this
      if ($route.meta.hideHeaderRight || $store.state.isMaterialOrder)
        return null
      $router.push('/')
    },
    // 关闭温馨提示
    closeTip: function () {
      this.$http({
        url: 'passport/read_letter',
        method: 'POST',
      })
        .then((res) => {
          that.ifShow = false
          sessionStorage['ifRead'] = true
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 监听页面滚动
    handleScroll: function () {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
    },
    // 滚动监听节流
    throttle: function (fn, time) {
      that.flag = true
      return function () {
        if (!that.flag) {
          return false
        }
        that.flag = false
        setTimeout(() => {
          fn()
          that.flag = true
        }, time)
      }
    },
    // 点击返回顶部
    goTop: function () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      let timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            150
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 20)
    },
    // 点击登录
    toLog: function (e) {
      this.showWindow = e
    },
    // 点击关闭弹框
    closeBox: function (e) {
      this.showWindow = e || 0
    },
    // 子组件登录成功
    login: function () {
      this.ifLogin = true
      this.carMount = sessionStorage['cartMount'] || 0
      this.user = sessionStorage['user']
      this.ifShow = !sessionStorage['ifRead']
      this.$router.go(0)
    },
    // 子组件清除数值
    childInit: function () {
      // this.searchText = '';
      this.carMount = sessionStorage['cartMount'] || 0
    },
    // 点击购物车
    toShopCar: function () {
      if (sessionStorage['ifLogin']) {
        this.$router.push('/shopcar')
      } else {
        this.toLog(1)
      }
    },
    // 点击更换城市和学制
    toChangePage: function () {
      sessionStorage.removeItem('showTip')
      sessionStorage.removeItem('addressMsg')
      sessionStorage.removeItem('addressData')
      sessionStorage.removeItem('gradeId')
      sessionStorage.removeItem('selectGrade')
      this.searchText = ''
      this.$router.replace('/choose')
    },
    // 点击查看订单
    toOrders: function () {
      const link = this.$store.state.isMaterialOrder
        ? '/material-order'
        : '/orders'
      this.$router.push(link)
    },
    // 点击退出登录
    toLogout: function () {
      that
        .$http({
          url: 'logout',
          method: 'GET',
        })
        .then((res) => {
          that.ifLogin = false
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('ifLogin')
          sessionStorage.removeItem('cartMount')
          sessionStorage.removeItem('gradeId')
          sessionStorage.removeItem('selectGrade')
          this.carMount = sessionStorage['cartMount'] || 0
          sessionStorage.clear()
          // this.$router.go(0);			// 需要登录查看的页返回导航,不需要登录的页刷新页面
          const link = this.$store.state.isMaterialOrder
            ? '/material-login'
            : '/choose'
          this.$router.replace(link)
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
</script>

<style>
.hidden {
  opacity: 0;
  visibility: hidden;
}
/* 返回顶部 */
.returntop {
  cursor: pointer;
  border: 1px solid #eee;
  opacity: 0;
  position: fixed;
  display: none;
  right: 4px;
  top: 480px;
  width: 55px;
  height: 55px;
  box-shadow: 0px 6px 12px 0px rgba(73, 73, 73, 0.12);
  border-radius: 6px;
  z-index: 40;
  text-align: center;
  overflow: hidden;
}
.returntop.on {
  display: block;
  opacity: 1;
}
.returntop:hover {
  width: 160px;
}
.returntop p {
  width: 55px;
  position: absolute;
  right: 0;
  background: #ffffff;
}
.returntop span {
  position: absolute;
  right: 45px;
  font-size: 18px;
  color: #333333;
  width: 112px;
  line-height: 55px;
  text-align: center;
}
.returntop p img {
  width: 20px;
  margin: 8px auto 0;
}
.returntop p em {
  width: 100%;
  display: inline-block;
  line-height: 1;
  font-size: 14px;
  color: #666666;
}
/* 侧边二维码 */
.open_ewm {
  position: fixed;
  left: 4px;
  top: 650px;
  background: #fff;
  width: 130px;
  text-align: center;
  z-index: 40;
}
.gzh_ewm {
  position: fixed;
  left: 0;
  top: 650px;
  width: 25px;
  text-align: center;
  background: #3b77d1;
  color: #fff;
  cursor: pointer;
  z-index: 40;
}
.gzh_ewm p.opengzh img {
  padding: 0 3px 0 3px;
}
.gzh_ewm p.opengzh {
  color: #fff;
  line-height: 1.5;
  padding: 5px 0;
}
.close_ewm {
  display: inline-block;
  float: right;
  padding: 5px;
  cursor: pointer;
  width: 24px;
}
</style>
