<template>
	<div class="c-tips">
		<div class="ct-container">
			<h3 class="title"></h3>
			<div class="ct-content">
				<div v-html="tips"></div>
				<div class="selectBox">
					<el-checkbox v-model="selected">知悉并同意</el-checkbox>
				</div>
				<button class="confirm" @click="close">确定</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				// tips: `
				// 	<p>尊敬的学生家长：</p>
				// 	<p style="text-indent:2em">现在是预征订期，您所订阅的期刊将于2023年秋季开学后发放。</p>
				// 	<p style="text-indent:2em">《中共中央国务院关于进一步加强和改进未成年人思想道德建设的若干意见》强调指出，要充分考虑未成年人成长进步的需求，精心策划选题，创作、编辑、出版并积极推荐一批知识性、趣味性、科学性强的图书、报刊······，丰富广大未成年人的精神文化生活。<p>
				// 	<p style="text-indent:2em">为了适应我省未成年人的需要，经省教育厅同意，由山东教育社向广大中小学和家长推荐部分国家和省优秀报刊，供学校、学生和家长自愿选择订阅。</p>`,
				tips:``,//文本内容
				selected:false
			}
		},
		mounted() {
			//注册时的一封信
			this.configRegister()
		},
		methods:{
			//关闭
			close(){
				if (this.selected) {
					this.$emit('close');													 // 弹一次
					sessionStorage.setItem('showTip', false);
				} else {
					this.$message.error('请勾选知悉并同意')
				}
			},

			// 获取注册时一封信文字内容
			configRegister:function(){
				this.$http({
					url: 'web_config/register_letter',
					method: 'GET',
				})
				.then((res) => {
					// console.log(`output->res`,res.result)
					this.tips = res.result
				})
				.catch((err) => {
					console.error(`output->err`,err)
				})
			},
		}
	}
</script>

<style>
	.c-tips {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .6);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
	}

	.c-tips .ct-container {
		width: 666px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 30px;
		overflow: hidden;
	}

	.c-tips .title {
		height: 93px;
		text-align: center;
		line-height: 93px;
		background: url(../assets/images/tips-header.jpg) no-repeat center center;
		background-size: 100% 100%;
		font-size: 28px;
		color: #fff;
	}
	.c-tips .ct-content {
		padding: 35px 40px;
		background-color: #fff;

	}
	.c-tips .ct-content p{
		font-size: 16px;
		line-height: 28px;
		margin-top: 10px;
	}
	.c-tips .ct-content p:first-child,.c-tips .ct-content p.phone{
		margin-top: 0;
	}
	.c-tips .ct-content p.phone{
		font-weight: bold;
	}
	.c-tips .confirm{
		display: block;
		width: 219px;
		height: 48px;
		background: url('../assets/images/button.png');
		background-size: cover;
		border-radius: 24px;
		font-size: 16px;
		color: #fff;
		border: none;
		outline: none;
		box-shadow: 0 17px 33px 0 rgba(0,0,0,.11);
		margin: 40px auto 15px;
		cursor: pointer;
		position: static;
	}
	.selectBox{
		height: 40px;
		position: relative;
	}
	.el-checkbox{
		position: absolute;
		top: 15px;
		left: 0;
	}
	.el-checkbox .el-checkbox__label{
		font-size: 16px;
	}
</style>
