<template>
    <div class="orders" :style="{ minHeight: $store.state.clientHeight }">
        <!-- <div class="banner"><img :src="bannerImg" alt="" /></div> -->
        <div class="page-box">
            <!-- ######################################################################################################################## -->
            <!-- 页面标题 开始 -->
            <div class="page-title clear">
                <div class="page-icon fl">
                    <i class="iconfont icondingdan"></i>
                    <span>查看订单</span>
                </div>
            </div>
            <!-- 页面标题 结束 -->
            <!-- ######################################################################################################################## -->

            <!-- ######################################################################################################################## -->
            <!-- 订单分类 开始 -->
            <div class="order-cate clear">
                <p class="line"></p>
                <span
                    :class="[{ active: item.id == curType }]"
                    v-for="item in cateList"
                    :key="item.id"
                    @click="selectType(item.id)"
                    >{{ item.text }}</span
                >
            </div>
            <!-- 订单分类 结束 -->
            <!-- ######################################################################################################################## -->

            <!-- ######################################################################################################################## -->
            <!-- 订单列表 开始 -->
            <div class="order-list">
                <!--
        <div class="list-container" v-for='item in getCurOrders' :key='item.id'>
          &lt;!&ndash; 列标题 &ndash;&gt;
          <div class="list-header clear">
             <span class="w15 theader">城市</span>
             <span class="w25 theader">批次</span>
             <span class="w40 theader">缴费单位</span>
             <span class="w15 theader">总价</span>
           </div>

          <div class='list-data'>
            <div class='list-mainer'>

              <div class="list-item clear">
                <div class="item-text w15">
                  <span>{{ cityName }}</span>
                </div>
                <div class="item-text w25">
                  <span>{{ item.batch_name }}</span>
                </div>
                <div class="item-text w40">
                  <span>{{ $store.state.user }}</span>
                </div>
                <div class="item-text w15">
                  <span>{{ item.total_price }}</span>
                </div>
              </div>

              &lt;!&ndash; 底部状态 &ndash;&gt;
              <div class="order-state clear">
                &lt;!&ndash; 订单信息 &ndash;&gt;
                <p class="fl">
                  订单号：{{ item.order_no }}&nbsp;&nbsp;&nbsp;
                  订单状态：<span>{{ item.status == 10 ? '未支付' : (item.status == 20 ? '已支付' : (item.status == 40 ? '退款中' : (item.status == 41 ? '退款成功': '其他'))) }}</span>
                </p>
                &lt;!&ndash; 已支付订单显示的操作按钮 &ndash;&gt;
                <div class="fr invoice-box" v-if="curType == 2 && item.status != 40 && item.status != 41 && item.status != 10">
                  <span @click="checkInvoice(item)" v-if="item.status == 10 || item.status == 20">查看电子发票</span>
                  <span @click="applyInvoice(item)" v-if="item.status == 10 || item.status == 20">申请电子发票</span>
                  <span @click="toRefund(item.order_no)" v-if="item.status == 20 && item.is_offline == 0 && item.pay_type !== 3">申请退款</span>
                </div>
                &lt;!&ndash; 未支付订单显示的操作按钮 &ndash;&gt;
                <div class="fr btn-box" v-if="curType == 1">
                  <span class="closeOrder" @click="checkInvoice(item)" v-if="item.pay_mode == 2">查看电子发票</span>
                  <span class="lingred" @click="payOrder(item.order_no, item.pay_type, item.pay_mode, item.invoice_over_status)">支付订单</span>
                </div>
                &lt;!&ndash; 历史订单显示的操作按钮 &ndash;&gt;
                <div class="fr invoice-box" v-if="curType == 3 && item.status != 40 && item.status != 41 && item.status != 10">
                  <span @click="checkInvoice(item)" v-if="item.status == 10 || item.status == 20">查看电子发票</span>
                  <span @click="applyInvoice(item)" v-if="item.status == 10 || item.status == 20">申请电子发票</span>
&lt;!&ndash;                  <span @click="toPrint(item)">支付订单打印</span>&ndash;&gt;
                </div>
              </div>

            </div>
          </div>
        </div>
-->

                <div class="list-container" v-for="(item, index) in getCurOrders" :key="index">
                    <!-- 列标题 -->
                    <div class="list-header clear">
                        <div class="list-header clear">
                            <span class="w20 theader">刊物名称</span>
                            <span class="w40 theader">总数</span>
                            <span style="opacity: 0" class="w25 theader">均价</span>
                            <span class="w15 theader">总价</span>
                        </div>
                    </div>

                    <!-- 列数据 -->
                    <div class="list-data" v-if="item.books">
                        <div class="list-mainer">
                            <!-- 书籍列表 -->
                            <div v-for="(book, index) in item.books" :key="index" class="list-item clear">
                                <div class="item-text w20">
                                    <span>{{ book.book_name }}</span>
                                </div>
                                <div class="item-text w40">
                                    <span>{{ book.total_count }}</span>
                                </div>
                                <div style="opacity: 0" class="item-text w25">
                                    <span>{{ book.avg_price }}元</span>
                                </div>
                                <div class="item-text w15">
                                    <span>{{ book.total_price }}元</span>
                                </div>
                            </div>

                            <!-- 收货信息 -->
                            <div class="order-detail clear">
                                <div class="fl">
                                    <div class="detail-address">
                                        <span>批次：{{ item.batch_name }}</span>
                                        <span>城市：{{ cityName }}</span>
                                        <span>缴费单位：{{ $store.state.user }}</span>
                                    </div>
                                </div>
                                <div class="fr">
                                    <div>
                                        <p class="clear">
                                            <span class="fl">订阅刊物费：</span
                                            ><span class="fr">{{ parseFloat(item.total_price).toFixed(2) }}元</span>
                                        </p>
                                        <p class="clear">
                                            <span class="fl">运费：</span
                                            ><span class="fr">{{ freight.toFixed(2) }}元</span>
                                        </p>
                                        <div class="clear">
                                            <span class="fl">合计总额：</span
                                            ><b class="fr"
                                                >{{ (parseFloat(item.total_price) + freight).toFixed(2) }}元</b
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 底部状态 -->
                            <div class="order-state clear">
                                <!-- 订单信息 -->
                                <p class="fl">
                                    订单号：{{ item.order_no }}&nbsp;&nbsp;&nbsp; 支付状态：<span>{{
                                        calcPaymentStatusText(item)
                                    }}</span>
                                    <template v-if="calcShipmentStatusText(item)">
                                        &nbsp;&nbsp;&nbsp; 发货状态：<span>{{ calcShipmentStatusText(item) }}</span>
                                    </template>
                                </p>
                                <!-- 已支付订单显示的操作按钮 -->
                                <div
                                    class="fr invoice-box"
                                    v-if="curType == 2 && item.status != 40 && item.status != 41 && item.status != 10"
                                >
                                    <span @click="checkInvoice(item)" v-if="item.status == 10 || item.status == 20"
                                        >查看电子发票</span
                                    >
                                    <span @click="applyInvoice(item)" v-if="item.status == 10 || item.status == 20"
                                        >申请电子发票</span
                                    >
                                    <span
                                        @click="toRefund(item.order_no)"
                                        v-if="item.status == 20 && item.is_offline == 0 && item.pay_type !== 3"
                                        >申请退款</span
                                    >
                                </div>
                                <!-- 未支付订单显示的操作按钮 -->
                                <div class="fr btn-box" v-if="curType == 1">
                                    <span class="closeOrder" @click="checkInvoice(item)" v-if="item.pay_mode == 2"
                                        >查看电子发票</span
                                    >
                                    <span
                                        class="lingred"
                                        @click="
                                            payOrder(
                                                item.order_no,
                                                item.pay_type,
                                                item.pay_mode,
                                                item.invoice_over_status,
                                            )
                                        "
                                        >支付订单</span
                                    >
                                </div>
                                <!-- 历史订单显示的操作按钮 -->
                                <div
                                    class="fr invoice-box"
                                    v-if="curType == 3 && item.status != 40 && item.status != 41 && item.status != 10"
                                >
                                    <span @click="checkInvoice(item)" v-if="item.status == 10 || item.status == 20"
                                        >查看电子发票</span
                                    >
                                    <span @click="applyInvoice(item)" v-if="item.status == 10 || item.status == 20"
                                        >申请电子发票</span
                                    >
                                    <!--                  <span @click="toPrint(item)">支付订单打印</span>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="nodata" v-if="getCurOrders.length < 1">
                    <noData noDataText="暂无订单"></noData>
                </div>
                <!-- 订单列表 结束 -->
                <!-- ######################################################################################################################## -->
            </div>

            <!-- 添加地址弹框 -->
            <transition name="fade">
                <AddressBox
                    key="address"
                    v-if="showAdress"
                    :showBox="showAdress"
                    :type="3"
                    :orderNo="curOrder"
                    @closeBox="closeAdress"
                    @addAdress="addAdress"
                ></AddressBox>
                <CloseOrder
                    key="close"
                    v-if="showNoWant"
                    :orderNo="curOrder"
                    @closeWant="closeWant"
                    @confirmNoWant="confirmNoWant"
                ></CloseOrder>
            </transition>

            <!-- 支付弹框 -->
            <div class="bank-container" v-if="showPaySelect">
                <div class="pay-select">
                    <i class="window-close iconfont iconclose" @click="showPaySelect = false"></i>
                    <span>请选择您的支付方式</span>
                    <div class="btn-box" v-if="isPublicPay || showPayType == 1">
                        <div class="bank-btn lingred" @click="wxPay()">微信支付</div>
                        <div v-if="cityName.indexOf('济南') < 0" class="bank-btn lingred" @click="officialCardPay()">
                            公务卡支付
                        </div>
                    </div>
                    <div class="btn-box" v-else>
                        <div class="bank-btn lingred" @click="bankPay(1)">扫码支付</div>
                        <!--            <div class="bank-btn lingred" @click="bankPay(2)">银联支付</div>-->
                        <div v-if="cityName.indexOf('济南') < 0" class="bank-btn lingred" @click="officialCardPay()">
                            公务卡支付
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bookImg from "@/assets/images/1.png";
    import AddressBox from "@/components/AddressBox.vue";
    import CloseOrder from "@/components/CloseOrder.vue";
    import noData from "@/components/noData.vue";
    let that;
    export default {
        name: "material-order",
        components: {
            AddressBox,
            CloseOrder,
            noData,
        },
        data: () => {
            return {
                allSelect: false, // 选择全部
                // 订单分类tabs
                cateList: [
                    { id: "1", text: "未支付订单", list: [] },
                    { id: "2", text: "已支付订单", list: [] },
                    { id: "3", text: "历史订单", list: [] },
                ],
                curType: "2", // 当前订单类型。1: 已支付订单; 2: 未支付订单; 3: 历史订单
                pageIndex: 1, // 当前页数
                pageSize: 10, // 每页数据条数
                total: 0, // 数据总条数
                listData: [], // 数据列表
                freight: 0, // 运费
                address: {}, // 地址列表
                showAdress: false, // 展示添加地址弹框
                showNoWant: false, // 展示不想要了弹框
                curOrder: "", // 当前订单
                noData: false, // 暂无订单
                bannerImg: require("@/assets/images/banner.jpg"), // banner图片
                showPaySelect: false, // 展示支付选择弹框
                orderId: "", // 支付订单ID
                showPayType: "", // 支付弹框中的支付类型
                isPublicPay: false, // 支付弹框中是否是公务卡支付
                isOpenCard: false, // 公务卡 是否 开完票
                cityName: sessionStorage["city"], // 城市名
                company: sessionStorage["company"], // 单位名称
            };
        },
        computed: {
            getCurOrders() {
                const { cateList, curType } = this;
                return cateList.find((item) => item.id === curType)
                    ? cateList.find((item) => item.id === curType).list
                    : [];
            },
        },
        created() {
            that = this;
            this.curType = sessionStorage["orderType"] || "1";
            // 查询订单列表
            this.queryOrders();
            this.$store.commit("updateH", 130);
        },
        destroyed() {
            sessionStorage["orderType"] = "";
        },
        methods: {
            /**
             * ####################################################################################################
             * ## 切换订单分类
             * ## id: 分类id
             * ####################################################################################################
             */
            selectType: function (id) {
                this.curType = id;
                this.$route.query.type = id || "1";

                this.queryOrders(id);
                sessionStorage["orderType"] = id;
                this.total = 0;
                this.pageIndex = 1;
            },

            /**
             * ####################################################################################################
             * ## 切换页数
             * ## pageIndex: 切换的页数
             * ####################################################################################################
             */
            changePageIndex: function (pageIndex) {
                this.pageIndex = pageIndex;
                this.queryOrders();
            },

            /**
             * ####################################################################################################
             * ## 格式化时间戳
             * ## timestamp: 时间戳，PHP格式的，表示的是秒数，不是js的毫秒数
             * ####################################################################################################
             */
            formatDate: function (timestamp) {
                let dt = new Date(timestamp * 1000);
                let year = dt.getFullYear();
                let month = dt.getMonth() + 1 < 10 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1;
                let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
                let hour = dt.getHours();
                let minute = dt.getMinutes();
                let second = dt.getSeconds();
                return `${year}-${month}-${day}`;
            },

            // 点击更换书籍
            changeBook: function (book_no, order_no) {
                this.$router.push({
                    path: "/booklist",
                    query: {
                        order_no: order_no,
                        book_no: book_no,
                        type: that.curType,
                        navId: "6",
                    },
                });
            },

            // 关闭填写地址
            closeAdress: function () {
                this.showAdress = false;
            },

            // 添加地址
            addAdress: function () {
                this.showAdress = false;
                this.pageIndex = 1;
                this.queryOrders();
            },

            // 去打印
            toPrint: function (order) {
                let time = this.$utils.timeChange(order.create_time * 1000);
                let printObj = Object.assign({}, order);
                printObj.create_time = time;
                sessionStorage["orderPrintData"] = JSON.stringify(printObj);
                let routeData = this.$router.resolve({
                    path: "/print",
                });
                window.open(routeData.href, "_blank");
            },

            // 点击退款
            toRefund: function (e) {
                this.curOrder = e;
                this.showNoWant = true;
            },

            // 关闭不想要了弹框
            closeWant: function () {
                this.showNoWant = false;
            },

            // 点击退款订单原因
            confirmNoWant: function (e) {
                // 只有已支付订单可以退款
                this.showNoWant = false;
                this.total = 0;
                this.listData = [];
                this.pageIndex = 1;
                this.queryOrders("1");
            },

            // 点击修改地址
            editAddress: function (e) {
                this.showAdress = true;
                this.curOrder = e;
            },

            // 取消订单
            cancelOrder: function (e, i) {
                this.$http({
                    url: "order/cancel_order",
                    data: {
                        order_no: e,
                    },
                })
                    .then((res) => {
                        // console.log(res);
                        that.$message({
                            message: res.msg,
                            type: "success",
                            duration: 1000,
                        });
                        that.listData.splice(i, 1);
                        that.noData = that.listData.length < 1;
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },

            // 点击支付订单
            payOrder: function (e, pay_type, pay_mode, invoice_over_status) {
                this.showPayType = pay_type;
                this.orderId = e;
                // pay_mode === 1 正常訂單; 2 公務卡訂單
                if (pay_mode === 1) {
                    this.showPaySelect = true;
                } else {
                    //pay_type： 1 微信支付； 2：建行支付  3: 後台收款
                    // 公务卡 未开完 票
                    if (pay_mode === 2 && invoice_over_status === 0) {
                        this.showPaySelect = true;
                        this.isOpenCard = true;
                        return;
                    }
                    // 公务卡 开完 票
                    if (pay_mode === 2 && invoice_over_status === 1) {
                        this.isOpenCard = false;
                        // 微信支付
                        this.wxPay();
                        return;
                    }

                    //pay_type： 1 微信支付； 2：建行支付  3: 後台收款
                    if (pay_type === 1) {
                        // 微信支付
                        this.wxPay();
                    } else {
                        this.showPaySelect = true;
                    }
                }
            },

            /**
             * ****************************************************************************************************
             * ** 微信支付
             * ****************************************************************************************************
             */
            wxPay(order_no, pay_type, pay_mode) {
                that.$http({
                    isMaterial: true,
                    url: "order/pay_again",
                    showLoading: true,
                    data: {
                        order_no: this.orderId,
                        source: 3,
                    },
                })
                    .then(({ result }) => {
                        let payObj = {
                            code: result.code_url,
                            orderId: result.order_no,
                            price: result.total_price,
                        };
                        sessionStorage["payCode"] = JSON.stringify(payObj);
                        this.$router.push({ path: "/place/wxpay" });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },

            /**
             * ****************************************************************************************************
             * ** 公务卡支付
             * ****************************************************************************************************
             */
            officialCardPay() {
                const { $router, isOpenCard } = this;
                // console.log('isOpenCard=======>', isOpenCard)
                if (!isOpenCard) {
                    $router.push({
                        path: "/place/businessCardBindingWechatOperationInstructions",
                        query: { order_no: this.orderId },
                    });
                    return;
                }

                this.wxPay();

                return;
                this.$http({
                    url: "order/official",
                    showLoading: true,
                    data: {
                        order_no: this.orderId,
                    },
                })
                    .then((res) => {
                        // console.log('officialCardPay success');
                        this.$router.push({
                            path: "/businessCardBindingWechatOperationInstructions",
                            query: { order_no: this.orderId },
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },

            // 建行支付
            bankPay: function (e) {
                that.$http({
                    url: "order/pay_again",
                    showLoading: true,
                    data: {
                        order_no: this.orderId,
                        source: e,
                    },
                })
                    .then((res) => {
                        window.open(res.result.url);
                        this.showPaySelect = false;
                    })
                    .catch((err) => {
                        this.showPaySelect = false;
                        console.error(err);
                    });
            },

            /**
             * ####################################################################################################
             * ## 查看电子发票
             * ## item: 订单数据
             * ####################################################################################################
             */
            checkInvoice: function (item) {
                this.$router.push({
                    path: "/place/invoiceIndex",
                    query: { order_no: item.order_no },
                });
            },

            /**
             * ####################################################################################################
             * ## 申请开票
             * ## item: 订单数据
             * ####################################################################################################
             */
            applyInvoice: function (item) {
                this.$router.push({
                    path: "/place/invoiceProcess",
                    query: { order_no: item.order_no },
                });
            },

            /**
             * ####################################################################################################
             * ## 查询订单列表
             * ####################################################################################################
             */
            queryOrders: function (type) {
                // 根据不同的类型请求不同的接口
                that.$http({
                    url: "order/lists",
                    isMaterial: true,
                    params: {
                        type: type || this.curType,
                    },
                    method: "GET",
                })
                    .then(({ result }) => {
                        if (result.length < 1) {
                            this.cateList = this.cateList.map((item) => ({
                                ...item,
                                list: [],
                            }));
                        } else {
                            this.cateList[0].list = [];
                            this.cateList[1].list = [];
                            this.cateList[2].list = [];
                            // TODO 接口返回全部订单数据 ;
                            result.forEach((item) => {
                                // 未支付
                                if (item.status === 10) this.cateList[0].list.push(item);
                                // 已支付
                                if ([20, 40, 41].indexOf(item.status) > -1) this.cateList[1].list.push(item);
                                // 历史支付
                                if ([10, 20].indexOf(item.status) > -1) this.cateList[2].list.push(item);
                            });
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },

            /**
             * @description: 计算显示的订单支付状态文本内容
             * @param {Object} order 订单数据
             * @return {String} 当前订单的支付状态文本内容
             */
            calcPaymentStatusText(order) {
                // item.status == 10 ? '未支付' : item.status == 20 ? '已支付' : item.status == 40 ? '退款中' : item.status == 41 ? '退款成功' : '其他'
                // 显示定义好的对应的发货状态文本
                if (order.status == 10) return "未支付";
                if (order.status == 20) return "已支付";
                if (order.status == 40) return "退款中";
                if (order.status == 41) return "退款成功";
                return "其他";
            },

            /**
             * @description: 计算显示的订单发货状态文本内容
             * @param {Object} order 订单数据
             * @return {String} 当前订单的发货状态文本内容
             */
            calcShipmentStatusText(order) {
                // 显示当前订单数据中的 batch_notes（自定义的发货状态文本）
                return order.batch_notes;
            },
        },
    };
</script>

<style scoped>
    .list-header {
        display: flex;
    }
    .list-header .checkbox {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        margin-right: 10px;
        cursor: pointer;
    }
    .list-container .list-header .all-check {
        line-height: 1;
        float: none !important;
        display: flex;
        justify-content: center;
    }
    ::v-deep.list-container .list-header .all-check .el-checkbox__label,
    ::v-deep.list-container .list-header .all-check .el-checkbox {
        line-height: 1;
        float: none !important;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .list-container .list-header .theader {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ::v-deep .list-container .el-checkbox__label {
        color: #fff !important;
        top: 0;
    }
    ::v-deep .list-container .list-item .el-checkbox {
        top: 0;
    }
    .list-mainer .list-item .item-text.check {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ::v-deep .list-container .list-item .el-checkbox .el-checkbox__inner {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
    ::v-deep .list-container .list-item .el-checkbox__inner::after {
        left: 40%;
        top: 24%;
    }
    .list-mainer .list-item > div {
        height: 114px;
    }

    .orders {
        padding-top: 90px;
    }
    .nodata {
        padding: 40px 0;
    }
    .list-container {
        border-bottom: 2px solid #eeeeee;
        padding-bottom: 20px;
    }
    .order-cate {
        width: 100%;
        padding-top: 15px;
        margin-bottom: 10px;
        position: relative;
    }
    .order-cate span {
        width: 190px;
        text-align: center;
        position: relative;
        font-size: 18px;
        line-height: 2.5;
        float: left;
        height: 48px;
        background: #ffffff;
        border-bottom: 2px solid #3b77d1;
        cursor: pointer;
    }
    .order-cate span.active {
        border-top: 2px solid #3b77d1;
        border-left: 2px solid #3b77d1;
        border-right: 2px solid #3b77d1;
        border-bottom: 2px solid #ffffff;
    }
    .order-cate .line {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #3b77d1;
        margin: 0;
    }
    .item-change {
        position: relative;
    }
    .item-change span {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -20px;
        margin-left: -50px;
        cursor: pointer;
        display: inline-block;
        width: 100px;
        height: 40px;
        border-radius: 20px;
        background: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        color: #666;
        border: 1px solid rgb(225, 225, 225);
    }
    .item-change span:hover {
        background: #3f7ad2;
        background: linear-gradient(90deg, #3fa6d2, #3f7ad2);
        color: #fff;
    }
    /* 地址信息 */
    .order-detail {
        width: 100%;
        background: rgb(240, 249, 253);
    }
    .order-detail > .fl {
        padding: 30px 40px;
        border-right: 1px solid #dddddd;
        width: 63%;
        position: relative;
    }
    .order-detail > .fl .detail-address {
        width: calc(100% - 240px);
    }
    .order-detail > .fl .detail-address span {
        display: inline-block;
        width: 100%;
        line-height: 2.5;
    }
    .order-detail > .fl .add-address {
        position: absolute;
        width: 135px;
        height: 45px;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(232, 232, 232);
        border-radius: 23px;
        text-align: center;
        line-height: 45px;
        top: 50%;
        right: 6%;
        margin-top: -23px;
        cursor: pointer;
        color: #3b77d1;
    }
    .order-detail > .fl .add-address:hover {
        background: #3f7ad2;
        background: linear-gradient(90deg, #3fa6d2, #3f7ad2);
        color: #ffffff;
    }
    .order-detail > .fr {
        padding: 30px 40px;
        width: 37%;
    }
    .order-detail > .fr > p {
        text-align: right;
        font-size: 0.18rem;
        color: #333;
        margin-bottom: 0.14rem;
        line-height: 2;
    }
    .order-detail > .fr > p span {
        color: #3b77d1;
        font-size: 0.26rem;
        font-weight: bold;
    }
    .order-detail > .fr > div {
        width: 100%;
    }
    .order-detail > .fr > div p span {
        font-size: 0.18rem;
        color: #888;
    }
    .order-detail > .fr > div div {
        margin-top: 0.2rem;
    }
    .order-detail > .fr > div div span {
        color: #333333;
    }
    .order-detail > .fr > div div b {
        font-size: 0.34rem;
        color: #3b77d1;
        font-weight: bold;
    }
    .item-totalPrice span {
        line-height: 160px;
        font-size: 20px;
        font-weight: bold;
        color: rgb(51, 51, 51);
        display: inline-block;
        text-align: center;
        width: 100%;
    }
    @media (max-width: 1100px) {
        .list-item .item-detail .item-msg.order-msg {
            width: 65%;
            margin-left: 4%;
        }
    }
    /* 底部状态 */
    .order-state {
        width: 100%;
        margin-top: 20px;
    }
    .order-state > p {
        color: #333;
        line-height: 20px;
        font-size: 16px;
        line-height: 2;
    }
    .order-state > p span {
        color: #3b77d1;
    }
    .order-state .btn-box {
        display: flex;
    }
    .order-state .btn-box .closeOrder {
        width: 120px;
        height: 36px;
        cursor: pointer;
        border-radius: 18px;
        line-height: 36px;
        font-weight: bold;
        display: inline-block;
        text-align: center;
        color: #999;
        background: #fff;
        border: 1px solid #eee;
        margin-right: 10px;
    }
    .order-state .btn-box .lingred {
        width: 120px;
        height: 36px;
        cursor: pointer;
        line-height: 36px;
        display: inline-block;
        text-align: center;
        border-radius: 30px;
        font-weight: bold;
    }
    .order-state .invoice-box {
        padding-right: 10px;
    }
    .order-state .invoice-box span {
        color: #3a3a3a;
        font-size: 0.18rem;
        font-weight: bold;
        padding-left: 0.2rem;
        position: relative;
        line-height: 2;
    }
    .order-state .invoice-box span:before {
        position: absolute;
        content: "";
        left: 8px;
        top: -5px;
        width: 1px;
        height: 25px;
        background: #ddd;
    }
    .order-state .invoice-box span:first-child::before {
        display: none;
    }
    .order-state .invoice-box span:hover {
        color: #9d5920;
        cursor: pointer;
    }
    .order-state .print {
        color: #3a3a3a;
        font-size: 0.18rem;
        font-weight: bold;
        cursor: pointer;
    }
    .order-state .print:hover {
        color: #9d5920;
    }
    .el-pagination {
        text-align: center;
        margin-top: 30px;
    }
    .orders .list-item .item-detail {
        cursor: default;
    }
    .orders .list-item .item-detail .item-img {
        width: 16%;
        margin-left: 3%;
    }
    .orders .item-allPrice {
        position: relative;
    }
    .orders .item-allPrice .item-price-box {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
    .orders .item-allPrice .item-price-box > span {
        color: #777777;
        font-size: 14px;
        font-weight: 500;
    }
    .orders .item-allPrice .item-price-box > p {
        text-align: center;
    }
    .orders .item-allPrice .item-price-box > p span {
        display: inline-block;
        width: auto;
        font-size: 18px;
    }

    /* 新增建行支付 */
    .bank-container {
        position: fixed;
        z-index: 100;
        background: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .pay-select {
        width: 400px;
        left: 50%;
        margin-left: -200px;
        top: 50%;
        margin-top: -100px;
        background: #ffffff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
        padding: 35px 25px;
        overflow: hidden;
        border-radius: 8px;
        position: absolute;
    }
    .pay-select > span {
        width: 100%;
        display: inline-block;
        text-align: center;
        font-size: 18px;
        color: #333333;
    }
    .pay-select > i {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 22px;
        cursor: pointer;
    }
    .pay-select .btn-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }
    .pay-select .btn-box .bank-btn {
        font-size: 16px;
        margin-right: 10px;
        flex: 1;
        padding: 0 16px;
        border-radius: 20px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn-box .bank-btn:last-of-type {
        margin-right: 0;
    }
</style>
