<template>
  <div class="pay">
    <div class="banner"><img :src="bannerImg" alt="" /></div>
    <div class="page-box">
      <div class="page-title clear">
        <div class="page-icon fl">
          <i class="iconfont icondingdan"></i>
          <span>确认订单信息</span>
        </div>
      </div>
      <!-- 订单列表 -->
      <div class="shop-list">
        <div class="list-container">
          <div class="list-header clear">
            <span class="w35">刊物名称</span>
            <span class="w35">刊物说明</span>
            <span class="w15">数量</span>
            <span class="w15">价格</span>
          </div>
          <div class="list-mainer">
            <div
              class="list-item clear"
              v-for="(item, index) in orderlist"
              :key="index"
            >
              <div class="item-detail w35">
                <div class="item-img"><img :src="item.picture" alt="" /></div>
                <div class="item-msg">
                  <p>{{ item.name }}</p>
                  <span>{{ item.press }}</span>
                  <div>
                    <span
                      class="item-type"
                      v-for="(type, index) in item.book_type"
                      :key="index"
                      >{{ type }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="item-text w35">
                <span>{{ item.apply_explain }}</span>
              </div>
              <div class="item-crease w15">
                <template>
                  <el-input-number
                    v-model="item.count"
                    :min="1"
                    :max="9999"
                    :precision="0"
                    :step="1"
                    label="描述文字"
                  ></el-input-number>
                </template>
              </div>
              <div class="item-allPrice w15">
                <div class="item-price-box">
                  <span>单价：{{ item.unit_price }}元/期</span>
                  <span
                    >[{{ item.frequency_name }}/共{{
                      item.frequency_periods
                    }}期]</span
                  >
                  <p>
                    总价：<span
                      >{{
                        (item.count * item.half_year_price).toFixed(2)
                      }}元</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 订单详情 -->
      <div class="order-detail clear">
        <div class="fl">
          <div class="detail-address">
            <span>姓名：{{ address.nameValue }}</span>
            <span>电话：{{ address.telValue }}</span>
            <span>学校：{{ address.schoolValue }}</span>
            <span
              v-show="
                (address.nameValue && address.gradeValue) || !address.nameValue
              "
              >年级：{{ address.gradeValue }}</span
            >
            <span
              v-show="
                (address.nameValue && address.classValue) || !address.nameValue
              "
              >班级：{{ address.classValue }}</span
            >
          </div>
          <span class="add-address am" @click="showAdress = true">{{
            haveAdress ? '修改地址' : '添加地址'
          }}</span>
        </div>
        <div class="fr">
          <p>
            您已选择购买<span> {{ selectAll }} </span>套刊物
          </p>
          <div>
            <p class="clear">
              <span class="fl">订阅刊物费：</span
              ><span class="fr">￥{{ bookFee.toFixed(2) }}元</span>
            </p>
            <p class="clear">
              <span class="fl">运费：</span
              ><span class="fr">￥{{ postFee.toFixed(2) }}元</span>
            </p>
            <div class="clear">
              <span class="fl">合计总额：</span
              ><b class="fr">￥{{ allFee.toFixed(2) }}元</b>
            </div>
          </div>
        </div>
      </div>
      <div class="confirm-btn am lingred" @click="confirmOrders">确认订单</div>
    </div>
    <!-- 填写地址 -->
    <transition-group name="fade">
      <AddressBox
        key="address"
        v-show="showAdress"
        :showBox="showAdress"
        :type="haveAdress ? '2' : '1'"
        @closeBox="closeAdress"
        @addAdress="addAdress"
      ></AddressBox>
      <CreateOrder
        key="order"
        v-if="showOrder"
        :list="orderlist"
        :bookFee="bookFee"
        :postFee="postFee"
        :allFee="allFee"
        @clickClose="closeOrders"
      ></CreateOrder>
    </transition-group>

    <PromptText v-show="showPro" @conExe="confirmSubmitOrder"></PromptText>
    <PromptToast
      v-show="showUpgradeToast"
      :title="upgradeToast.title"
      :text="upgradeToast.text"
      @conExe="upgradeToastConfirm"
    ></PromptToast>
  </div>
</template>

<script>
import AddressBox from '@/components/AddressBox.vue'
import CreateOrder from '@/components/CreateOrder.vue'
import PromptText from '@/components/PromptText.vue'
import PromptToast from '@/components/PromptToast.vue'
let that
export default {
  name: 'confirm',
  components: {
    AddressBox,
    CreateOrder,
    PromptText, //新增加文字描述
    PromptToast
  },
  data: function () {
    return {
      curName: '小学目录',
      showAdress: false, // 添加地址
      haveAdress: false, // 是否有地址
      showOrder: false, // 展示订单详情+
      showPro: false, // 新增显示文字
      postFee: 0,
      address: {},
      orderlist: [],
      pageIndex: 1, // 当前页
      pageSize: 100, // 每页数量
      bannerImg: require('@/assets/images/banner.jpg'),
      creatSwitch: true, // 正在生成订单
      showUpgradeToast: false,
      upgradeToast: {
        title: '',
        text: ''
      },
    }
  },
  computed: {
    // 选择刊物数量
    selectAll: () => {
      let mount = 0
      that.orderlist &&
        that.orderlist.forEach((item, index) => {
          mount += item.count
        })
      return mount
    },
    // 订阅刊物费
    bookFee: () => {
      let fee = 0
      // console.log(that.orderlist)
      that.orderlist &&
        that.orderlist.forEach((item, index) => {
          fee = fee + item.count * item.half_year_price
        })
      return fee
    },
    // 总费用
    allFee: () => {
      return that.postFee + that.bookFee
    },
  },
  created() {
    that = this
  },
  mounted() {
    this.queryPromptToast()
    this.getOrder()
  },
  methods: {
    testClick: function () {},
    // 获取订单列表
    getOrder: function () {
      this.address =
        (sessionStorage['addressMsg'] &&
          JSON.parse(sessionStorage['addressMsg'])) ||
        {}
      this.haveAdress = this.address.nameValue ? true : false
      this.orderlist =
        sessionStorage['orderList'] && JSON.parse(sessionStorage['orderList'])
    },

    /**
     * ****************************************************************************************************
     * ** 确认订单
     * ****************************************************************************************************
     */
    confirmOrders: function () {
      // 未确认升级提示
      if (this.showUpgradeToast) return
      
      // 正在生成订单
      if (!this.creatSwitch) return

      // 是否填写了收货地址信息
      if (this.haveAdress) {
        // console.log('已填写信息')
        // 更细订单生成状态
        this.creatSwitch = false
        // 填写完地址之后的提示消息
        that.showPro = true
      } else {
        // console.log('未填写信息')
        this.showAdress = true
      }
    },

    /**
     * 提示消息 确定事件
     */
    confirmSubmitOrder: function () {
      this.showPro = false
      let books = []
      let cartNos = []
      that.orderlist &&
        that.orderlist.forEach((el) => {
          books.push({
            book_no: el.book_no,
            count: el.count,
          })
          if (el.cart_no) {
            cartNos.push(el.cart_no)
          }
        })
      // 调接口,成功回调中弹框
      that
        .$http({
          url: 'order/add',
          data: {
            books: books,
            cart_no: cartNos,
            city_id: sessionStorage['city'],
            xz_type: sessionStorage['xzType'],
            name: that.address.nameValue,
            phone: that.address.telValue,
            mechanism_id: that.address.schoolId,
            school_type_id: that.address.schoolTypeId,
            grade_id: that.address.gradeId,
            classes_id: that.address.classId,
            source: 3,
            is_cart_count: 1,
          },
        })

        .then((res) => {
          that.$message.success('订单生成成功')
          sessionStorage.removeItem('orderList') // 移除订单信息
          sessionStorage.removeItem('addressMsg') // 移除地址信息
          let payObj = {
            code: res.result.code_url || '',
            orderId: res.result.order_no,
            price: res.result.total_price,
            url: res.result.url || '',
          }
          sessionStorage['payCode'] = JSON.stringify(payObj)
          sessionStorage['cartMount'] = res.result.cart_count
          that.$emit('init')
          // if (this.showPro == false){
          //   this.showPro = true
          //   return false
          // }

          this.showOrder = true
          this.creatSwitch = true
        })
        .catch((err) => {
          console.error(err)
          this.creatSwitch = true
        })
    },

    // 点击关闭
    closeAdress: function () {
      this.showAdress = false
    },
    // 点击添加地址
    showAdressBox: function () {
      this.showAdress = true
    },
    // 子组件点击添加地址
    addAdress: function () {
      this.address = JSON.parse(sessionStorage['addressMsg'])
      this.showAdress = false
      this.haveAdress = true
    },
    // 点击关闭确认订单弹窗
    closeOrders: function () {
      this.showOrder = false
      sessionStorage['orderType'] = 2
      that.$router.replace({
        path: 'orders',
      })
    },
    // 查询升级提示
    queryPromptToast: function () {
      this.$http({
        method: 'GET',
        url: 'web_config/create_order_prompt',
        data: {},
      })

        .then((res) => {
          this.upgradeToast.title = res.result.title
          this.upgradeToast.text = res.result.content
          this.showUpgradeToast = true
        })
        .catch((err) => {
          console.error(err)
        })
    },
    upgradeToastConfirm: function () {
      this.showUpgradeToast = false
    }
  },
}
</script>

<style scoped>
.pay {
  padding-top: 90px;
}

.pay .banner {
  margin-top: 0;
}

.pay .el-input-number {
  width: 135px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -67.5px;
  margin-top: -20px;
}

.order-detail {
  width: 100%;
  background: rgb(240, 249, 253);
}

.order-detail > .fl {
  padding: 30px 40px;
  border-right: 1px solid #dddddd;
  width: 63%;
  position: relative;
  /* height: 212px; */
}

.order-detail > .fl .detail-address {
  width: calc(100% - 240px);
}

.order-detail > .fl .detail-address span {
  display: inline-block;
  width: 100%;
  line-height: 2.3;
}

.order-detail > .fl .add-address {
  position: absolute;
  width: 135px;
  height: 45px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(232, 232, 232);
  border-radius: 23px;
  text-align: center;
  line-height: 45px;
  top: 50%;
  right: 6%;
  margin-top: -23px;
  cursor: pointer;
  color: #3b77d1;
}

.order-detail > .fl .add-address:hover {
  background: #3f7ad2;
  background: linear-gradient(90deg, #3fa6d2, #3f7ad2);
  color: #ffffff;
}

.order-detail > .fr {
  padding: 30px 40px;
  width: 37%;
}

.order-detail > .fr > p {
  text-align: right;
  font-size: 0.18rem;
  color: #333;
  margin-bottom: 0.14rem;
  line-height: 2;
}

.order-detail > .fr > p span {
  color: #3b77d1;
  font-size: 0.26rem;
  font-weight: bold;
}

.order-detail > .fr > div {
  width: 100%;
}

.order-detail > .fr > div p span {
  font-size: 0.18rem;
  color: #888;
}

.order-detail > .fr > div div {
  margin-top: 0.2rem;
}

.order-detail > .fr > div div span {
  color: #333333;
}

.order-detail > .fr > div div b {
  font-size: 0.34rem;
  color: #3b77d1;
  font-weight: bold;
}

.confirm-btn {
  width: 200px;
  height: 60px;
  margin: 40px 0 0 auto;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  line-height: 60px;
  cursor: pointer;
}

.confirm-btn:hover {
  color: #053f7c;
}

.list-item .item-detail {
  cursor: default;
}

.list-item .item-detail .item-img {
  width: 21%;
  margin-left: 3%;
}

.list-item .item-detail .item-msg {
  margin-left: 3%;
}

.list-item .item-allPrice > span {
  line-height: 160px;
}

.pay .item-allPrice {
  position: relative;
}

.pay .item-allPrice .item-price-box {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.pay .item-allPrice .item-price-box > span {
  color: #777777;
  font-size: 14px;
  font-weight: 500;
}

.pay .item-allPrice .item-price-box > p {
  text-align: center;
}

.pay .item-allPrice .item-price-box > p span {
  display: inline-block;
  width: auto;
  color: #000000;
  font-size: 18px;
}
</style>
