<!-- 填写开票信息页面 -->
<template>
  <div class="invoice">
    <div class="page-box">
      <!-- ######################################################################################################################## -->
      <!-- 页面标题 开始 -->
      <div class="page-title clear">
        <div class="page-icon fl">
          <i class="iconfont iconfapiao"></i>
          <span>开具发票</span>
        </div>
        <div class="page-return fr" @click="$router.go(-1)">
          <i class="am iconfont iconzhiyuanfanhui3"></i>
          <span class="am">返回上一步</span>
        </div>
      </div>
      <!-- 页面标题 结束 -->
      <!-- ######################################################################################################################## -->

      <!-- ######################################################################################################################## -->
      <!-- 主内容 开始 -->
      <div class="invoice-container">
        <!-- 开票导航 -->
        <div class="invoice-guide clear">
          <div class="item active">选择开票商品及数量</div>
          <div class="item active">填写开票信息</div>
          <div class="item">完成开票申请</div>
        </div>

        <!-- 开票信息 -->
        <div class="invoice-form">
          <div class="invoice-line clear invoice-head">
            <span>发票抬头</span>
            <div class="choose-type">
              <span
                :class="['am', { on: type == 1 }]"
                @click="onChangeInvoiceType(1)"
                >个人</span
              >
              <span
                :class="['am', { on: type == 2 }]"
                @click="onChangeInvoiceType(2)"
                >单位</span
              >
            </div>
          </div>
          <div class="invoice-line clear">
            <span>购方名称</span>
            <input
              id="taxname"
              type="text"
              v-model="invoice.buyerName"
              placeholder="请输入准确名称"
              v-support
            />
            <div class="taxlist" v-if="type == 2 && showList">
              <p
                v-for="(item, index) in taxList"
                :key="index"
                @click="chooseThis(item)"
              >
                {{ item.unitName }}
              </p>
            </div>
          </div>
          <div class="invoice-line clear" v-show="type == 2">
            <span>购方税号</span>
            <input
              type="text"
              v-model.trim="invoice.buyerNum"
              :disabled="isSelect"
              placeholder="请输入纳税人识别号"
              v-support
              maxlength="20"
            />
          </div>
          <div class="invoice-line clear" v-show="type == 2">
            <div class="label">
              购方银行<span class="optional">(选填)</span>
            </div>
            <input
              type="text"
              v-model.trim="invoice.buyerBank"
              placeholder="请输入正确的银行"
              v-support
              maxlength="35"
            />
          </div>
          <div class="invoice-line clear" v-show="type == 2">
            <div class="label">
              银行账号<span class="optional">(选填)</span>
            </div>
            <input
              type="text"
              v-model.trim="invoice.bankNum"
              placeholder="请输入银行账号"
              v-support
              maxlength="30"
            />
          </div>
          <div class="invoice-line clear">
            <span>购方地址</span>
            <input
              type="text"
              v-model="invoice.buyerAddress"
              placeholder="请输入准确地址"
              v-support
              maxlength="40"
            />
          </div>
          <div class="invoice-line clear">
            <span>购方电话</span>
            <input
              type="text"
              v-model.trim="invoice.buyerPhone"
              placeholder="请输入联系电话"
              v-support
              maxlength="20"
            />
          </div>
          <div class="invoice-line clear">
            <span>手机号码</span>
            <input
              type="text"
              v-model.trim="invoice.phoneNum"
              placeholder="请输入常用手机号"
              v-support
            />
          </div>
          <div class="invoice-line clear">
            <span>电子邮箱</span>
            <input
              type="text"
              v-model.trim="invoice.buyerEmail"
              placeholder="请输入正确的邮箱"
              v-support
            />
          </div>
        </div>
        <div class="clear toApply">
          <span class="invoice-btn fl" @click="toApply">申请开票</span>
          <span class="fr">*请仔细核对填写信息是否准确无误</span>
        </div>
      </div>
      <!-- 主内容 结束 -->
      <!-- ######################################################################################################################## -->
    </div>
  </div>
</template>

<script>
import store from '../../store'

let that
export default {
  data: () => {
    return {
      orderNo: '', // 订单号
      type: 1, // 发票抬头类型1:个人;2:单位
      invoice: {
        buyerName: '', // 购方名称
        buyerNum: '', // 购方税号
        buyerBank: '', // 银行名称
        bankNum: '', // 银行账号
        buyerAddress: '', // 购方地址
        buyerPhone: '', // 购方电话
        phoneNum: '', // 手机号码
        buyerEmail: '', // 电子邮箱
      },
      taxList: [], // 税号列表
      sending: false, // 正在请求
      showList: false, // 展示列表
      isSelect: false, // 是否选择
    }
  },

  created() {
    that = this
    this.orderNo = this.$route.query.order_no
  },

  mounted() {
    let dataMsg = JSON.parse(sessionStorage.getItem('invoiceInfo'))
    this.type = dataMsg ? dataMsg.type : 1
    this.invoice = dataMsg ? dataMsg.invoice : this.invoice
    this.inputCompany()
  },

  methods: {
    // ========================================================================================
    // 去申请
    // ========================================================================================
    toApply: function () {
      if (!this.invoice.buyerName) {
        this.$message.error('请输入购方名称')
        return false
      }
      // 单位发票
      if (this.type == 2 && !this.invoice.buyerNum) {
        this.$message.error('请输入购方税号')
        return false
      }
      if (
        this.type == 2 &&
        (!this.invoice.buyerNum ||
          !this.$store.state.regTax.test(this.invoice.buyerNum))
      ) {
        this.$message.error('请输入正确的购方税号')
        return false
      }
      let len = this.invoice.buyerNum.length
      if (this.type == 2 && len != 15 && len != 17 && len != 18 && len != 20) {
        this.$message.error('请输入正确的购方税号')
        return false
      }

      // 按如下需求取消开户行+银行账号的必填验证
      // `2.单位选择开发票时，好多单位是没有银行账号的，开户行及账号一栏是否可以改为选填项；`
      // if (this.type == 2 && !this.invoice.buyerBank) {
      //   this.$message.error('请输入购方银行');
      //   return false;
      // }
      // if (this.type == 2 && !this.invoice.bankNum) {
      //   this.$message.error('请输入银行账号');
      //   return false;
      // }

      // 公共验证
      if (!this.invoice.buyerAddress) {
        this.$message.error('请输入购方地址')
        return false
      }
      if (!this.invoice.buyerPhone) {
        this.$message.error('请输入购方电话')
        return false
      }
      if (!this.$store.state.regTel.test(this.invoice.buyerPhone)) {
        this.$message.error('请输入正确的购方电话')
        return false
      }
      if (!this.invoice.phoneNum) {
        this.$message.error('请输入手机号')
        return false
      }
      if (!this.$store.state.regPhone.test(this.invoice.phoneNum)) {
        this.$message.error('请输入正确的手机号')
        return false
      }
      if (!this.invoice.buyerEmail) {
        this.$message.error('请输入购方邮箱')
        return false
      }
      if (!this.$store.state.regMail.test(this.invoice.buyerEmail)) {
        this.$message.error('请输入正确的邮箱')
        return false
      }
      // 将填写数据进行缓存
      let invoiceInfo = {}
      invoiceInfo.invoice = that.invoice
      invoiceInfo.type = that.type
      sessionStorage.setItem('invoiceInfo', JSON.stringify(invoiceInfo))
      // console.log(JSON.parse(sessionStorage['InvoiceTwoGoodsData']))
      that
        .$http({
          url: 'invoice/add',
          data: {
            order_no: this.orderNo,
            goods: JSON.parse(sessionStorage['InvoiceTwoGoodsData']),
            type: this.type,
            address: this.invoice.buyerAddress,
            tel: this.invoice.buyerPhone,
            phone: this.invoice.phoneNum,
            email: this.invoice.buyerEmail,
            name: this.invoice.buyerName,
            taxpayersNo: this.invoice.buyerNum,
            bankID: this.invoice.buyerBank,
            bankNo: this.invoice.bankNum,
          },
        })
        .then((res) => {
          // console.log(res)
          this.$router.push('/invoiceSuccess')
        })
        .catch((err) => {
          console.error(err)
        })
    },

    // ========================================================================================
    // 公司名称输入
    // ========================================================================================
    inputCompany: function () {
      let that = this
      if (!this.sending) {
        let dom = document.getElementById('taxname')
        dom.addEventListener(
          'input',
          that.$utils.debounce(that.checkTax, 400, true)
        )
      }
    },

    // ========================================================================================
    // 查询税号
    // ========================================================================================
    checkTax: function () {
      if (this.invoice.buyerName && this.type == 2) {
        this.sending = true
        this.$http({
          url: 'invoice/get_company_info',
          data: {
            name: this.invoice.buyerName,
          },
        })
          .then((res) => {
            this.taxList = res.result
            this.showList = res.result.length > 0 ? true : false
            this.isSelect = false
            setTimeout(() => {
              this.sending = false
            }, 300)
          })
          .catch((err) => {
            setTimeout(() => {
              this.sending = false
            }, 300)
          })
      }
    },

    // ========================================================================================
    // 选择此项
    // ========================================================================================
    chooseThis: function (item) {
      if (item && item.unitName) {
        this.invoice.buyerName = item.unitName
        this.invoice.buyerNum = item.unitTaxNo ? item.unitTaxNo : ''
        this.invoice.buyerAddress = item.unitAddress ? item.unitAddress : ''
        this.invoice.buyerPhone = item.unitPhone ? item.unitPhone : ''
        this.invoice.buyerBank = item.bankName ? item.bankName : ''
        this.invoice.bankNum = item.bankNo ? item.bankNo : ''
        this.isSelect = item.unitTaxNo ? true : false
        this.showList = false
      }
    },

    // ========================================================================================
    // 切换发票类型
    // ========================================================================================
    onChangeInvoiceType: function (type) {
      this.type = type
      Object.keys(this.invoice).forEach((key) => (this.invoice[key] = ''))
      this.taxList = [] // 税号列表
      this.sending = false // 正在请求
      this.showList = false // 展示列表
      this.isSelect = false // 是否选择
    },
  },
}
</script>

<style>
.invoice {
  padding-top: 90px;
}

.invoice-container {
  width: 900px;
  margin: 30px auto;
}

.invoice-container .invoice-guide {
  width: 100%;
  overflow: hidden;
}

.invoice-container .invoice-guide .item {
  float: left;
  position: relative;
  width: 33.33%;
  color: #3a3a3a;
  font-size: 0.16rem;
  text-align: center;
  background: #eeeeee;
  height: 56px;
  line-height: 56px;
}

.invoice-container .invoice-guide .item.active {
  background-color: #3f7ad2;
  background: linear-gradient(90deg, #3fa6d2, #3f7ad2);
  color: #ffffff;
}

.invoice-container .invoice-guide .item:before {
  content: '';
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  right: -56px;
  z-index: 10;
  border-width: 28px;
  border-style: solid;
  box-sizing: border-box;
  border-color: rgba(0, 0, 0, 0);
  border-left-color: #eeeeee;
}

.invoice-container .invoice-guide .item:after {
  content: '';
  width: 60px;
  height: 60px;
  position: absolute;
  top: -2px;
  right: -61px;
  z-index: 5;
  border-width: 30px;
  border-style: solid;
  box-sizing: border-box;
  border-color: rgba(0, 0, 0, 0);
  border-left-color: #ffffff;
}

.invoice-container .invoice-guide .item.active:before {
  border-left-color: #3f7ad2;
}

.invoice-form {
  border-bottom: 1px solid #eeeeee;
  padding: 35px 0 15px;
}

.invoice-form .invoice-line {
  width: 100%;
  margin-bottom: 25px;
  position: relative;
}

.invoice-form .invoice-line > span {
  display: inline-block;
  vertical-align: middle;
  line-height: 36px;
}

.invoice-form .invoice-line > span:first-child,
.invoice-form .invoice-line .label {
  float: left;
  color: #3a3a3a;
  width: 120px;
}

.invoice-form .invoice-line .choose-type {
  vertical-align: middle;
}

.invoice-form .invoice-line.invoice-head {
  margin-bottom: 30px;
}

.invoice-form .invoice-line.invoice-head > span {
  line-height: 2;
}

.invoice-form .invoice-line .choose-type span {
  margin-left: 5px;
  border-radius: 3px;
  padding: 10px 17px;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  line-height: 2;
}

.invoice-form .invoice-line .choose-type span.on {
  background-color: #3f7ad2;
  border-color: #327de0;
  color: #ffffff;
}

.invoice-form .invoice-line input {
  width: 300px;
  font-size: 14px;
  color: #666666;
  border: 1px solid #e3e3e3;
  height: 36px;
  padding: 0 5px;
  vertical-align: middle;
}

.invoice-container .toApply {
  margin-top: 15px;
}

.invoice-container .confirm-btn {
  display: inline-block;
  display: inline-block;
  padding: 6px 17px;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  border: none;
  opacity: 1;
  background: #3f7ad2;
  background: linear-gradient(90deg, #3fa6d2, #3f7ad2);
  outline: none;
  transition: all 0.2s;
  cursor: pointer;
}

.invoice-container .confirm-btn:hover {
  opacity: 0.8;
}
.taxlist {
  margin-left: 80px;
  width: 300px;
  background: #fafafa;
  border: 1px solid #dddddd;
  border-top: none;
  transition: all 0.2s linear;
}
.taxlist p {
  cursor: pointer;
  line-height: 2.3;
  padding-left: 8px;
  transition: all 0.2s linear;
}
.taxlist p:hover {
  background: #f2f2f2;
}

.optional {
  font-size: 0.75em;
  color: #ff0000;
}
</style>
