<template>
  <div class="confirm nowant">
    <div class="confirm-box">
      <div class="confirm-title lingred">
        <span>确认退款原因</span>
        <i class="window-close iconfont iconclose" @click="clickClose"></i>
      </div>
      <div class="no-want">
        <el-select v-model="result" placeholder="请选择" @change="changeResult">
          <el-option
            v-for="item in list"
            :key="item.id"
            :label="item.text"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <div class="lingred pay-btn" @click="noWant">立即提交</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { id: 'a', text: '不想买了' },
        { id: 'b', text: '填错信息' },
        { id: 'c', text: '其他' },
      ],
      result: '',
    }
  },
  props: {
    orderNo: null,
  },
  methods: {
    // 点击取消
    clickClose: function () {
      this.$emit('closeWant')
    },
    // 点击立即提交
    noWant: function () {
      let that = this
      // console.log(this.result);
      if (!this.result) {
        that.$message.error('请选择退款原因')
        return false
      }
      // 判断选择的理由是不是信息填写错误,如果选择的理由是信息填写错误就提示用户去订单列表页面更换地址
      if (this.result == '填错信息') {
        that.$message.error(
          '如果您的信息填写错误，请您前往订单列表页面自行更换学校地址！'
        )
        return false
      }
      this.$http({
        url: 'order/refund',
        data: {
          order_no: that.orderNo,
          refund_cause: that.result,
        },
        showLoading: true,
      })
        .then((res) => {
        //   console.log(res)
          this.$message.success('退款请求成功提交')
          this.$emit('confirmNoWant', this.result)
        })
        .catch((err) => {
          console.error(err)
        })
    },

    // 更换退款原因
    changeResult: function (e) {
      this.result = e.text
    },
  },
}
</script>

<style>
.nowant {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}
.nowant .confirm-box {
  width: 860px;
  max-height: 580px;
  margin-left: -430px;
  position: absolute;
  left: 50%;
  top: 50%;
  padding-bottom: 40px;
  margin-top: -290px;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
}
.nowant .confirm-title {
  width: 100%;
  position: relative;
  height: 80px;
}
.nowant .confirm-title span {
  text-align: center;
  font-size: 0.26rem;
  color: #fff;
  line-height: 80px;
  display: inline-block;
  width: 100%;
}
.nowant .confirm-title i {
  color: #666;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 28px;
  font-weight: 100;
  padding: 10px;
  cursor: pointer;
}
.no-want {
  margin: 30px auto;
  text-align: center;
}
.no-want .el-select {
  width: 400px;
  height: 60px;
  font-size: 0.18rem;
  background: #f9f9f9;
  border: none;
}
.no-want .el-select .el-input,
.no-want .el-select .el-input input {
  border: none;
  background: transparent;
  height: 60px;
}
.confirm .confirm-box .pay-btn {
  text-align: center;
  display: block;
  width: 220px;
  height: 60px;
  line-height: 60px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  margin: 0 auto;
}
</style>
