<template>
  <div class="process">
    <div class="banner"><img :src="bannerImg" alt="" /></div>
    <div class="process-box">
      <div class="processtitle clear">
        <p>
          注：如有不明白，请在微信公众号留言(山东教育社)
          ，我们会尽快回复您(工作日时间)
        </p>
        <div class="steps">
          <ul class="clear processnav">
            <li
              v-for="item in navList"
              :key="item.id"
              @click="jumpLoc(item.id)"
            >
              {{ item.name }}
            </li>
          </ul>
          <div @click="jump()" class="add-btn btn-lingred" style="float: right">
            <span>跳过说明</span>
          </div>
        </div>
      </div>
      <div class="processtop clear">
        <div class="processshu"></div>
        <div class="processname">
          <img :src="gwlc" alt="" />
          <p class="processbf">操作流程</p>
          <p>Instructions for</p>
        </div>
        <div class="processwarn">
          <p>1.请牢记操作流程</p>
          <p>2.已熟悉操作流程的用户，请直接点击跳过流程</p>
        </div>
      </div>
      <div class="processlist">
        <div class="processitem" id="processa">
          <p class="process-module-title">
            <img :src="one" /><span>绑卡操作说明</span>
          </p>
          <div class="process-module-content">
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">
                  ① 打开微信应用，点开“我”
                </div>
                <img class="process-module-item-image" :src="lc1_1" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">② 点击“支付”</div>
                <img class="process-module-item-image" :src="lc1_2" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">③ 点击“钱包”</div>
                <img class="process-module-item-image" :src="lc1_3" />
              </div>
            </div>
            <div class="process-module-block-line"></div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">④ 点击“银行卡”</div>
                <img class="process-module-item-image" :src="lc1_4" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">⑤ 点击“添加银行卡”</div>
                <img class="process-module-item-image" :src="lc1_5" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">⑥ 输入支付密码</div>
                <img class="process-module-item-image" :src="lc1_6" />
              </div>
            </div>
            <div class="process-module-block-line"></div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">
                  ⑦ 输入持卡人及银行卡卡号
                </div>
                <img class="process-module-item-image" :src="lc1_7" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">⑧ 同意用户协议</div>
                <img class="process-module-item-image" :src="lc1_8" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">⑨ 添加完成</div>
                <img class="process-module-item-image" :src="lc1_9" />
              </div>
            </div>
            <div class="process-module-block-line"></div>
          </div>
        </div>
        <div class="processitem" id="processb">
          <p class="process-module-title">
            <img :src="two" /><span>设置优先支付</span>
          </p>
          <div class="process-module-content">
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">
                  ① 打开支付页面后，<br /><span class="seize"></span
                  >点击页面左上角的收付款
                </div>
                <img class="process-module-item-image" :src="lc2_1" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">
                  ② 打开向商家付款页面后，<br /><span class="seize"></span
                  >点击页面如图所示位置
                </div>
                <img class="process-module-item-image" :src="lc2_2" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">
                  ③ 选择展开的选项中所绑定的公务卡
                </div>
                <img class="process-module-item-image" :src="lc2_3" />
              </div>
            </div>
            <div class="process-module-block-line"></div>
          </div>
        </div>
        <div class="processitem" id="processc">
          <p class="process-module-title">
            <img :src="three" /><span>恢复原优先支付</span>
          </p>
          <div class="process-module-content">
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">
                  ① 打开支付页面后，<br /><span class="seize"></span
                  >点击页面左上角的收付款
                </div>
                <img class="process-module-item-image" :src="lc3_1" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">
                  ② 打开向商家付款页面后，<br /><span class="seize"></span
                  >点击页面如图所示位置
                </div>
                <img class="process-module-item-image" :src="lc3_2" />
              </div>
            </div>
            <div class="process-module-item-wrapper">
              <div class="process-module-item-content">
                <div class="process-module-item-title">
                  ③ 选择展开的选项中所绑定的公务卡
                </div>
                <img class="process-module-item-image" :src="lc3_3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerImg from '@/assets/images/process/banner.png'
import gwlc from '@/assets/images/gwlc.png'
import one from '@/assets/images/one.png'
import two from '@/assets/images/two.png'
import three from '@/assets/images/three.png'
import four from '@/assets/images/four.png'
import five from '@/assets/images/five.png'
import lc1_1 from '@/assets/images/process/1.1.png'
import lc1_2 from '@/assets/images/process/1.2.png'
import lc1_3 from '@/assets/images/process/1.3.png'
import lc1_4 from '@/assets/images/process/1.4.png'
import lc1_5 from '@/assets/images/process/1.5.png'
import lc1_6 from '@/assets/images/process/1.6.png'
import lc1_7 from '@/assets/images/process/1.7.png'
import lc1_8 from '@/assets/images/process/1.8.png'
import lc1_9 from '@/assets/images/process/1.9.png'
import lc2_1 from '@/assets/images/process/2.1.png'
import lc2_2 from '@/assets/images/process/2.2.png'
import lc2_3 from '@/assets/images/process/2.3.png'
import lc3_1 from '@/assets/images/process/3.1.png'
import lc3_2 from '@/assets/images/process/3.2.png'
import lc3_3 from '@/assets/images/process/3.3.png'

export default {
  name: 'businessCardBindingWechatOperationInstructions',
  data: () => {
    return {
      bannerImg: bannerImg,
      gwlc,
      one,
      two,
      three,
      four,
      five,
      lc1_1,
      lc1_2,
      lc1_3,
      lc1_4,
      lc1_5,
      lc1_6,
      lc1_7,
      lc1_8,
      lc1_9,
      lc2_1,
      lc2_2,
      lc2_3,
      lc3_1,
      lc3_2,
      lc3_3,
      navList: [
        { id: 'a', name: '绑卡操作说明' },
        { id: 'b', name: '设置优先支付' },
        { id: 'c', name: '恢复原优先支付' },
      ],
    }
  },
  created() {},
  methods: {
    jumpLoc: function (e) {
      let targetbox = document.querySelector('#process' + e).offsetTop - 200
      let top = 0
      document.documentElement.scrollTop = targetbox
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top +=
            100
        if (top >= targetbox) {
          clearInterval(timeTop)
        }
      }, 10)
    },

    /**
     * ****************************************************************************************************
     * ** 跳过说明
     * ****************************************************************************************************
     */
    jump() {
      this.$router.push({
        path: '/invoiceProcess',
        query: { order_no: this.$route.query.order_no },
      })
    },
  },
}
</script>

<style scoped>
.process {
  width: 100%;
}
.process-box {
  max-width: 1200px;
  min-width: 1000px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.processtitle {
  width: 100%;
  padding-top: 30px;
  text-align: center;
}
.processtitle p {
  display: right;
  text-align: right;
  font-size: 16px;
  margin-bottom: 10px;
}
.processtitle ul {
  display: inline-block;
  width: 800px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.processtitle .steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
}
.processtitle .steps .add-btn {
  width: 140px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  color: #333333;
  font-weight: bold;
}
.processtitle .steps .add-btn > span {
  color: #333333;
}
.processtitle .steps .add-btn:hover > span {
  color: #3b77d1;
}
.processtitle ul li {
  float: left;
  margin: 0 6% 0 0;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}
.processtitle ul li:hover {
  color: #3b77d1;
  border-bottom: 1px solid #3b77d1;
}
.processtop {
  width: 100%;
}
.processtop .processshu {
  width: 6px;
  height: 110px;
  background: #3b77d1;
  float: left;
  margin-right: 10px;
}
.processtop .processname {
  float: left;
  margin-bottom: 15px;
}
.processtop .processname .processbf {
  font-size: 26px;
  color: #333;
  font-weight: bold;
  line-height: 1.4;
}
.processtop .processname img {
  width: 50px;
  margin-top: 5px;
}
.processtop .processname p {
  color: #333;
}
.processtop .processwarn {
  float: left;
  margin: 10px 0;
  width: 100%;
  border: 1px solid #ddd;
  background: #f4f4f4;
  padding: 10px 20px;
}
.processtop .processwarn p {
  font-size: 18px;
  color: #333;
}
.processitem {
  width: 100%;
  margin-top: 35px;
}
.processitem p {
  color: #333;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
.processitem p img {
  display: inline-block;
  width: 50px;
  vertical-align: middle;
  border: none;
  margin: 0 5px 0 0;
}
.processitem p span {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
}
.processitem span {
  display: block;
  text-align: left;
  font-size: 18px;
  line-height: 2;
}
.processitem img {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
}
.processitem .processaf {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.processtk {
  margin-top: 50px;
}
.processtklist {
  display: block;
  width: 100%;
}
.processtklist .processtkitem:last-child {
  width: 66%;
  float: left;
  margin-left: 3%;
}
.processtklist .processtkitem:first-child {
  margin: 0;
  width: 31%;
  float: left;
}
.processtklist .processtkitem .processtkimg {
  width: 100%;
  margin-bottom: 20px;
}
.processtklist .processtkitem div {
  height: 245px;
}
.processtklist .processtkitem .processtkimg {
  height: 245px;
}
.processtklist .processtkitem div .processtkimg:first-child {
  width: 48%;
  float: left;
  height: 245px;
}
.processtklist .processtkitem div .processtkimg:last-child {
  width: 48%;
  float: right;
  height: 245px;
}
.processtklist .processtkitem p {
  margin-bottom: 10px;
}
.processtklist .processtkitem p span {
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}
.processtklist .processtkitem p img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 50px;
}
.processtklist .processtkitem span {
  font-size: 18px;
  color: #333;
}
.process-module-title {
  display: flex;
  align-items: center;
}
.process-module-title > span {
  padding-left: 8px;
}
.process-module-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
}
.process-module-item-wrapper {
  width: 328px;
  flex-shrink: 0;
}
img.process-module-item-image {
  width: 100%;
  height: auto;
  margin: 0 -16px;
}
.process-module-item-title {
  color: #808080;
}
.process-module-block-line {
  border-top: 1px solid #e9e9e9;
  margin: 40px 0;
  width: 100%;
}
span.seize {
  display: inline-block;
  width: 18px;
}
</style>
