/**
 * ************************************************************************************************************************
 * @description HTTP请求封装。
 * @description 使用前需先引用Axios。
 * ************************************************************************************************************************
 */
import axios from 'axios';
import { Loading, Message} from 'element-ui';
import store from '../../store'
import Vue from 'vue'

// 正常接口
let baseURL = '/app/';
// 地方教材接口地址
let MaterialBaseURL = '/material/';
if (process.env.NODE_ENV === "development") {
  baseURL = "/api/app/";
  MaterialBaseURL = "/api/material/";
}

let loading;
// 进一步封装。
var http = function $http(settings,Vue) {
  var url = settings.isMaterial ? MaterialBaseURL : baseURL;
	// 默认配置。
	var config = {
		// Axios原生配置项。
		url: '',									// 请求地址。
		baseURL: url,					// 拼接到url前面，除非url是一个绝对路径。
		method: 'POST',						//	请求类型
		data: {},									// 请求参数。
		params: {},								// url参数，拼接在请求url后面。
		headers: {
			'Authorization': sessionStorage['token']||''
		},
		timeout: 60000,						// 超时时间，毫秒。
		// `onUploadProgress` 允许为上传处理进度事件。
		onUploadProgress: function(progressEvent) {
			// console.log(progressEvent)
		},
		// `onDownloadProgress` 允许为下载处理进度事件。
		onDownloadProgress: function(progressEvent) {
			// console.log(progressEvent)
		},
		// 自定义配置 - loading相关。
		showLoading: false,				// 是否显示loading动画。
		loadingText: '加载中...',	// loading文本。
		loadingMask: true,				// 是否显示遮罩层？
		loadingIcon: '',					// 图标。
		loadingType: 'spinner',		// 图标类型。
		loadingDuration: 0,				// 展示时长（毫秒），值为0时，不会消失。
		// 自定义配置 - 成功提示相关。
		showSuccessTips: true,		// 是否显示成功提示？
		successTipsText: '',			// 提示文本。
		successTipsMask: false,		// 是否显示遮罩层？
		successTipsIcon: '',			// 图标。
		successTipsDuration: 3000,// 展示时长（毫秒），值为0时，不会消失。
		successTipsCloseCallback: function() {},// 关闭时的回调函数。
		// 自定义配置 - 错误提示相关。
		showErrorTips: true,			// 是否显示错误提示？
		errorTipsText: '',				// 提示文本。
		errorTipsMask: false,			// 是否显示遮罩层？
		errorTipsIcon: '',				// 图标。
		errorTipsDuration: 3000,	// 展示时长（毫秒），值为0时，不会消失。
		errorTipsCloseCallback: function() {},		// 关闭时的回调函数。
		
		judgeMessage:true,				// 默认错误弹出提示
	};

	function deepMergeObject(FirstOBJ, SecondOBJ) {
		for (var key in SecondOBJ) {
			FirstOBJ[key] = FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]" ? deepMergeObject(FirstOBJ[key],
				SecondOBJ[key]) : FirstOBJ[key] = SecondOBJ[key];
		}
		return FirstOBJ;
	}

	// 合并参数对象。
	let cusConfig;
	cusConfig = deepMergeObject(config, settings || {});
	config.data = cusConfig.data

	// 判断是否显示loading。
	if (config.showLoading) {
		loading = Loading.service('加载中');
	}

	// 进行请求。
	return new Promise(function(resolve, reject) {
		axios(config).then(function(response) {

			if (response.headers && response.headers.authorization) {
				sessionStorage['token'] = response.headers.authorization;
			}
			var data = response.data;
			// 判断是否需要关闭loading。
			if (config.showLoading) {
				loading.close();
			}
			var material = store.state.isMaterialOrder > 0 || settings.isMaterial;

			// 200：数据正常。
			if (data.code == 200) {
        resolve(data);
			} else {
				// 未选择城市学制
				if (config.judgeMessage || data.code != 201) {
					Message({type:'error',message:data.msg})
				}
				if (data.code == '400' || data.code == '500') {
          sessionStorage.clear();
          setTimeout(()=>{
						window.location.href = material ? '/place/login' : '/choose';
						// window.location.href = '/choose';
					},1000)
				}
				reject(data);
			}
		}).catch((error) => {
			if (config.showLoading) {
				loading.close();
			}
			if (config.judgeMessage || error.code != 201) {
				Message({type:'error',message: error.msg})
			}
			reject(error);
		});
	});
};

export { baseURL, http };