<template>
  <div class="p-choose" style="padding: 120px">
    <div class="ct-container">
      <div class="choose-title">
        <img :src="logoImg" alt="" />
        <span>报刊订阅</span>
      </div>
      <!--			<div class="select-district">
				<i class="iconfont icondingwei"></i>
				<span>选择地区</span>
			</div>-->
      <div>
        <!-- 新加外链跳转 -->
        <div
          class="select-district"
          style="border-bottom: 0; padding-bottom: 0"
        >
          <!-- <router-link to='/https://mall.sdjys.org/'> -->
          <p class="topic" @click="openUrl(url)">{{ textW }}</p>
          <!-- </router-link> -->
        </div>

        <div class="select-district" style="border-bottom: 0">
          <span>每学季报刊订阅入口</span>
        </div>
      </div>

      <div class="choose-list clear" v-if="areaList.length > 0">
        <div
          class="item"
          v-for="(item, index) in areaList"
          :key="index"
          @click="chooseCity(item)"
        >
          <img :src="item.background" alt="" />
          <span>{{ item.city_name }}</span>
        </div>
      </div>

      <div class="select-district">
        <router-link to="/place">
          <p class="topic">各地市补充教材结算入口</p>
        </router-link>
      </div>

      <!-- 			<div class="wh-box" v-else>
				<img src="@/assets/images/wh.png" alt="">
				<span>系统正在优化中，如有问题，请拨打客服热线：0531-51756788</span>
			</div> -->
    </div>
    <!-- 学制弹框 -->
    <div
      :class="['shadow', 'am', { on: showBox }]"
      @click.self="showBox = false"
    >
      <div class="system-container">
        <div class="clear">
          <div
            :class="['system-item', 'am', 'fl', { on: systemType == 2 }]"
            @click.stop="selectSystem(2)"
          >
            <img :src="sixthree" alt="" />
            <div>
              <i class="iconfont iconsuccess am"></i>
              <span class="am">六三制</span>
            </div>
          </div>
          <div
            :class="['system-item', 'am', 'fr', { on: systemType == 1 }]"
            @click.stop="selectSystem(1)"
          >
            <img :src="fivefour" alt="" />
            <div>
              <i class="iconfont iconsuccess am"></i>
              <span class="am">五四制</span>
            </div>
          </div>
        </div>
        <span v-show="cityName == '济南市' || cityName == '青岛市'"
          >{{
            cityName == '济南市' ? '莱芜区' : '莱西市'
          }}学生选择五四学制进行征订</span
        >
        <div class="confirm-btn" @click.stop="selectBtn">确认选择</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentArea: 0, // 当前地区
      currentSystem: 0, // 当前学制
      showBox: false, // 展示学制
      systemType: 0, // 学制类型
      logoImg: require('@/assets/images/logo.png'), // logo图片
      sixthree: require('@/assets/images/sixthree.png'), // 六三图片
      fivefour: require('@/assets/images/fivefour.png'), // 五四图片
      areaList: [],
      textW: '', // 地区列表
      //学制列表
      systemList: [
        {
          id: 2,
          name: '六三制',
          img: require('../assets/images/63.png'),
        },
        {
          id: 1,
          name: '五四制',
          img: require('../assets/images/54.png'),
        },
      ],
      selectData: {}, // 选中的城市数据
      cityName: '', // 城市名称
      errorRequest: '', // 错误请求次数
    }
  },
  // 新加外链跳转
  props: {
    url: {
      type: String,
      default: 'https://mall.sdjys.org/',
    },
  },
  created() {
    sessionStorage.clear()
    this.$store.commit('updateLoginMethod', 0)
    this.$store.commit('updateUser', '')
    this.requestList()
  },
  mounted() {
    //获取首页幼教园地订阅文字
    this.indexText()
  },
  methods: {
    // 新加外链跳转
    openUrl(url) {
      window.open(url, '_blank')
      //_blank : 在新窗口打开
      //_self : 在当前窗口打开

      //window.location.href = url : 当前页面重定向
    },

    // 获取首页幼教园地订阅文字
    indexText: function () {
      this.$http({
        url: 'city/mall_name',
        method: 'GET',
      })
        .then((res) => {
          // console.log(`output->res`,res.result)
          this.textW = res.result
        })
        .catch((err) => {
          console.error(`output->err`, err)
        })
    },

    // 点击确认选择
    confirmSelect() {
      this.$router.push('/')
      sessionStorage.setItem('currentId', '2')
    },
    // 选择这个城市
    chooseCity: function (e) {
      if (e.mode == 2) {
        // 如果是单独版
        window.open(e.href)
      } else {
        sessionStorage.setItem('city', e.city_id)
        sessionStorage.setItem('cityName', e.city_name)
        sessionStorage.setItem('outLine', e.is_line)
        sessionStorage.setItem('payType', e.pay_type)
        this.cityName = e.city_name
        // 如果是多学制
        if (e.data.length == 2) {
          this.selectData = e
          this.systemType = ''
          this.showBox = true
        } else {
          sessionStorage.setItem('official', e.data[0].official)
          // 单独学制跳转到数据列表
          sessionStorage.setItem('xzType', e.data[0].xz_type)
          this.$router.push({ path: '/booklist', query: { navId: '2' } })
        }
      }
    },
    // 选择这个学制
    selectSystem: function (e) {
      this.systemType = e
      const index = this.selectData.data.findIndex((item) => item.xz_type == e)
      sessionStorage.setItem('official', this.selectData.data[index].official)
      sessionStorage.setItem('xzType', e)
    },
    // 点击确认选择
    selectBtn: function () {
      if (!this.systemType) {
        this.$message.error('请选择学制')
        return false
      }
      this.$router.push({ path: '/booklist', query: { navId: '2' } })
      this.showBox = false
    },
    // 请求城市列表
    requestList: function () {
      this.$http({
        url: '/city',
        method: 'GET',
      })
        .then((res) => {
          this.areaList = res.result
        })
        .catch((err) => {
          if (err.code == 201) {
            this.errorRequest = this.errorRequest + 1
            if (this.errorRequest < 5) {
              setTimeout(() => {
                this.requestList()
              }, 500)
            } else {
              this.$message.error('网络错误，请稍后重试')
            }
          }
          console.error(err)
        })
    },
  },
}
</script>

<style>
.wh-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
}
.wh-box span {
  color: #888;
  font-size: 18px;
  margin-top: 20px;
}
.p-choose {
  background: url('../assets/images/choose-bg.jpg') no-repeat center center;
  background-size: cover;
  padding: 160px 0 70px;
}
.p-choose .ct-container {
  min-width: 980px;
  max-width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 8px;
  padding: 0.6rem 5%;
}
.ct-container .choose-title {
  margin: 0 auto;
  text-align: center;
}
.choose-title img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25rem;
  padding-right: 0.25rem;
  border-right: 1px solid #eeeeee;
}
.choose-title span {
  color: #333333;
  font-size: 32px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}
.select-district {
  margin: 35px auto 0;
  width: 920px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  padding: 15px 0;
}
.select-district:nth-of-type(2) {
  margin: 20px auto 0;
  padding-bottom: 0;
}
.select-district .topic {
  width: 300px;
  cursor: pointer;
  font-size: 24px;
  line-height: 56px;
  border-radius: 4px;
  background: linear-gradient(
    105deg,
    rgb(63, 129, 210) 0%,
    rgb(136, 210, 103) 100%
  );
  color: #fff;
  text-align: center;
}
.select-district i {
  font-size: 24px;
  color: #3b77d1;
  margin-right: 0.2rem;
  padding-right: 0.2rem;
  border-right: 1px solid #eeeeee;
}
.select-district span {
  font-size: 24px;
  color: #333333;
  font-weight: bolder;
}
.choose-list {
  width: 920px;
  margin: 0 auto;
}
.choose-list .item {
  float: left;
  width: 200px;
  margin-right: 40px;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}
.choose-list .item:nth-child(4n) {
  margin-right: 0;
}
.choose-list .item img {
  width: 100%;
  border-radius: 8px;
}
.choose-list .item span {
  text-align: center;
  color: #333333;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  line-height: 3;
}
.choose-list .item:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}
.shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  pointer-events: none;
}
.shadow .system-container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 600px;
  margin-top: -300px;
  margin-left: -300px;
  background: #ffffff;
  border-radius: 8px;
  padding: 50px 50px 30px 50px;
}
.shadow .system-container span {
  display: inline-block;
  text-align: center;
  color: #333333;
  width: 100%;
  font-size: 16px;
  margin-top: 10px;
}
.shadow.on {
  opacity: 1;
  pointer-events: auto;
}
.system-container .system-item {
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 10px 20px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  width: 240px;
  cursor: pointer;
}
.system-container .system-item > img {
  height: auto;
  width: 230px;
}
.system-container .system-item > div {
  width: 100%;
  text-align: center;
}
.system-container .system-item > div i {
  width: 22px;
  height: 22px;
  line-height: 22px;
  color: #ffffff;
  margin-right: 5px;
  border-radius: 4px;
  vertical-align: middle;
  border: 1px solid #eeeeee;
  display: inline-block;
}
.system-container .system-item > div span {
  font-size: 0.2rem;
  font-weight: bold;
  line-height: 2;
  vertical-align: middle;
}
.system-container .system-item.on > div i {
  background: #3e8bff;
}
.system-container .system-item.on > div span {
  color: #3e8bff;
}
.system-container .system-item.on {
  border: 1px solid #3e8bff;
}
.confirm-btn {
  width: 50%;
  height: 0.6rem;
  line-height: 0.6rem;
  margin: 30px auto 10px;
  border-radius: 25px;
  background: #3e8bff;
  background: linear-gradient(90deg, #3e8bff, #88d267);
  text-align: center;
  line-height: 2;
  color: #ffffff;
  font-size: 0.28rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>
