<template>
	<div class="no-data">
		<img :src="noData" alt="">
		<span>{{noDataText}}</span>
	</div>
</template>

<script>
	export default {
		data:()=>{
			return {
				noData:require('@/assets/images/no_data_tips.png')
			}
		},
		props:{
			noDataText:String
		}
	}
</script>

<style>
	.no-data{width: 100%;text-align: center;}
	.no-data img{width: 200px;height: auto;margin: 30px auto;}
	.no-data span{width: 100%;text-align: center;color: #999999;font-size: 22px;}
</style>
