<template>
  <div class="confirm">
    <div class="confirm-box">
      <p>{{title}}</p>
      <div class="clear">
        <div class="fl lingred confirm-btn btn" @click="clickConfirm">确认</div>
        <div class="fr close btn" @click="clickClose">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    props: {
      title: String
    },
    methods: {
      // 点击确认
      clickConfirm: function() {
        this.$emit('yes');
      },
      // 点击取消
      clickClose: function() {
        this.$emit('no');
      }
    }
  }
</script>

<style scoped>
  .confirm {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, .6);
  }

  .confirm .confirm-box {
    width: 380px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 45px 30px;
    overflow: hidden;
    background: #FFFFFF;
  }

  .confirm .confirm-box>p {
    text-align: center;
    color: #333;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .confirm .confirm-box>div {
    width: 100%;
  }

  .confirm .confirm-box>div .btn {
    cursor: pointer;
    width: 140px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 30px;
    font-size: 16px;
    margin: 0;
  }

  .confirm .confirm-box>div .close {
    background: #F2F2F2;
    color: #999;
  }
</style>
