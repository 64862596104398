<template>
  <div class="shopcar" :style="{ minHeight: $store.state.clientHeight }">
    <div class="page-box">
      <div class="page-title clear">
        <div class="page-icon fl">
          <i class="iconfont icongouwuche"></i>
          <span>购物车</span>
        </div>
        <div class="page-mount fr">
          共有<span>{{ allMount }}</span
          >套刊物
        </div>
      </div>
      <!-- 列表部分 -->
      <div class="shop-list">
        <div class="list-container">
          <div class="list-header clear">
            <span class="w325">刊物名称</span>
            <span class="w30">适用说明</span>
            <span class="w125">数量</span>
            <span class="w125">价格</span>
            <span class="w125">管理</span>
          </div>
          <div class="list-mainer">
            <div
              class="list-item clear"
              v-for="(item, index) in carlist"
              :key="item.id"
            >
              <div class="item-detail w325" @click="toSelect(item, index)">
                <div :class="['item-select', { on: item.selected }]">
                  <i class="iconfont iconsuccess"></i>
                </div>
                <div class="item-img"><img :src="item.picture" alt="" /></div>
                <div class="item-msg">
                  <p>{{ item.name }}</p>
                  <span :title="item.press">{{ item.press }}</span>
                  <div>
                    <span
                      class="item-type"
                      v-for="(type, index) in item.book_type"
                      :key="index"
                      >{{ type }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="item-text w30">
                <span>{{ item.apply_explain }}</span>
              </div>
              <div class="item-crease w125">
                <template>
                  <el-input-number
                    v-model="item.count"
                    :min="1"
                    :max="9999"
                    :precision="0"
                    :step="1"
                    label="描述文字"
                    @change="changeCount(item)"
                  ></el-input-number>
                </template>
              </div>
              <div class="item-allPrice w125">
                <div class="item-price-box">
                  <span>单价：{{ item.unit_price }}元/期</span>
                  <span
                    >[{{ item.frequency_name }}/共{{
                      item.frequency_periods
                    }}期]</span
                  >
                  <p>
                    总价：<span
                      >{{
                        (item.count * item.half_year_price).toFixed(2)
                      }}元</span
                    >
                  </p>
                </div>
              </div>
              <div class="item-operate w125">
                <div class="remove-btn am" @click="removeItem(item)">移除</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 暂无数据 -->
        <noData noDataText="购物车空空如也~" v-if="noData"></noData>
        <!-- 分页器 -->
        <!--        <div class="list-pager" v-if="!noData">
          <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="changePage"></el-pagination>
        </div> -->
        <!-- 底部信息 -->
        <div class="shop-bottom clear">
          <div class="all-select" @click="selectall">
            <div :class="['item-select', { on: allSelected }]">
              <i class="iconfont iconsuccess"></i>
            </div>
            <span>全选</span>
          </div>
          <span
            >您已选择
            <span class="on bold">{{ selectmount }}</span> 套刊物</span
          >
          <p>
            合计： <span>￥{{ bookFee.toFixed(2) }}</span
            >元 （刊物费）+ <span>￥{{ postFee.toFixed(2) }}</span
            >元（运费）
          </p>
          <div class="tol-mount bold">
            ￥<span class="on">{{ shouldPay.toFixed(2) }}</span
            >元
          </div>
          <div class="pay-btn" @click="toPay">结 算</div>
        </div>
      </div>
    </div>
    <!-- 弹框提示 -->
    <transition name="fade">
      <Confirm
        :title="confirmTitle"
        v-if="showConfirm"
        @yes="confirmYes"
        @no="confirmNo"
      ></Confirm>
    </transition>
  </div>
</template>

<script>
import bookImg from '@/assets/images/1.png'
import Confirm from '@/components/ConfirmBox.vue'
import noData from '@/components/noData.vue'
let that
const delay = (function () {
  let timer = 0
  return function (callback, ms) {
    clearTimeout(timer)
    timer = setTimeout(callback, ms)
  }
})()
export default {
  name: 'shopcar',
  data: () => {
    return {
      allSelected: false, // 是否全选
      showConfirm: false, // 移除弹框
      confirmTitle: '确认删除当前商品吗？', // 弹框问题
      carlist: [], // 购物车列表
      postFee: 0, // 快递费
      allFee: 0, // 总费用
      currentItem: '', // 当前操作按钮
      noData: false, // 暂无数据
      pageIndex: 1, // 当前页
      pageSize: 10, // 每页数量
      total: 0, // 总数量
      deleteList: [], // 待删除列表
    }
  },
  components: {
    Confirm,
    noData,
  },
  created() {
    that = this
  },
  mounted() {
    that.requestList()
    this.$store.commit('updateH', 130)
  },
  computed: {
    // 合计支付费用
    shouldPay: function () {
      return this.bookFee > 0 ? this.bookFee + this.postFee : 0
    },
    // 购物车共有几套书刊
    allMount: function () {
      let mount = 0
      this.carlist.forEach((item, index) => {
        mount = mount + item.count
      })
      return mount
    },
    // 购物车已选择几套书刊
    selectmount: function () {
      let selectMount = 0
      this.carlist.forEach((item, index) => {
        if (item.selected) {
          selectMount = selectMount += item.count
        }
      })
      return selectMount
    },
    // 合计刊物费
    bookFee: function () {
      let bookFee = 0
      this.carlist.forEach((item, index) => {
        if (item.selected) {
          bookFee = bookFee + item.count * item.half_year_price
        }
      })
      return bookFee
    },
  },
  methods: {
    // 点击选中
    toSelect: function (item, index) {
      this.carlist[index].selected = !this.carlist[index].selected
      this.allSelected = this.selectmount == this.allMount ? true : false
    },
    // 点击全选
    selectall: function () {
      this.allSelected = !this.allSelected
      this.carlist.forEach((item, index) => {
        item.selected = this.allSelected ? true : false
      })
    },
    // 点击结算按钮
    toPay: function () {
      let payList = this.carlist.filter((item, index) => {
        return item.selected
      })
      if (!payList.length > 0) {
        this.$message.error('您未选择结算的刊物')
      } else {
        sessionStorage.removeItem('addressMsg')
        sessionStorage.setItem('orderList', JSON.stringify(payList))
        this.$router.push('/confirm')
      }
    },
    // 点击移除按钮
    removeItem: function (item) {
      this.showConfirm = true
      this.currentItem = item
      this.deleteList = []
      this.deleteList.push(item.cart_no)
    },
    // 点击确认按钮
    confirmYes: function (item) {
      this.delete()
    },
    // 点击取消按钮
    confirmNo: function () {
      this.showConfirm = false
    },
    // 请求购物车列表
    requestList: function () {
      that
        .$http({
          url: 'shop_cart/index',
          method: 'GET',
          params: {
            city_id: sessionStorage['city'],
            xz_type: sessionStorage['xzType'],
            // page: that.pageIndex,
            // limit: that.pageSize
          },
        })
        .then((res) => {
          // console.log(res);
          // this.total = res.result.count;
          if (res.result.length > 0) {
            that.carlist = res.result.map((item) => {
              item.selected = false
              return item
            })
            that.noData = false
          } else {
            that.noData = true
          }
        })
        .catch((err) => {
          if (err.code == 500) {
            that.$emit('login')
          }
          console.error(err)
        })
    },
    // 点击分页
    changePage: function (e) {
      // console.log(e);
    },
    // 删除
    delete: function () {
      this.$http({
        url: 'shop_cart/del',
        method: 'POST',
        params: {
          city_id: sessionStorage['city'],
          xz_type: sessionStorage['xzType'],
          cart_no: this.deleteList,
          is_cart_count: 1,
        },
      })
        .then((res) => {
          this.deleteList.forEach((item) => {
            let index = this.carlist.findIndex((el) => {
              return el.cart_no === item
            })
            this.carlist.splice(index, 1)
            this.$message({
              type: 'success',
              message: '移除成功',
            })
          })
          if (res.result.cart_count == 0) {
            that.noData = true
          }
          sessionStorage['cartMount'] = res.result.cart_count
          that.$emit('init')
          that.showConfirm = false
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 点击修改数量
    changeCount: function (item) {
      delay(() => {
        that
          .$http({
            url: 'shop_cart/edit_count',
            method: 'POST',
            params: {
              city_id: sessionStorage['city'],
              xz_type: sessionStorage['xzType'],
              cart_no: item.cart_no,
              count: item.count,
            },
          })
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            console.error(err)
          })
      }, 500)
    },
  },
}
</script>

<style scoped="scoped">
.shopcar {
  padding-top: 90px;
}
.shopcar .el-input-number {
  width: 135px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -67.5px;
  margin-top: -20px;
}
.list-item .item-detail .item-img {
  width: 24%;
  margin-left: 0;
}
.shopcar .list-item .item-detail .item-msg {
  width: 60%;
  float: right;
  margin-left: 0;
}
.shopcar .item-allPrice {
  position: relative;
}
.shopcar .item-allPrice .item-price-box {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.shopcar .item-allPrice .item-price-box > span {
  color: #777777;
  font-size: 14px;
  font-weight: 500;
}
.shopcar .item-allPrice .item-price-box > p {
  text-align: center;
}
.shopcar .item-allPrice .item-price-box > p span {
  display: inline-block;
  width: auto;
  color: #000000;
  font-size: 18px;
}
.shopcar .item-totalPrice span {
  line-height: 160px;
  font-size: 20px;
  font-weight: bold;
  color: rgb(51, 51, 51);
  display: inline-block;
  text-align: center;
  width: 100%;
}
.remove-btn {
  width: 100px;
  display: block;
  margin: 60px auto 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid rgb(225, 225, 225);
  color: #666666;
  font-size: 14px;
  cursor: pointer;
  background: #ffffff;
}

.remove-btn:hover {
  border: 1px solid #3b77d1;
  color: #3b77d1;
}

.item-select {
  width: 24px;
  height: 24px;
  float: left;
  margin: 64px 10px 0;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  text-align: center;
}

.item-select i {
  color: #ffffff;
  line-height: 24px;
}

.item-select.on {
  background: rgb(63, 122, 210);
  background: linear-gradient(rgb(63, 122, 210) 0%, rgb(63, 166, 210) 100%);
  border: 1px solid rgb(63, 122, 210);
}

.item-select.on i {
  color: #ffffff;
}
.shop-bottom {
  width: 100%;
  background: rgb(241, 241, 241);
  height: 80px;
  margin: 50px 0 60px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
}

.shop-bottom .all-select {
  display: inline-block;
  padding-left: 25px;
  float: left;
  height: 80px;
  cursor: pointer;
  margin-right: 30px;
}

.shop-bottom .all-select .item-select {
  float: none;
  margin: 0;
  background: #ffffff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.shop-bottom .all-select .item-select.on {
  background: rgb(63, 122, 210);
  background: linear-gradient(rgb(63, 122, 210) 0%, rgb(63, 166, 210) 100%);
  border: 1px solid rgb(63, 122, 210);
}

.shop-bottom .all-select span {
  vertical-align: middle;
  display: inline-block;
  line-height: 80px;
  font-size: 20px;
  color: #333333;
}

.shop-bottom > span {
  line-height: 80px;
  font-size: 20px;
  float: left;
  margin-right: 5%;
}

.shop-bottom .on {
  color: #3b77d1;
}

.shop-bottom .bold {
  font-weight: bold;
  font-size: 24px;
}

.shop-bottom > p {
  color: #888;
  font-size: 16px;
  line-height: 80px;
  float: left;
}

.shop-bottom .tol-mount {
  float: left;
  color: #3b77d1;
  line-height: 80px;
  margin-left: 5%;
}

.shop-bottom .tol-mount span {
  color: #333333;
}

.shop-bottom .pay-btn {
  float: right;
  cursor: pointer;
  width: 12%;
  height: 80px;
  text-align: center;
  color: #ffffff;
  background: rgb(63, 129, 210);
  background: linear-gradient(
    105deg,
    rgb(63, 129, 210) 0%,
    rgb(136, 210, 103) 100%
  );
  line-height: 80px;
  font-size: 24px;
  font-weight: bold;
}

.shop-bottom .pay-btn:hover {
  color: #053f7c;
}
</style>
