import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		$API: '',
		token: '',																				// 是否登录
		regPhone:/^1[3|4|5|6|7|8|9]{1}[0-9]{9}$/,					// 手机正则
		regTel:/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/,		// 电话正则
		regMail:/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,	// 邮箱正则
		regTax:/^[A-Za-z0-9]+$/,													// 纳税人识别号
		regBank:/^\d{12,21}$/,														// 银行正则
		type:'',																					// 列表类型
		clientHeight:'',																	// 屏幕高度
		gradeId:'',																				// 所选年级
    isMaterialOrder: 0,																// 是否 是地方教材 登录
    user: '',																  // 用户手机号
	},
	getters: { },
	mutations: {
		updateH(state, value) {
			// 变更状态
      state.clientHeight = window.innerHeight - value + 'px';
		},
		
		updateGrade(state,value) {
			state.gradeId = value;
		},

    updateLoginMethod(state, value){
      state.isMaterialOrder = value
    },

    updateUser(state, value){
      state.user = value
    }
	},
	actions: {}
})

export default store
