<template>
  <div class="place-login">
    <div class="window-box">
      <div class="window_con">
        <!--        <i class="window_close iconfont iconclose"></i>-->
        <img class="window_bgone" :src="windowA" alt="" />
        <img class="window_bgtwo" :src="windowB" alt="" />
        <div class="window_title">
          {{ showWindow === 1 ? '欢迎登录' : '欢迎注册'
          }}<span>山东教育社刊物订购</span>
        </div>
        <div class="window_title" v-if="showWindow === 3">修改密码</div>
        <div class="window_form">
          <p>
            <i class="iconfont icongerenzhongxin"></i>
            <input
              type="text"
              placeholder="请您输入手机号"
              pad="true"
              v-model="phone"
              v-support
              maxlength="11"
            />
          </p>
          <div class="silde-box" v-if="showWindow > 1">
            <p class="slide-bg" :style="{ width: clientX - startX + 'px' }"></p>
            <div
              :class="['slide-btn', { on: ifPass }]"
              :style="{ left: clientX - startX + 4 + 'px' }"
              @mousedown="downSlide"
            >
              <i
                :class="[
                  'iconfont',
                  { iconicon_paging_right: !ifPass, iconsuccess: ifPass },
                ]"
              ></i>
            </div>
            <span :class="['slide-text', { on: ifPass }]">{{
              ifPass ? '校验成功' : '请向右滑动验证'
            }}</span>
          </div>
          <p class="window_shortp" v-if="showWindow > 1">
            <i class="iconduanxin iconfont"></i>
            <input
              type="text"
              placeholder="请您输入验证码"
              pad="true"
              v-model="msgCode"
              v-support
              autocomplete="off"
            />
            <span class="window_require" @click="requestCode">{{
              codeText
            }}</span>
          </p>
          <p>
            <i class="iconfont iconpassword"></i>
            <input
              type="password"
              placeholder="至少8位的数字+字母组合的密码"
              pad="true"
              v-model="password"
              v-support
              autocomplete="off"
            />
          </p>
          <span v-if="showWindow === 1"
            ><a class="forbtn" @click="changeType(3)">忘记密码?</a></span
          >
          <div class="window_btnbox clear">
            <p class="logbtn fl lingred" v-if="showWindow === 1" @click="login">
              登录
            </p>
            <p
              class="logbtn lingred modbtn"
              v-if="showWindow === 3"
              @click="modlog"
            >
              确认修改并登录
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import windowA from '@/assets/images/windowa.jpg'
import windowB from '@/assets/images/windowb.jpg'
import util from '../assets/js/util'
let that
export default {
  name: 'place-login',
  data: function () {
    return {
      windowA, // 背景图a
      windowB, // 背景图b
      phone: '', // 手机号
      password: '', // 密码
      checkCode: '请向右滑动验证', // 校验码
      codeText: '获取验证码', // 验证码内容
      msgCode: '', // 验证码
      ifPass: false, // 校验码状态
      startX: '', // 初始位置
      clientX: '', // 当前位置
      sending: false, // 正在发送验证码
      ifIe9: false, // 是否是ie11以下
      isSecond: false, // 是否是第二次发送验证码
      showWindow: 1, // 切换登录 和 注册
    }
  },
  created() {
    that = this
    let userAgent = navigator.userAgent
    if (
      userAgent.indexOf('compatible') > -1 &&
      userAgent.indexOf('MSIE') > -1
    ) {
      this.ifIe9 = true
      return
    }

    const protocol = window.location.protocol
    const host = window.location.host
    // 手机端的对应页面
    if (!util.IsPC()) {
      window.location.href = protocol + '//' + host + '/phone/material-login'
      return null
    }
    if (util.isWX()) {
      window.location.href =
        'http://wxzf.sdjys.org/wechat/openid?secret_key=StqVTlQQsF8F&uri=combine_material'
      return null
    }
  },
  mounted() {
    that.initSlide()
  },
  methods: {
    // 初始化滑块
    initSlide: function () {
      this.ifPass = false
      this.startX = 0
      this.clientX = 0
    },
    // 初始化表单
    initForm: function () {
      this.phone = ''
      this.password = ''
      this.checkCode = '请向右滑动验证'
      this.msgCode = ''
      this.codeText = '获取验证码'
      this.ifPass = false
    },
    // 点下滑块
    downSlide: function (e) {
      if (!this.ifPass) {
        this.startX = e.clientX
        this.clientX = e.clientX
      }
      document.addEventListener('mousemove', this.moveSlide)
      document.addEventListener('mouseup', this.upSlide)
    },
    // 滑动滑块
    moveSlide: function (ev) {
      let event = ev || window.event
      if (
        !this.ifPass &&
        event.clientX - this.startX < 531 &&
        event.clientX - this.startX >= 0
      ) {
        this.clientX = event.clientX
      }
    },
    // 抬起鼠标
    upSlide: function (ev) {
      let event = ev || window.event
      if (!this.ifPass) {
        if (event.clientX - this.startX > 530) {
          this.clientX = 530 + this.startX
          this.ifPass = true
        } else {
          this.startX = 0
          this.clientX = 0
        }
        document.removeEventListener('mousemove', this.moveSlide)
        document.removeEventListener('mouseup', this.upSlide)
      }
    },
    // 点击忘记密码/注册
    changeType: function (e) {
      this.initSlide()
      this.initForm()
      this.showWindow = e
      // this.$emit('update:showWindow',e);
    },
    // 点击获取验证码
    requestCode: function () {
      if (!this.phone) {
        this.$message.error('请输入手机号')
        return false
      }
      if (!this.$store.state.regPhone.test(this.phone)) {
        this.$message.error('请输入正确的手机号')
        return false
      }
      if (!this.ifPass) {
        this.$message.error('请滑动验证')
        return false
      }
      that
        .$http({
          url: 'send_sms',
          showLoading: true,
          data: {
            phone: that.phone,
            type: that.showWindow - 1, // 1:注册;2:忘记密码
            send_type: that.isSecond ? 2 : 1,
          },
        })
        .then((res) => {
          that.isSecond = true
          let num = 60
          if (!this.sending) {
            this.sending = true
            let timer = setInterval(() => {
              if (num > 1) {
                num--
                this.codeText = num + '秒后重新获取'
              } else {
                num = 0
                this.codeText = '点击重新获取'
                this.sending = false
                clearInterval(timer)
              }
            }, 1000)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 点击登录按钮
    login: function () {
      if (!this.checkData()) {
        return false
      }

      const word = JSON.stringify({
        phone: that.phone,
        password: that.password,
        user_type: 2, // 用户类型，固定值2
      })

      // 生成随机16位的iv字符串
      const iv = this.$utils.generateRandomString()

      that
        .$http({
          url: 'login',
          data: {
            iv: iv,
            data: this.$encrypt(word, this.$aesKey, iv),
            // phone: that.phone,
            // password: that.password,
            // user_type: 2, // 用户类型，固定值2
          },
          judgeMessage: false,
        })
        .then((res) => {
          sessionStorage['token'] = res.result.token
          sessionStorage['ifLogin'] = true
          sessionStorage['cartMount'] = res.result.cart_count
          sessionStorage['user'] = res.result.phone

          sessionStorage['ifRead'] = res.result.is_read_letter
          sessionStorage['selectGrade'] = 0
          sessionStorage['gradeId'] = 'kong'

          this.phone = ''
          this.password = ''
          // 标识 是 地方教材登录
          sessionStorage.setItem('isMaterialOrder', '1')
          this.$store.commit('updateLoginMethod', 1)
          this.$store.commit('updateUser', res.result.phone)
          this.$router.push('/material-order')
        })
        .catch((err) => {
          if (err.code === 201) {
            this.$confirm(
              '因平台升级，请您重新注册。感谢老客户的支持！',
              '提示',
              {
                confirmButtonText: '注册',
                cancelButtonText: '取消',
              }
            )
              .then(() => {
                this.password = ''
                this.$emit('update:showWindow', 2)
                this.showWindow = 2
              })
              .catch(() => {})
          } else if (err.code === 202) {
            this.$confirm(
              '您当前的登录密码强度过弱，请先修改密码后再进行登录！',
              '提示',
              {
                confirmButtonText: '修改密码',
                cancelButtonText: '取消',
              }
            )
              .then(() => {
                this.password = ''
                this.$emit('update:showWindow', 3)
                this.showWindow = 3
              })
              .catch(() => {})
          }
        })
    },
    // 验证表单
    checkData: function (e) {
      const { phone, ifPass, $store, msgCode, password, $message } = this
      if (!phone) {
        $message.error('请输入手机号')
        return false
      }
      if (!$store.state.regPhone.test(phone)) {
        $message.error('请输入正确的手机号')
        return false
      }

      // 弱密码验证
      if (
        this.showWindow != 1 &&
        !this.$utils.validatePassword(this.password)
      ) {
        this.$confirm(
          '您的密码过于简单，为了您的账号安全，请设置至少8位的数字+字母组合的密码。',
          '提示',
          {
            confirmButtonText: '我知道了',
            cancelButtonText: '取消',
          }
        )
          .then(() => {})
          .catch(() => {})
        return false
      }

      if (e) {
        if (!ifPass) {
          $message.error('请滑动验证')
          return false
        }
        if (!msgCode) {
          $message.error('请输入验证码')
          return false
        }
        return true
      }
      if (!password) {
        $message.error('请输入密码')
        return false
      }
      return true
    },
    // 点击修改密码
    modlog: function () {
      if (this.checkData(1)) {
        const word = JSON.stringify({
          phone: that.phone,
          captcha: that.msgCode,
          password: that.password,
          user_type: 2, // 用户类型。固定值2
        })

        // 生成随机16位的iv字符串
        const iv = this.$utils.generateRandomString()

        that
          .$http({
            url: 'edit_pwd',
            showLoading: true,
            data: {
              iv: iv,
              data: this.$encrypt(word, this.$aesKey, iv),
              //   phone: that.phone,
              //   captcha: that.msgCode,
              //   password: that.password,
              //   user_type: 2, // 用户类型。固定值2
            },
          })
          .then((res) => {
            that.$message({ type: 'success', message: '修改成功' })
            that.login()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
  },
}
</script>

<style scoped>
.place-login {
  min-height: calc(100vh - 130px);
  position: relative;
  width: 100%;
  padding-top: 160px;
  padding-bottom: 120px;
}
.place-login .window-box {
  position: relative;
  left: 0;
  top: 0;
  margin: 0 auto;
  padding: 24px 20px;
}
.window-box {
  overflow: hidden;
  padding: 0;
  border-radius: 6px;
  background: url(../assets/images/logwindow.jpg) no-repeat;
  background-position: top;
}
.window.on .window-box {
  width: 750px;
  padding: 28px 25px;
}
.window_con {
  width: 100%;
  position: relative;
  background: #fff;
  padding: 55px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
}
.window_con .window_title {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: rgb(51, 51, 51);
  line-height: 2;
  margin-bottom: 30px;
}
.window_con .window_close {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  font-size: 24px;
  color: #aaaaaa;
  padding: 10px;
}
.window_con .window_bgone {
  position: absolute;
  top: 0;
  left: 0;
}
.window_con .window_bgtwo {
  position: absolute;
  bottom: 0;
  right: 0;
}
.window_con .window_form {
  width: 100%;
  z-index: 100;
  position: relative;
}
.window_con .window_form > p {
  width: 100%;
  height: 60px;
  background: rgb(249, 249, 249);
  border-radius: 6px;
  margin-bottom: 15px;
}
.window_con .window_form > p i {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
  color: #aaaaaa;
  font-size: 24px;
}
.window_con .window_form > p .iconpassword {
  font-size: 26px;
}
.window_con .window_form > p input {
  display: inline-block;
  background: rgb(249, 249, 249);
  width: 500px;
  border: none;
  vertical-align: middle;
  line-height: 40px;
  font-size: 18px;
  height: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.window_con .window_form .window_btnbox {
  padding: 25px 20px 0;
}
.window_con .window_form > span {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.window_con .window_form .forbtn {
  display: inline-block;
  width: 70px;
  text-align: right;
  color: #999;
  font-size: 14px;
  cursor: pointer;
}
.window_con .window_form .forbtn:hover {
  color: rgb(63, 129, 210);
}
.window_con .window_form .logbtn,
.regbtn {
  display: inline-block;
  text-align: center;
  line-height: 60px;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
}
.window_con .window_form .regbox {
  display: inline-block;
  width: 260px;
  height: 60px;
  border-radius: 30px;
}
.window_con .window_form .regbox .regbtn {
  line-height: 60px;
}
.window_con .window_form .window_shortp {
  width: 65%;
  position: relative;
}
.window_con .window_form .window_shortp input {
  width: 300px;
}
.window_con .window_form .window_shortp .window_require {
  position: absolute;
  right: -205px;
  top: 0;
  width: 185px;
  height: 60px;
  background: rgb(63, 122, 210);
  background: linear-gradient(
    -90deg,
    rgb(63, 122, 210) 0%,
    rgb(63, 166, 210) 100%
  );
  border-radius: 6px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  line-height: 60px;
  cursor: pointer;
}
.window_con .window_form .window_shortp .window_require:hover {
  color: rgb(14, 66, 144);
}
.window_con .window_form .window_shortp #verifyImg {
  display: inline-block;
  position: absolute;
  right: -205px;
  cursor: pointer;
  width: 185px;
  height: 60px;
  border: none;
  margin: 0;
}
.window_con .window_form #regsubbtn,
#msgbtn,
#addbtn,
#or-btn {
  display: block;
  width: 260px;
  height: 60px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  margin: 0 auto;
}
.window_con .window_form .modbtn {
  display: block;
  margin: 0 auto;
}
.window_con .window_title span {
  font-weight: bold;
}
.silde-box {
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
  background: rgb(249, 249, 249);
  border-radius: 6px;
  position: relative;
  text-align: center;
}
.silde-box > span {
  color: #333333;
  font-size: 16px;
  line-height: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  z-index: 20;
}
.silde-box > span.on {
  color: #ffffff;
}
.silde-box .slide-btn {
  width: 52px;
  position: absolute;
  left: 4px;
  top: 4px;
  height: 52px;
  text-align: center;
  background: #ffffff;
  cursor: pointer;
  z-index: 50;
}
.silde-box .slide-btn i {
  font-size: 24px;
  line-height: 52px;
  color: #afafaf;
}
.silde-box .slide-bg {
  background: #88d267;
  height: 100%;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  z-index: 10;
}
.silde-box .slide-btn.on i {
  color: #55a532;
}
</style>
