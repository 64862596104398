<template>
	<div class="select-grade" v-if="gradeList.length > 0">
		<div class="showbox" v-if="showList">
			<div :class="{'on':current == 0}" @click="selectItem(0)">全部</div>
			<div @click="selectItem(item.id)" :class="{'on':item.id == current}" v-for="(item,index) in gradeList" :key="index">{{item.name}}</div>
			<div class="reback" @click="clickback()">
				<i class="el-icon-caret-left"></i>
			</div>
		</div>
		<div class="hidebox" v-else @click="clickback()">
			<i class="el-icon-caret-right"></i>年级筛选
		</div>
	</div>
</template>

<script>
	export default {
		props:['gradeList','gradeId'],
		data() {
			return {
				showList:true,					// 展示筛选
				current:'kong'					// 当前选项
			}
		},
		watch:{
			gradeId:function(newVal,oldVal){
				this.current = newVal
			}
		},
		mounted() {
			this.current = this.gradeId
		},
		methods:{
			// ==============================================================================
			// 点击收起
			// ==============================================================================
			clickback:function () {
				this.showList = !this.showList;
			},
			
			// ==============================================================================
			// 选择此项
			// ==============================================================================
			selectItem:function (id) {
				sessionStorage['selectGrade'] = 1;
				this.current = id;
				this.$emit('requestList',this.current);
			}
		}
	}
</script>

<style scoped>
.select-grade{position: fixed;left: 3px;top: 185px;overflow: hidden;border-radius: 8px;}
.select-grade i{font-size: 20px;color: #FFFFFF;}
.select-grade>div{color: #333333; width: 1.4rem;text-align: center;line-height: 2.6;cursor: pointer;max-height: 375px;overflow: auto;}
.select-grade>div div{color: #FFFFFF;background: #017eff;}
.select-grade .on{background: #f78051;}
.select-grade .reback{display: inline-block;width: 100%;}
.reback i,.reback span{display: inline-block;vertical-align: middle;}
.select-grade .hidebox{width: 25px;text-align: center;line-height: 1.8;cursor: pointer;padding: 10px 0;background: linear-gradient(rgb(63,129,210) 0%,rgb(136,210,103));color: #FFFFFF;}
</style>
