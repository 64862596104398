import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js'
import iePlaceholder from './assets/js/placeholder.js'
import util from './assets/js/util.js'
import { baseURL, http } from './assets/js/http.js'
import {
  Loading,
  Message,
  Notification,
  InputNumber,
  Select,
  Option,
  Input,
  Pagination,
  MessageBox,
  Autocomplete,
  Checkbox,
} from 'element-ui'
import { encrypt, decrypt } from './assets/js/crypto.js'

Vue.config.productionTip = false
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading
Vue.prototype.$notify = Notification
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$utils = util
Vue.prototype.$baseUrl = baseURL
Vue.prototype.$http = http
Vue.prototype.$encrypt = encrypt
Vue.prototype.$decrypt = decrypt
Vue.prototype.$aesKey = process.env.VUE_APP_KEY
Vue.prototype.$aesIv = process.env.VUE_APP_IV

Vue.use(iePlaceholder)
  .use(InputNumber)
  .use(Select)
  .use(Option)
  .use(Input)
  .use(Pagination)
  .use(Checkbox)
  .use(Autocomplete)

import '@/assets/css/common.css' /*引入公共样式*/
import '@/assets/iconfont/iconfont.css' /*引入iconfont*/
import 'element-ui/lib/theme-chalk/index.css' /*引入element*/

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
