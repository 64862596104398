<template>
	<div class="invoice" :style="{minHeight:$store.state.clientHeight}">
		<div class="page-box">
			<!-- 标题部分 -->
			<div class="page-title clear">
				<div class="page-icon fl">
					<i class="iconfont iconfapiao"></i>
					<span>开具发票</span>
				</div>
				<div class="page-return fr" @click="$router.go(-1);">
					<i class="am iconfont iconzhiyuanfanhui3"></i>
					<span class="am">返回上一步</span>
				</div>
			</div>
			<div class="invoice-container">
				<!-- 开票导航 -->
				<div class="invoice-guide clear">
					<div class="item active">选择开票商品及数量</div>
					<div class="item active">填写开票信息</div>
					<div class="item active">完成开票申请</div>
				</div>
				<div class="invoice-success">
					<div class="success-img"><i class="iconfont iconsuccess"></i></div>
					<span>已完成开票申请</span>
					<p>可在 <router-link :to="{name:'orders'}">订单-查看发票</router-link> 中查看发票进度</p>
					<router-link class="invoice-btn" :to="{ name: $store.state.isMaterialOrder ? 'material-order' : 'orders'}">前往订单页</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	let that;
	export default {
		data:()=>{
			return {
			}
		},
		created() {
			that = this;
		},
		methods:{
			
		}
	}
</script>

<style>
	.invoice{padding-top: 90px;}
	.invoice-container{width: 900px;margin: 30px auto;}
	.invoice-container .invoice-guide{width: 100%;overflow: hidden;}
	.invoice-container .invoice-guide .item{float: left;position: relative;width: 33.33%;color: #3a3a3a;font-size: .16rem;text-align: center;background: #EEEEEE;height: 56px;line-height: 56px;}
	.invoice-container .invoice-guide .item.active{background-color: #3f7ad2;background: linear-gradient(90deg,#3fa6d2,#3f7ad2);color: #FFFFFF;}
	.invoice-container .invoice-guide .item:before{content: "";width: 56px;height: 56px;position: absolute;top: 0;right: -56px;z-index: 10;border-width: 28px;border-style: solid;box-sizing: border-box;border-color: rgba(0, 0, 0, 0);border-left-color: #EEEEEE;}
	.invoice-container .invoice-guide .item:after{content: "";width: 60px;height: 60px;position: absolute;top: -2px;right: -61px;z-index: 5;border-width: 30px;border-style: solid;box-sizing: border-box;border-color: rgba(0, 0, 0, 0);border-left-color: #FFFFFF;}
	.invoice-container .invoice-guide .item.active:before{border-left-color: #3f7ad2;}
	.invoice-success{text-align: center;width: 100%;}
	.invoice-success .success-img{display: block;color: #FFFFFF;width: 64px;height: 64px;background-color: #71C972;border-radius: 50%;text-align: center;line-height: 64px;margin: 100px auto 30px;}
	.invoice-success .success-img i{color: #FFFFFF;font-size: .35rem;}
	.invoice-success>span{color:#3d3d3d;}
	.invoice-success>p{color: #a1a1a1;margin-bottom: 40px;}
	.invoice-success>p a{color: #3B77D1;}
	.invoice-success>p a:hover{text-decoration: underline;}
	.invoice-container .confirm-btn{display: inline-block;display: inline-block;padding: 6px 17px;margin-bottom: 70px;border-radius: 2px;font-size: 14px;color: #FFFFFF;border: none;opacity: 1;background: #3f7ad2;background: linear-gradient(90deg,#3fa6d2,#3f7ad2);outline: none;transition: all .2s;cursor: pointer;}
	.invoice-container .confirm-btn:hover{opacity: .8;color: #FFFFFF;}
</style>
