<template>
  <div class="process">
    <div class="banner"><img :src="bannerImg" alt="" /></div>
    <div class="process-box">
      <div class="processtitle clear">
        <p>
          注：如有不明白，请咨询微信公众号 (山东教育社) 或电话咨询 (0531-51756788)（工作日时间）
        </p>
        <ul class="clear processnav">
          <li v-for="item in navList" :key="item.id" @click="jumpLoc(item.id)">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="processtop clear">
        <div class="processshu"></div>
        <div class="processname">
          <img :src="gwlc" alt="" />
          <p class="processbf">订阅流程</p>
          <p>SUBSCRIPTION PROCESS</p>
        </div>
        <div class="processwarn">
          <p>1. 请务必牢记注册密码。</p>
          <p>2. 已注册用户无需再注册，请直接登录。</p>
        </div>
      </div>
      <div class="processlist">
        <div class="processitem" id="processa">
          <p><img :src="one" /><span>注册/登录</span></p>
          <span
            ><b>① 注册 :</b
            >在网站的右上角，点击注册按钮，输入手机号、校验码、验证码、密码，点击下一步。</span
          >
          <span
            ><b>② 登录 :</b
            >在网站的右上角，点击登录按钮，输入手机号、密码，点击登录，登录完成。</span
          >
          <img :src="lc1" alt="" />
          <img :src="lc3" alt="" />
        </div>
        <div class="processitem" id="processb">
          <p><img :src="two" /><span>选择刊物</span></p>
          <span class="processaf">选择刊物方法：（如下图）</span>
          <span
            >①
            直接在搜索框中搜索您要购买的刊物名称。例如：《当代小学生》（低年级刊）</span
          >
          <span
            >②
            直接根据要购买刊物的分类，筛选购买。例如：小学目录--小学--刊物名称
            例如：《当代小学生》</span
          >
          <img :src="lc4" alt="" />
          <span class="processaf">订购一本刊物与多本刊物方法：（如下图）</span>
          <span
            >①
            刊物订购一本：直接选择要购买的刊物，点击立即购买，填写订单。</span
          >
          <span
            >②
            刊物订购多本：需要选择要购买的刊物，点击加入购物车，点击结算，填写订单。</span
          >
          <img :src="lc5" alt="" />
        </div>
        <div class="processitem" id="processc">
          <p><img :src="three" /><span>填写订单</span></p>
          <span class="processaf"
            >选择完刊物，点击“结算”，页面跳转“结算/选择或增加新收货地址”</span
          >
          <span
            >① 选择或增加新收货地址：
            刊物发送地址（地区、详细地址、学校、姓名、电话……）</span
          >
          <span>② 编辑刊物数量 ： 点击刊物描述中，数量模块的加减功能。</span>
          <img :src="lc6" alt="" />
          <img :src="lc7" alt="" />
        </div>
        <div class="processitem" id="processd">
          <p><img :src="four" /><span>确认订单</span></p>
          <span>确认订单：刊物名称、数量、单价、总价。“点击支付”</span>
          <img :src="lc7a" alt="" />
        </div>
        <div class="processitem" id="processe">
          <p><img :src="five" /><span>完成付款，支付</span></p>
          <span class="processaf"
            >微信扫码支付。已经开通网上支付，通过微信扫码，可直接付款。</span
          >
          <img :src="lc88" alt="" />
          <span class="processaf"
            >其他支付，分为扫码支付或银联支付(以建行为例)</span
          >
          <span class="processaf"
            >① 已经开通网上支付，通过支付宝或微信扫码，直接付款。</span
          >
          <img :src="lc8" alt="" />
          <span class="processaf"
            >② 银联支付（以建行为例,选择中国建设银行，点击下一步）</span
          >
          <span
            >方法一：网上银行支付：输入证件号与登录密码，点击下一步进行支付。使用该支付方式时，需要到银行柜台开通网上银行业务</span
          >
          <span
            >方法二
            ：账号支付：账号支付属于快捷支付，无需注册网上银行。选择账号支付，输入银行卡号，与附加码，点击下一步。输入手机号的后4位，就能完成支付。推荐支付方式，简单便捷</span
          >
          <img :src="lc9" alt="" />
        </div>
      </div>
      <div class="processtop clear" id="processf">
        <div class="processshu"></div>
        <div class="processname">
          <img :src="tklc" alt="" />
          <p class="processbf">退款说明</p>
          <p>REFUND INSTRUCTIONS</p>
        </div>
        <div class="processtklist clear">
          <div class="processtkitem">
            <img :src="tk1" alt="" class="processtkimg" />
            <p><img :src="one" alt="" /><span>查看订单</span></p>
            <span
              >鼠标放在区域①，下拉内容，点击“查看订单”，进入查看订单页面。</span
            >
          </div>
          <div class="processtkitem">
            <div style="width: 100%" class="clear">
              <img :src="tk2" alt="" class="processtkimg" />
              <img :src="tk3" alt="" class="processtkimg" />
            </div>
            <p><img :src="two" alt="" /><span>申请退款</span></p>
            <span
              >点击“已支付订单”，在需要退款的订单右上角，点击“申请退款”，弹窗提示选择退款原因。</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gwlc from '@/assets/images/gwlc.png'
import one from '@/assets/images/one.png'
import two from '@/assets/images/two.png'
import three from '@/assets/images/three.png'
import four from '@/assets/images/four.png'
import five from '@/assets/images/five.png'
import lc1 from '@/assets/images/lc1.jpg'
import lc3 from '@/assets/images/lc3.png'
import lc4 from '@/assets/images/lc4.png'
import lc5 from '@/assets/images/lc5.png'
import lc6 from '@/assets/images/lc6.png'
import lc7 from '@/assets/images/lc7.png'
import lc7a from '@/assets/images/lc7a.png'
import lc8 from '@/assets/images/lc8.png'
import lc9 from '@/assets/images/lc9.jpg'
import lc88 from '@/assets/images/lc88.png'
import tklc from '@/assets/images/tklc.png'
import tk1 from '@/assets/images/tk1.png'
import tk2 from '@/assets/images/tk2.png'
import tk3 from '@/assets/images/tk3.png'
import bannerImg from '@/assets/images/banner.jpg'

export default {
  name: 'process',
  data: () => {
    return {
      gwlc,
      one,
      two,
      three,
      four,
      five,
      lc1,
      lc3,
      lc4,
      lc5,
      lc6,
      lc7,
      lc7a,
      lc8,
      lc88,
      lc9,
      tklc,
      tk1,
      tk2,
      tk3,
      bannerImg: bannerImg,
      navList: [
        {
          id: 'a',
          name: '①注册/登录',
        },
        {
          id: 'b',
          name: '②选择刊物',
        },
        {
          id: 'c',
          name: '③填写订单',
        },
        {
          id: 'd',
          name: '④确认订单',
        },
        {
          id: 'e',
          name: '⑤完成付款',
        },
        {
          id: 'f',
          name: '退款说明',
        },
      ],
    }
  },
  created() {},
  methods: {
    jumpLoc: function (e) {
      let targetbox = document.querySelector('#process' + e).offsetTop - 200
      let top = 0
      document.documentElement.scrollTop = targetbox
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top +=
            100
        if (top >= targetbox) {
          clearInterval(timeTop)
        }
      }, 10)
    },
  },
}
</script>

<style scoped>
.process {
  width: 100%;
}
.process-box {
  max-width: 1200px;
  min-width: 1000px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.processtitle {
  width: 100%;
  padding-top: 30px;
  text-align: center;
}
.processtitle p {
  display: right;
  text-align: right;
  font-size: 16px;
  margin-bottom: 10px;
}
.processtitle ul {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  margin-top: 10px;
}
.processtitle ul li {
  float: left;
  margin: 0 4% 0 0;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}
.processtitle ul li:hover {
  color: #3b77d1;
  border-bottom: 1px solid #3b77d1;
}
.processtop {
  width: 100%;
}
.processtop .processshu {
  width: 6px;
  height: 110px;
  background: #3b77d1;
  float: left;
  margin-right: 10px;
}
.processtop .processname {
  float: left;
  margin-bottom: 15px;
}
.processtop .processname .processbf {
  font-size: 26px;
  color: #333;
  font-weight: bold;
  line-height: 1.4;
}
.processtop .processname img {
  width: 50px;
  margin-top: 5px;
}
.processtop .processname p {
  color: #333;
}
.processtop .processwarn {
  float: left;
  margin: 10px 0;
  width: 100%;
  border: 1px solid #ddd;
  background: #f4f4f4;
  padding: 10px 20px;
}
.processtop .processwarn p {
  font-size: 18px;
  color: #333;
}
.processitem {
  width: 100%;
  margin-top: 35px;
}
.processitem p {
  color: #333;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
.processitem p img {
  display: inline-block;
  width: 50px;
  vertical-align: middle;
  border: none;
  margin: 0 5px 0 0;
}
.processitem p span {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
}
.processitem span {
  display: block;
  text-align: left;
  font-size: 18px;
  line-height: 2;
}
.processitem img {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
}
.processitem .processaf {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.processtk {
  margin-top: 50px;
}
.processtklist {
  display: block;
  width: 100%;
}
.processtklist .processtkitem:last-child {
  width: 66%;
  float: left;
  margin-left: 3%;
}
.processtklist .processtkitem:first-child {
  margin: 0;
  width: 31%;
  float: left;
}
.processtklist .processtkitem .processtkimg {
  width: 100%;
  margin-bottom: 20px;
}
.processtklist .processtkitem div {
  height: 245px;
}
.processtklist .processtkitem .processtkimg {
  height: 245px;
}
.processtklist .processtkitem div .processtkimg:first-child {
  width: 48%;
  float: left;
  height: 245px;
}
.processtklist .processtkitem div .processtkimg:last-child {
  width: 48%;
  float: right;
  height: 245px;
}
.processtklist .processtkitem p {
  margin-bottom: 10px;
}
.processtklist .processtkitem p span {
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}
.processtklist .processtkitem p img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 50px;
}
.processtklist .processtkitem span {
  font-size: 18px;
  color: #333;
}
</style>
