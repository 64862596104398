<!-- 订单页面 -->
<template>
    <div class="orders" :style="{ minHeight: $store.state.clientHeight }">
        <!-- <div class="banner"><img :src="bannerImg" alt="" /></div> -->
        <div class="page-box">
            <!-- ######################################################################################################################## -->
            <!-- 页面标题 开始 -->
            <div class="page-title clear">
                <div class="page-icon fl">
                    <i class="iconfont icondingdan"></i>
                    <span>查看订单</span>
                </div>
            </div>
            <!-- 页面标题 结束 -->
            <!-- ######################################################################################################################## -->

            <!-- ######################################################################################################################## -->
            <!-- 订单分类 开始 -->
            <div class="order-cate clear">
                <p class="line"></p>
                <span
                    :class="[{ active: item.id == curType }]"
                    v-for="(item, index) in cateList"
                    :key="index"
                    @click="selectType(item.id)"
                    >{{ item.text }}</span
                >
            </div>
            <!-- 订单分类 结束 -->
            <!-- ######################################################################################################################## -->

            <!-- ######################################################################################################################## -->
            <!-- 订单列表 开始 -->
            <div class="order-list">
                <div class="list-container" v-for="(item, index) in listData" :key="index">
                    <!-- 列标题 -->
                    <div class="list-header clear">
                        <span :class="['w30', { w35: curType != 1 }]">刊物名称</span>
                        <span :class="['w30', { w35: curType != 1 }]">刊物说明</span>
                        <span :class="['w125', { w15: curType != 1 }]">数量</span>
                        <span :class="['w125', { w15: curType != 1 }]">价格</span>
                        <span class="w15" v-if="curType == 1">管理</span>
                    </div>

                    <!-- 列数据 -->
                    <div class="list-data" v-if="item">
                        <div class="list-mainer">
                            <!-- 书籍列表 -->
                            <div
                                class="list-item clear"
                                v-show="item.books"
                                v-for="book in item.books"
                                :key="book.book_no + book.order_no"
                            >
                                <div :class="['item-detail', 'w30', { w35: curType != 1 }]">
                                    <!-- 图片 -->
                                    <div class="item-img">
                                        <img :src="book.picture" :alt="book.name" :title="book.name" />
                                    </div>
                                    <div class="item-msg order-msg">
                                        <!-- 大标题 -->
                                        <p>{{ book.name }}</p>
                                        <span :title="book.press">{{ book.press }}</span>
                                        <div>
                                            <span
                                                class="item-type"
                                                v-for="(type, index) in book.book_type"
                                                :key="index"
                                                >{{ type }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div :class="['item-text', 'w30', { w35: curType != 1 }]">
                                    <span>{{ book.apply_explain }}</span>
                                </div>
                                <div :class="['item-price', 'w125', { w15: curType != 1 }]">
                                    <span>{{ book.count }}</span>
                                </div>
                                <div :class="['item-allPrice', 'w125', { w15: curType != 1 }]">
                                    <div class="item-price-box">
                                        <!-- 历史订单单价不同 -->
                                        <span v-if="curType == 3">单价：{{ book.half_year_price }}元</span>
                                        <span v-else>单价：{{ book.unit_price }}元/期</span>
                                        <span>[{{ book.frequency_name }}/共{{ book.frequency_periods }}期]</span>
                                        <p>
                                            总价：<span>{{ book.total_price }}元</span>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="item-change w15"
                                    v-if="curType == 1 && item.status == 10 && item.is_offline == 0"
                                >
                                    <span @click="changeBook(book.book_no, item.order_no)" class="am">更换书籍</span>
                                </div>
                            </div>

                            <!-- 收货信息 -->
                            <div class="order-detail clear">
                                <div class="fl">
                                    <div class="detail-address">
                                        <span>姓名：{{ item.address.name }}</span>
                                        <span>电话：{{ item.address.phone }}</span>
                                        <span>学校：{{ item.address.school }}</span>
                                        <!-- <span>{{ item.address.grade_name ? '年级：' + item.address.grade_name : '' }}</span> -->
                                        <!-- <span>{{ item.address.classes_name ? '班级：' + item.address.classes_name : '' }}</span> -->
                                        <span v-show="item.address.grade_name">{{
                                            item.address.grade_name
                                                ? "年级：" + item.address.grade_name + item.address.classes_name
                                                : ""
                                        }}</span>
                                        <span>批次：{{ item.batch_name }}</span>
                                    </div>
                                    <span
                                        v-if="(item.status == 10 && item.is_offline == 0) || item.status == 0"
                                        class="add-address am"
                                        @click="editAddress(item)"
                                        >修改地址</span
                                    >
                                </div>
                                <div class="fr">
                                    <p>
                                        您已选择购买<span> {{ item.total_count }} </span>套刊物
                                    </p>
                                    <div>
                                        <p class="clear">
                                            <span class="fl">订阅刊物费：</span
                                            ><span class="fr">{{ parseFloat(item.total_price).toFixed(2) }}元</span>
                                        </p>
                                        <p class="clear">
                                            <span class="fl">运费：</span
                                            ><span class="fr">{{ freight.toFixed(2) }}元</span>
                                        </p>
                                        <div class="clear">
                                            <span class="fl">合计总额：</span
                                            ><b class="fr"
                                                >{{ (parseFloat(item.total_price) + freight).toFixed(2) }}元</b
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 底部状态 -->
                            <div class="order-state clear">
                                <!-- 订单信息 -->
                                <p class="fl">
                                    订单号：{{ item.order_no }} &nbsp;&nbsp;&nbsp; 日期：{{
                                        formatDate(item.create_time)
                                    }}
                                    &nbsp;&nbsp;&nbsp; 支付状态：<span>{{ calcPaymentStatusText(item) }}</span>
                                    <template v-if="calcShipmentStatusText(item)">
                                        &nbsp;&nbsp;&nbsp; 发货状态：<span>{{ calcShipmentStatusText(item) }}</span>
                                    </template>
                                </p>
                                <!-- 已支付订单显示的操作按钮 -->
                                <div
                                    class="fr invoice-box"
                                    v-if="curType == 1 && item.status != 40 && item.status != 41"
                                >
                                    <span @click="checkInvoice(item)" v-if="item.status == 10 || item.status == 20"
                                        >查看电子发票</span
                                    >
                                    <span @click="applyInvoice(item)" v-if="item.status == 10 || item.status == 20"
                                        >申请电子发票</span
                                    >
                                    <span @click="toPrint(item)" v-if="item.status == 10 || item.status == 20"
                                        >支付订单打印</span
                                    >
                                    <span
                                        @click="toRefund(item.order_no)"
                                        v-if="item.status == 10 && item.is_offline == 0 && item.pay_type !== 3"
                                        >申请退款</span
                                    >
                                </div>
                                <!-- 未支付订单显示的操作按钮 -->
                                <div class="fr btn-box" v-if="curType == 2">
                                    <span class="closeOrder" @click="checkInvoice(item)" v-if="item.pay_mode == 2"
                                        >查看电子发票</span
                                    >
                                    <!--                  <span class="closeOrder" @click="applyInvoice(item)" v-if="item.pay_mode !== 2">申请电子发票</span>-->
                                    <span class="closeOrder" @click="cancelOrder(item.order_no, index)">取消订单</span>
                                    <!-- @click="payOrder(item.order_no,item.pay_type,item.pay_mode,item.invoice_over_status)" -->
                                    <span class="lingred" @click="popText">支付订单</span>
                                    <!-- <span
                    v-if="item.status === 10"
                    @click="toRefund(item.order_no)"
                    >申请退款</span
                  > -->
                                </div>
                                <!-- 历史订单显示的操作按钮 -->
                                <div
                                    class="fr invoice-box"
                                    v-if="curType == 3 && item.status != 40 && item.status != 41"
                                >
                                    <span @click="checkInvoice(item)" v-if="item.status == 10 || item.status == 20"
                                        >查看电子发票</span
                                    >
                                    <span @click="applyInvoice(item)" v-if="item.status == 10 || item.status == 20"
                                        >申请电子发票</span
                                    >
                                    <span @click="toPrint(item)">支付订单打印</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 新增加提示语弹窗 -->
                    <PromptText
                        v-show="showPro"
                        @conExe="payOrder(item.order_no, item.pay_type, item.pay_mode, item.invoice_over_status)"
                    ></PromptText>
                </div>
                <div class="nodata" v-if="noData">
                    <noData noDataText="暂无订单"></noData>
                </div>
                <!-- 分页组件 -->
                <el-pagination
                    v-if="!noData && curType != '2'"
                    background
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="changePageIndex"
                ></el-pagination>
            </div>
            <!-- 订单列表 结束 -->
            <!-- ######################################################################################################################## -->
        </div>
        <!-- 添加地址弹框 -->
        <transition name="fade">
            <AddressBox
                key="address"
                v-if="showAdress"
                :showBox="showAdress"
                :type="3"
                :orderNo="curOrder"
                @closeBox="closeAdress"
                @addAdress="addAdress"
            ></AddressBox>
            <CloseOrder
                key="close"
                v-if="showNoWant"
                :orderNo="curOrder"
                @closeWant="closeWant"
                @confirmNoWant="confirmNoWant"
            ></CloseOrder>
        </transition>

        <!-- 支付弹框 -->
        <div class="bank-container" v-if="showPaySelect">
            <div class="pay-select">
                <i class="window-close iconfont iconclose" @click="showPaySelect = false"></i>
                <span>请选择您的支付方式</span>
                <!--        <div class="btn-box" v-if="showPayType == 1">-->
                <div class="btn-box" v-if="isPublicPay || showPayType == 1">
                    <div class="bank-btn lingred" @click="wxPay()">微信支付</div>
                    <div v-if="official == 1" class="bank-btn lingred" @click="officialCardPay()">公务卡支付</div>
                </div>
                <div class="btn-box" v-else>
                    <div class="bank-btn lingred" @click="bankPay(1)">扫码支付</div>
                    <!--					<div v-if='!isPublicPay' class="bank-btn lingred" @click="bankPay(2)">银联支付</div>-->
                    <div class="bank-btn lingred" @click="bankPay(2)">银联支付</div>
                    <div v-if="official == 1" class="bank-btn lingred" @click="officialCardPay()">公务卡支付</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bookImg from "@/assets/images/1.png";
    import AddressBox from "@/components/AddressBox.vue";
    import CloseOrder from "@/components/CloseOrder.vue";
    import noData from "@/components/noData.vue";
    import PromptText from "@/components/PromptText.vue";
    let that;
    export default {
        name: "orders",
        components: {
            AddressBox,
            CloseOrder,
            noData,
            PromptText, //新增加文字描述
        },
        data: () => {
            return {
                // 订单分类tabs
                cateList: [
                    { id: "2", text: "未支付订单" },
                    { id: "1", text: "已支付订单" },
                    { id: "3", text: "历史订单" },
                ],
                curType: "1", // 当前订单类型。1: 已支付订单; 2: 未支付订单; 3: 历史订单
                pageIndex: 1, // 当前页数
                city: sessionStorage["city"] || "", // 当前城市
                official: sessionStorage["official"] || "", // 当前城市 是否可以公务卡支付
                pageSize: 10, // 每页数据条数
                total: 0, // 数据总条数
                listData: [], // 数据列表
                freight: 0, // 运费
                address: {}, // 地址列表
                showAdress: false, // 展示添加地址弹框
                showNoWant: false, // 展示不想要了弹框
                curOrder: "", // 当前订单
                noData: false, // 暂无订单
                bannerImg: require("@/assets/images/banner.jpg"), // banner图片
                showPaySelect: false, // 展示支付选择弹框
                orderId: "", // 支付订单ID
                showPayType: "", // 支付弹框中的支付类型
                isPublicPay: false, // 支付弹框中是否是公务卡支付
                isOpenCard: false, // 公务卡 是否 开完票

                showPro: false, // 新增显示文字
            };
        },
        created() {
            that = this;
            this.curType = sessionStorage["orderType"] || "2";
            // 查询订单列表
            this.queryOrders();
            this.$store.commit("updateH", 130);
        },
        destroyed() {
            sessionStorage["orderType"] = "";
        },
        mounted() {},
        methods: {
            /**
             * ####################################################################################################
             * ## 切换订单分类
             * ## id: 分类id
             * ####################################################################################################
             */
            selectType: function (id) {
                // ########## 2021秋季征订兼容历史数据版本 Del ########## //
                // 更新订单分类id
                // if (id != '3' || (sessionStorage['cityName'] != '淄博市' && sessionStorage['cityName'] != '枣庄市' && sessionStorage['cityName'] != '东营市' && sessionStorage['cityName'] != '济南市')) {
                // 	this.curType = id;
                // 	this.$route.query.type = id || '1';
                // 	sessionStorage['orderType'] = id;
                // 	this.total = 0;
                // 	this.listData = [];
                // 	this.pageIndex = 1;
                // 	this.queryOrders();
                // } else {
                // 	this.$http({
                // 		url:'passport/old_version_url',
                // 		method: 'GET',
                // 		params: {
                // 			city:sessionStorage['cityName'],
                // 			xz_type:sessionStorage['xzType'],
                // 			type:'pc'
                // 		}
                // 	}).then(res => {
                // 		window.open(res.result.url,'_blank');
                // 	}).catch(err => {
                // 		console.log(err);
                // 	})
                // }

                // ########## 2021秋季征订历史数据整合完后的版本 Add ########## //
                this.curType = id;
                this.$route.query.type = id || "1";
                sessionStorage["orderType"] = id;
                this.total = 0;
                this.listData = [];
                this.pageIndex = 1;
                this.queryOrders();
            },

            /**
             * ****************************************************************************************************
             * ** 支付订单提示弹窗
             * ****************************************************************************************************
             */
            popText: function () {
                that.showPro = true;
            },

            /**
             * ####################################################################################################
             * ## 切换页数
             * ## pageIndex: 切换的页数
             * ####################################################################################################
             */
            changePageIndex: function (pageIndex) {
                this.pageIndex = pageIndex;
                this.queryOrders();
            },

            /**
             * ####################################################################################################
             * ## 格式化时间戳
             * ## timestamp: 时间戳，PHP格式的，表示的是秒数，不是js的毫秒数
             * ####################################################################################################
             */
            formatDate: function (timestamp) {
                let dt = new Date(timestamp * 1000);
                let year = dt.getFullYear();
                let month = dt.getMonth() + 1 < 10 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1;
                let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
                let hour = dt.getHours();
                let minute = dt.getMinutes();
                let second = dt.getSeconds();
                return `${year}-${month}-${day}`;
            },

            // 点击更换书籍
            changeBook: function (book_no, order_no) {
                this.$router.push({
                    path: "/booklist",
                    query: {
                        order_no: order_no,
                        book_no: book_no,
                        type: that.curType,
                        navId: "6",
                    },
                });
            },

            // 关闭填写地址
            closeAdress: function () {
                this.showAdress = false;
            },

            // 添加地址
            addAdress: function () {
                this.showAdress = false;
                this.pageIndex = 1;
                this.queryOrders();
            },

            // 去打印
            toPrint: function (order) {
                let time = this.$utils.timeChange(order.create_time * 1000);
                let printObj = Object.assign({}, order);
                printObj.create_time = time;
                sessionStorage["orderPrintData"] = JSON.stringify(printObj);
                let routeData = this.$router.resolve({
                    path: "/print",
                });
                window.open(routeData.href, "_blank");
            },

            // 点击退款
            toRefund: function (e) {
                this.curOrder = e;
                this.showNoWant = true;
            },

            // 关闭不想要了弹框
            closeWant: function () {
                this.showNoWant = false;
            },

            // 点击退款订单原因
            confirmNoWant: function (e) {
                // 只有已支付订单可以退款
                this.showNoWant = false;
                this.total = 0;
                this.listData = [];
                this.pageIndex = 1;
                this.queryOrders("1");
            },

            // 点击修改地址
            editAddress: function (e) {
                this.showAdress = true;
                this.curOrder = e;
            },

            // 取消订单
            cancelOrder: function (e, i) {
                this.$http({
                    url: "order/cancel_order",
                    data: {
                        order_no: e,
                    },
                })
                    .then((res) => {
                        // console.log(res);
                        that.$message({
                            message: res.msg,
                            type: "success",
                            duration: 1000,
                        });
                        that.listData.splice(i, 1);
                        if (that.listData.length == 0) {
                            that.noData = true;
                        } else {
                            that.noData = false;
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },

            // 点击支付订单
            payOrder: function (e, pay_type, pay_mode, invoice_over_status) {
                this.showPro = false;
                this.showPayType = pay_type;
                this.orderId = e;
                this.isPublicPay = pay_mode === 2;

                if (pay_mode == 1) {
                    this.showPaySelect = true;
                } else {
                    // 公务卡 未开完 票
                    // pay_mode: 付款方式
                    if (pay_mode == 2 && invoice_over_status === 0) {
                        this.showPaySelect = true;
                        // this.isOpenCard = true
                        return;
                    }
                    // 公务卡 开完 票
                    if (pay_mode == 2 && invoice_over_status === 1) {
                        // 微信支付
                        this.wxPay();
                        return;
                    }

                    //pay_type： 1 微信支付； 2：建行支付  3: 後台收款
                    if (pay_type == 1) {
                        // 微信支付
                        this.wxPay();
                    } else {
                        this.showPaySelect = true;
                    }
                }
            },

            /**
             * ****************************************************************************************************
             * ** 微信支付
             * ****************************************************************************************************
             */
            wxPay(order_no, pay_type, pay_mode) {
                that.$http({
                    url: "order/pay_again",
                    showLoading: true,
                    data: {
                        order_no: this.orderId,
                        source: 3,
                    },
                })
                    .then((res) => {
                        let payObj = {
                            code: res.result.code_url,
                            orderId: res.result.order_no,
                            price: res.result.total_price,
                        };
                        sessionStorage["payCode"] = JSON.stringify(payObj);
                        this.$router.push({ path: "/wxPay" });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },

            /**
             * ****************************************************************************************************
             * ** 公务卡支付
             * ****************************************************************************************************
             */
            officialCardPay() {
                const { isPublicPay, $http, $router, isOpenCard } = this;
                $router.push({
                    path: "/businessCardBindingWechatOperationInstructions",
                    query: { order_no: this.orderId },
                });
                // if (isOpenCard){
                //   return
                // }
                // $http({
                // 	url:'order/official',
                // 	showLoading:true,
                // 	data:{
                // 		order_no: this.orderId,
                // 	}
                // }).then(res => {
                //   console.log('officialCardPay success');
                //   $router.push({
                //     path: '/businessCardBindingWechatOperationInstructions',
                //     query: { order_no:  this.orderId}
                //   });
                // }).catch(err => {
                // 	console.log(err);
                // })
            },

            // 建行支付
            bankPay: function (e) {
                that.$http({
                    url: "order/pay_again",
                    showLoading: true,
                    data: {
                        order_no: this.orderId,
                        source: e,
                    },
                })
                    .then((res) => {
                        window.open(res.result.url);
                        this.showPaySelect = false;
                    })
                    .catch((err) => {
                        this.showPaySelect = false;
                        console.error(err);
                    });
            },

            /**
             * ####################################################################################################
             * ## 查看电子发票
             * ## item: 订单数据
             * ####################################################################################################
             */
            checkInvoice: function (item) {
                this.$router.push({
                    path: "/invoiceIndex",
                    query: { order_no: item.order_no },
                });
            },

            /**
             * ####################################################################################################
             * ## 申请开票
             * ## item: 订单数据
             * ####################################################################################################
             */
            applyInvoice: function (item) {
                this.$router.push({
                    path: "/invoiceProcess",
                    query: { order_no: item.order_no },
                });
            },

            /**
             * ####################################################################################################
             * ## 查询订单列表
             * ####################################################################################################
             */
            queryOrders: function (e) {
                // 根据不同的类型请求不同的接口
                let apiUrl = "";
                if (this.curType == "1" || e == "1") {
                    apiUrl = "order/paid_order";
                } else if (this.curType == "2") {
                    apiUrl = "order/no_paid_order";
                } else if (this.curType == "3") {
                    apiUrl = "order/history_order";
                }
                that.$http({
                    url: apiUrl,
                    method: "GET",
                    params: {
                        page: this.pageIndex,
                        limit: this.pageSize,
                        xz_type: sessionStorage["xzType"],
                        city_id: sessionStorage["city"],
                    },
                })
                    .then((res) => {
                        if (res.result.length == 0 || res.result.length == 0) {
                            that.noData = true;
                        } else {
                            that.noData = false;
                            that.listData = res.result.rows;
                            that.total = res.result.count;
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },

            /**
             * @description: 计算显示的订单支付状态文本内容
             * @param {Object} order 订单数据
             * @return {String} 当前订单的支付状态文本内容
             */
            calcPaymentStatusText(order) {
                // item.status == 10 ? '已支付' : item.status == 20 ? '已发货' : item.status == 40 ? '退款中' : item.status == 41 ? '已退款' : '待付款'
                // 显示定义好的对应的发货状态文本
                if (order.status == 10) return "已支付";
                if (order.status == 20) return "已发货";
                if (order.status == 40) return "退款中";
                if (order.status == 41) return "已退款";
                return "待付款";
            },

            /**
             * @description: 计算显示的订单发货状态文本内容
             * @param {Object} order 订单数据
             * @return {String} 当前订单的发货状态文本内容
             */
            calcShipmentStatusText(order) {
                // 显示当前订单数据中的 batch_notes（自定义的发货状态文本）
                return order.batch_notes;
            },
        },
    };
</script>

<style scoped>
    .orders {
        padding-top: 90px;
    }
    .nodata {
        padding: 40px 0;
    }
    .list-container {
        border-bottom: 2px solid #eeeeee;
        padding-bottom: 20px;
    }
    .order-cate {
        width: 100%;
        padding-top: 15px;
        margin-bottom: 10px;
        position: relative;
    }
    .order-cate span {
        width: 190px;
        text-align: center;
        position: relative;
        font-size: 18px;
        line-height: 2.5;
        float: left;
        height: 48px;
        background: #ffffff;
        border-bottom: 2px solid #3b77d1;
        cursor: pointer;
    }
    .order-cate span.active {
        border-top: 2px solid #3b77d1;
        border-left: 2px solid #3b77d1;
        border-right: 2px solid #3b77d1;
        border-bottom: 2px solid #ffffff;
    }
    .order-cate .line {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #3b77d1;
        margin: 0;
    }
    .item-change {
        position: relative;
    }
    .item-change span {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -20px;
        margin-left: -50px;
        cursor: pointer;
        display: inline-block;
        width: 100px;
        height: 40px;
        border-radius: 20px;
        background: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        color: #666;
        border: 1px solid rgb(225, 225, 225);
    }
    .item-change span:hover {
        background: #3f7ad2;
        background: linear-gradient(90deg, #3fa6d2, #3f7ad2);
        color: #fff;
    }
    /* 地址信息 */
    .order-detail {
        width: 100%;
        background: rgb(240, 249, 253);
    }
    .order-detail > .fl {
        padding: 30px 40px;
        border-right: 1px solid #dddddd;
        width: 63%;
        position: relative;
    }
    .order-detail > .fl .detail-address {
        width: calc(100% - 240px);
    }
    .order-detail > .fl .detail-address span {
        display: inline-block;
        width: 100%;
        line-height: 2.5;
    }
    .order-detail > .fl .add-address {
        position: absolute;
        width: 135px;
        height: 45px;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(232, 232, 232);
        border-radius: 23px;
        text-align: center;
        line-height: 45px;
        top: 50%;
        right: 6%;
        margin-top: -23px;
        cursor: pointer;
        color: #3b77d1;
    }
    .order-detail > .fl .add-address:hover {
        background: #3f7ad2;
        background: linear-gradient(90deg, #3fa6d2, #3f7ad2);
        color: #ffffff;
    }
    .order-detail > .fr {
        padding: 30px 40px;
        width: 37%;
    }
    .order-detail > .fr > p {
        text-align: right;
        font-size: 0.18rem;
        color: #333;
        margin-bottom: 0.14rem;
        line-height: 2;
    }
    .order-detail > .fr > p span {
        color: #3b77d1;
        font-size: 0.26rem;
        font-weight: bold;
    }
    .order-detail > .fr > div {
        width: 100%;
    }
    .order-detail > .fr > div p span {
        font-size: 0.18rem;
        color: #888;
    }
    .order-detail > .fr > div div {
        margin-top: 0.2rem;
    }
    .order-detail > .fr > div div span {
        color: #333333;
    }
    .order-detail > .fr > div div b {
        font-size: 0.34rem;
        color: #3b77d1;
        font-weight: bold;
    }
    .item-totalPrice span {
        line-height: 160px;
        font-size: 20px;
        font-weight: bold;
        color: rgb(51, 51, 51);
        display: inline-block;
        text-align: center;
        width: 100%;
    }
    @media (max-width: 1100px) {
        .list-item .item-detail .item-msg.order-msg {
            width: 65%;
            margin-left: 4%;
        }
    }
    /* 底部状态 */
    .order-state {
        width: 100%;
        margin-top: 20px;
    }
    .order-state > p {
        color: #333;
        line-height: 20px;
        font-size: 16px;
        line-height: 2;
    }
    .order-state > p span {
        color: #3b77d1;
    }
    .order-state .btn-box .closeOrder {
        width: 120px;
        height: 36px;
        cursor: pointer;
        border-radius: 18px;
        line-height: 36px;
        font-weight: bold;
        display: inline-block;
        text-align: center;
        color: #999;
        background: #fff;
        border: 1px solid #eee;
        margin-right: 10px;
    }
    .order-state .btn-box .lingred {
        width: 120px;
        height: 36px;
        cursor: pointer;
        line-height: 36px;
        display: inline-block;
        text-align: center;
        border-radius: 30px;
        font-weight: bold;
    }
    .order-state .invoice-box {
        padding-right: 10px;
    }
    .order-state .invoice-box span {
        color: #3a3a3a;
        font-size: 0.18rem;
        font-weight: bold;
        padding-left: 0.2rem;
        position: relative;
        line-height: 2;
    }
    .order-state .invoice-box span:before {
        position: absolute;
        content: "";
        left: 8px;
        top: -5px;
        width: 1px;
        height: 25px;
        background: #ddd;
    }
    .order-state .invoice-box span:first-child::before {
        display: none;
    }
    .order-state .invoice-box span:hover {
        color: #9d5920;
        cursor: pointer;
    }
    .order-state .print {
        color: #3a3a3a;
        font-size: 0.18rem;
        font-weight: bold;
        cursor: pointer;
    }
    .order-state .print:hover {
        color: #9d5920;
    }
    .el-pagination {
        text-align: center;
        margin-top: 30px;
    }
    .orders .list-item .item-detail {
        cursor: default;
    }
    .orders .list-item .item-detail .item-img {
        width: 16%;
        margin-left: 3%;
    }
    .orders .item-allPrice {
        position: relative;
    }
    .orders .item-allPrice .item-price-box {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
    .orders .item-allPrice .item-price-box > span {
        color: #777777;
        font-size: 14px;
        font-weight: 500;
    }
    .orders .item-allPrice .item-price-box > p {
        text-align: center;
    }
    .orders .item-allPrice .item-price-box > p span {
        display: inline-block;
        width: auto;
        font-size: 18px;
    }

    /* 新增建行支付 */
    .bank-container {
        position: fixed;
        z-index: 100;
        background: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .pay-select {
        width: 400px;
        left: 50%;
        margin-left: -200px;
        top: 50%;
        margin-top: -100px;
        background: #ffffff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
        padding: 35px 25px;
        overflow: hidden;
        border-radius: 8px;
        position: absolute;
    }
    .pay-select > span {
        width: 100%;
        display: inline-block;
        text-align: center;
        font-size: 18px;
        color: #333333;
    }
    .pay-select > i {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 22px;
        cursor: pointer;
    }
    .pay-select .btn-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }
    .pay-select .btn-box .bank-btn {
        /*width: 160px;*/
        flex: 1;
        margin-right: 12px;
        font-size: 16px;
        border-radius: 20px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .pay-select .btn-box .bank-btn:last-of-type {
        margin-right: 0;
    }
</style>
