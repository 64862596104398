var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay"},[_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":_vm.bannerImg,"alt":""}})]),_c('div',{staticClass:"page-box"},[_vm._m(0),_c('div',{staticClass:"shop-list"},[_c('div',{staticClass:"list-container"},[_vm._m(1),_c('div',{staticClass:"list-mainer"},_vm._l((_vm.orderlist),function(item,index){return _c('div',{key:index,staticClass:"list-item clear"},[_c('div',{staticClass:"item-detail w35"},[_c('div',{staticClass:"item-img"},[_c('img',{attrs:{"src":item.picture,"alt":""}})]),_c('div',{staticClass:"item-msg"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.press))]),_c('div',_vm._l((item.book_type),function(type,index){return _c('span',{key:index,staticClass:"item-type"},[_vm._v(_vm._s(type))])}),0)])]),_c('div',{staticClass:"item-text w35"},[_c('span',[_vm._v(_vm._s(item.apply_explain))])]),_c('div',{staticClass:"item-crease w15"},[[_c('el-input-number',{attrs:{"min":1,"max":9999,"precision":0,"step":1,"label":"描述文字"},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", $$v)},expression:"item.count"}})]],2),_c('div',{staticClass:"item-allPrice w15"},[_c('div',{staticClass:"item-price-box"},[_c('span',[_vm._v("单价："+_vm._s(item.unit_price)+"元/期")]),_c('span',[_vm._v("["+_vm._s(item.frequency_name)+"/共"+_vm._s(item.frequency_periods)+"期]")]),_c('p',[_vm._v(" 总价："),_c('span',[_vm._v(_vm._s((item.count * item.half_year_price).toFixed(2))+"元")])])])])])}),0)])]),_c('div',{staticClass:"order-detail clear"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"detail-address"},[_c('span',[_vm._v("姓名："+_vm._s(_vm.address.nameValue))]),_c('span',[_vm._v("电话："+_vm._s(_vm.address.telValue))]),_c('span',[_vm._v("学校："+_vm._s(_vm.address.schoolValue))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
              (_vm.address.nameValue && _vm.address.gradeValue) || !_vm.address.nameValue
            ),expression:"\n              (address.nameValue && address.gradeValue) || !address.nameValue\n            "}]},[_vm._v("年级："+_vm._s(_vm.address.gradeValue))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
              (_vm.address.nameValue && _vm.address.classValue) || !_vm.address.nameValue
            ),expression:"\n              (address.nameValue && address.classValue) || !address.nameValue\n            "}]},[_vm._v("班级："+_vm._s(_vm.address.classValue))])]),_c('span',{staticClass:"add-address am",on:{"click":function($event){_vm.showAdress = true}}},[_vm._v(_vm._s(_vm.haveAdress ? '修改地址' : '添加地址'))])]),_c('div',{staticClass:"fr"},[_c('p',[_vm._v(" 您已选择购买"),_c('span',[_vm._v(" "+_vm._s(_vm.selectAll)+" ")]),_vm._v("套刊物 ")]),_c('div',[_c('p',{staticClass:"clear"},[_c('span',{staticClass:"fl"},[_vm._v("订阅刊物费：")]),_c('span',{staticClass:"fr"},[_vm._v("￥"+_vm._s(_vm.bookFee.toFixed(2))+"元")])]),_c('p',{staticClass:"clear"},[_c('span',{staticClass:"fl"},[_vm._v("运费：")]),_c('span',{staticClass:"fr"},[_vm._v("￥"+_vm._s(_vm.postFee.toFixed(2))+"元")])]),_c('div',{staticClass:"clear"},[_c('span',{staticClass:"fl"},[_vm._v("合计总额：")]),_c('b',{staticClass:"fr"},[_vm._v("￥"+_vm._s(_vm.allFee.toFixed(2))+"元")])])])])]),_c('div',{staticClass:"confirm-btn am lingred",on:{"click":_vm.confirmOrders}},[_vm._v("确认订单")])]),_c('transition-group',{attrs:{"name":"fade"}},[_c('AddressBox',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdress),expression:"showAdress"}],key:"address",attrs:{"showBox":_vm.showAdress,"type":_vm.haveAdress ? '2' : '1'},on:{"closeBox":_vm.closeAdress,"addAdress":_vm.addAdress}}),(_vm.showOrder)?_c('CreateOrder',{key:"order",attrs:{"list":_vm.orderlist,"bookFee":_vm.bookFee,"postFee":_vm.postFee,"allFee":_vm.allFee},on:{"clickClose":_vm.closeOrders}}):_vm._e()],1),_c('PromptText',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPro),expression:"showPro"}],on:{"conExe":_vm.confirmSubmitOrder}}),_c('PromptToast',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUpgradeToast),expression:"showUpgradeToast"}],attrs:{"title":_vm.upgradeToast.title,"text":_vm.upgradeToast.text},on:{"conExe":_vm.upgradeToastConfirm}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title clear"},[_c('div',{staticClass:"page-icon fl"},[_c('i',{staticClass:"iconfont icondingdan"}),_c('span',[_vm._v("确认订单信息")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-header clear"},[_c('span',{staticClass:"w35"},[_vm._v("刊物名称")]),_c('span',{staticClass:"w35"},[_vm._v("刊物说明")]),_c('span',{staticClass:"w15"},[_vm._v("数量")]),_c('span',{staticClass:"w15"},[_vm._v("价格")])])
}]

export { render, staticRenderFns }