class Utils {
  // 根据屏幕设置字体大小
  setRootSize() {
    let docEl = document.documentElement,
      resizeEvt =
        'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = function () {
        let minWidth = 1020
        let maxWidth = 1920
        let interval = maxWidth - minWidth
        let cw
        if (docEl.clientWidth < minWidth) {
          cw = 0
        } else if (docEl.clientWidth > maxWidth) {
          cw = interval
        } else {
          cw = docEl.clientWidth - minWidth
        }
        docEl.style.fontSize = Math.ceil(75 + cw / (interval / 25)) + 'px'
      }
    window.addEventListener(resizeEvt, recalc, false)
    document.addEventListener('DOMContentLoaded', recalc, false)
  }

  // 时间戳转时间
  timeChange(e) {
    let time = new Date(e)
    let dateTime = `${time.getFullYear()}-${
      time.getMonth() + 1
    }-${time.getDate()}`
    return dateTime
  }

  // 函数防抖 {fun:执行函数,wait:间隔时长,immediate:首次是否立即执行,params:执行函数参数}
  debounce(fun, wait = 300, immediate = false, params) {
    let timer = null
    return function () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        immediate ? fun(params) : null
        immediate = true
      }, wait)
    }
  }

  //pc 端
  IsPC() {
    return !navigator.userAgent.match(
      /(phone|pad|pod|iPad|iPod|iPhone|ios|Android|Mobile|IEMobile|BlackBerry|JUC|Fennec|wOSBrowser|WebOS|MQQBrowser|BrowserNG|Symbian|Windows Phone)/i
    )
  }
  // 微信浏览器环境
  isWX() {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1
  }

  /**
   * @description: 生成指定长度的随机字符串
   * @param {Number} length 长度
   * @return {String} 生成的指定长度的随机字符串
   */
  generateRandomString(length = 16) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  /**
   * @description: 验证密码是否是数字+字母至少8位的组合
   * @param {String} password 密码
   * @return {Boolean} 验证通过返回 true,否则返回 false
   */
  validatePassword(password) {
    // var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,20}$/;
    return regex.test(password)
  }

  /**
   * @description: 将手机号中间4位替换为星号
   * @param {String} phoneNumber 手机号
   * @return {String} 加密后的手机号
   */
  hidePhoneNumber(phoneNumber = '***********') {
    return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
  }
}

const util = new Utils()
export default util
