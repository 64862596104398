<template>
  <div class="confirm">
    <div class="confirm-box">
      <div class="confirm-title lingred">
        <span>{{ title }}</span>
      </div>
      <div class="confirm-text">
        <div v-html="text"></div>
      </div>
      <div class="btn-box">
        <div class="lingred pay-btn" @click="conExe">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return '升级提示'
      },
    }, // 标题
    text: {
      type: String,
      default() {
        return ''
      },
    }, // 描述
  },
  data: function () {
    return {}
  },
  mounted() {
  },
  methods: {
    //继续执行
    conExe() {
      this.$emit('conExe')
    },
  },
}
</script>
<style scoped>
.confirm {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}
.confirm .confirm-box {
  width: 660px;
  max-height: 580px;
  margin-left: -330px;
  position: absolute;
  left: 50%;
  top: 50%;
  padding-bottom: 40px;
  margin-top: -290px;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
}

.confirm .confirm-box .confirm-text {
  font-size: 18px;
  padding: 30px 50px;
  line-height: 1.9;
  text-indent: 2em;
}
.confirm .confirm-box > p {
  text-align: center;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}
.confirm .confirm-box > div {
  width: 100%;
}
.confirm .confirm-box > div .btn {
  cursor: pointer;
  width: 140px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 30px;
  font-size: 16px;
}
.confirm-title {
  width: 100%;
  position: relative;
  height: 80px;
}
.confirm-title span {
  text-align: center;
  font-size: 0.26rem;
  color: #fff;
  line-height: 80px;
  display: inline-block;
  width: 100%;
}
.confirm .confirm-box .pay-btn {
  text-align: center;
  display: block;
  width: 220px;
  height: 60px;
  line-height: 60px;
  border: none;
  border-radius: 30px;
  font-size: 22px;
  cursor: pointer;
  margin: 0 auto;
}
.confirm .confirm-box .order-msg span {
  color: #333333;
}
.confirm .confirm-box .order-msg b {
  font-size: 0.28rem;
  color: #3b77d1;
  font-weight: bold;
}
.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm .confirm-box .btn-box .pay-btn {
  margin: 0 30px;
}
</style>
